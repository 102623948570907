<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.questionnaire-update-to-active-headline') }}
    </template>
    <template #description>
      <p>
        {{ $t('score.questionnaire-update-to-active-description1') }}
      </p>
      <p class="mt-8">
        {{ $t('score.questionnaire-update-to-active-description2') }}
      </p>
      <p class="mt-8">
        {{ $t('score.questionnaire-update-to-active-description3') }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('general.update') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Dialog from './Dialog'
import { useDialog } from '@/composition'
import { useRequest } from '@/composition'
import { Button } from '@/components/form'
import {
  questionnaires as questionnairesApi,
  objectGroups as objectGroupsApi,
} from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    assetId: {
      type: String,
      required: false,
    },
    assetGroupId: {
      type: Number,
      required: false,
    },
    isBuyerAsset:{
      type:Boolean,
      required:false
    }
  },
  setup(props, { emit }) {
    const request = useRequest(() => {
      if (props.assetId !== null) {
        return questionnairesApi.updateToActiveQuestionnaire(props.assetId,props.isBuyerAsset)
      } else {
        return objectGroupsApi.updateToActiveQuestionnaire(props.assetGroupId,props.isBuyerAsset)
      }
    })

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('update', this.assetId ?? this.assetGroupId)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-update-to-active-success',
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: `questionnaire-update-to-active-${
            this.assetGroupId !== null && error.response.status === 400
              ? 'invalid'
              : 'error'
          }`,
        })
      }
    },
  },
}
</script>
