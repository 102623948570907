import jwtDecode from 'jwt-decode'
import { EUserRole } from '@/enums'
import type { IAuthState, IJwtPayload } from './types'

export default {
  setUser(
    state: IAuthState,
    { userName, token }: { userName: string; token: string }
  ): void {
    state.userName = userName
    state.token = token
    const decoded = jwtDecode<IJwtPayload>(token)
    // decoded.exp = 1000 // to test expired user
    state.tokenDecoded = decoded
    state.roles = extractRoles(decoded)
    state.organisationIsTestAccount = decoded.orgTest
    state.organisationIsFinancePartner = decoded.orgFinance
    state.organisationIsBuyer = decoded.orgBuyer
  },
  clearUser(state: IAuthState): void {
    state.userName = ''
    state.token = ''
    state.tokenDecoded = null
    state.roles = []
    state.organisationIsTestAccount = false
    state.organisationIsFinancePartner = false
  },
  setInitialRouteName(state: IAuthState, routeName: string): void {
    state.initialRouteName = routeName
  },
}

function extractRoles(token: IJwtPayload): Array<EUserRole> {
  return token.role
    .split(',')
    .map(stringRole => EUserRole[stringRole as keyof typeof EUserRole])
}
