import { ref, onBeforeUnmount, Ref } from 'vue'
import useRequest, { IUseRequest } from '@/composition/useRequest'
import {
  users,
  organisations,
  objects,
  objectGroups,
  questionnaires,
} from '@/services/api'
import { IUser } from '@/models/user'
import { IOrganisation } from '@/models/organisation'
import { IObject } from '@/models/object'
import { IObjectGroup } from '@/models/objectGroup'
import { IQuestionnaireListEntry } from '@/models/questionnaire'

type Payload =
  | IUser
  | IOrganisation
  | IObject
  | IObjectGroup
  | IQuestionnaireListEntry

interface IApiService {
  getList: () => Promise<Array<Payload>>
  cancelRequest: () => void
}

interface IListFetch<T = Payload> extends IUseRequest<Array<T>> {
  list: Ref<Array<T>>
}

const apiServices: { [service: string]: IApiService } = {
  users,
  organisations,
  objects,
  objectGroups,
  questionnaires,
}

export default <T = Payload>(
  key: string,
  onError?: (err?: unknown) => void
): IListFetch<T> => {
  const list = ref<Array<Payload>>([])

  const api = apiServices[key]

  const fetch = useRequest<Array<Payload>>(api.getList, api.cancelRequest)

  fetch
    .request()
    .then(result => {
      if (result) {
        list.value = result
      }
    })
    .catch(err => {
      if (onError) {
        onError(err)
      }
    })
  onBeforeUnmount(() => fetch.pending && fetch.cancel!())

  return {
    ...fetch,
    // @ts-ignore https://github.com/vuejs/vue-next/issues/1324
    list,
  }
}
