import { ValidatorFn, ErrorObject } from '@vuelidate/core'
import {
  helpers,
  required,
  email,
  sameAs,
  minLength,
} from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

type ValidationCollection = Record<string, { $errors: ErrorObject[] }>

const _numeric = (val: string | number) =>
  !!String(val).match(/^\d+([,.]\d+)?$/)

const numeric: ValidatorFn<string> = val => _numeric(val)

const optionalNumeric: ValidatorFn<string> = val => _numeric(val) || !val

export default (): Record<string, unknown> => {
  const { t } = useI18n()

  return {
    required: helpers.withMessage(t('error.required'), required),
    email: helpers.withMessage(t('error.email'), email),
    numeric: helpers.withMessage(
      t('error.numeric'),
      numeric as ValidatorFn<unknown>
    ),
    optionalNumeric: helpers.withMessage(
      t('error.numeric'),
      optionalNumeric as ValidatorFn<unknown>
    ),
    requiredRole: helpers.withMessage(t('error.requiredRole'), required),
    sameAs: (field: Record<string, unknown>, messageKey: string) =>
      helpers.withMessage(t(messageKey), sameAs(field)),
    minLength: (minNumber: number, messageKey: string) =>
      helpers.withMessage(t(messageKey, { minNumber }), minLength(minNumber)),
    errorMessages(fields: ValidationCollection, fieldKeys: Array<string>) {
      return fieldKeys.reduce((result: Record<string, string>, key) => {
        result[key] = fields[key].$errors[0]?.$message as string
        return result
      }, {})
    },
    passwordFormat: (messageKey: string) =>
      helpers.withMessage(t(messageKey), (value: string) =>
        /(?=.*[a-zA-Z])(?=.*[0-9])/.test(value)
      ),
    helpers,
  }
}
