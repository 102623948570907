import { EFeatureQuality, EHistoricProtection } from '@/enums'
import { i18n } from '@/plugins'
import { enumKeys, enumString } from './general'

export function assetClassName(
  assetClassIdentifier: string,
  assetClassIsPilot: boolean
): string {
  let name = i18n.global.t(`general.assetClass-${assetClassIdentifier}`)
  if (assetClassIsPilot) {
    name += ' (' + i18n.global.t(`general.assetClass-pilot`) + ')'
  }
  return name
}

export function countryName(countryCode: string): string {
  return i18n.global.t(`general.country-${countryCode}`)
}

export const qualityKeys = enumKeys(EFeatureQuality)

export function qualityName(quality: EFeatureQuality): string {
  return i18n.global.t(
    `assetManagement.objects-featureQuality-${enumString(
      EFeatureQuality,
      quality
    )}`
  )
}

export const historicProtectionKeys = enumKeys(EHistoricProtection)

export function historicProtectionName(
  historicProtection: EHistoricProtection
): string {
  return i18n.global.t(
    `assetManagement.objects-historicProtection-${enumString(
      EHistoricProtection,
      historicProtection
    )}`
  )
}
