<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.export-dialog_headline') }}
    </template>
    <template #description>
      <p>
        {{ $t('score.export-dialog_description') }}
      </p>
      <Select
        v-model="selectedLanguage"
        :items="availableLanguages"
        :placeholder="$t('general.language')"
      >
      </Select>
      <p>
        {{ $t('score.export-dialog_description_format') }}
      </p>
      <Select
        v-model="selectedFormat"
        :items="availableFormats"
        :placeholder="$t('export.export_format')"
      >
      </Select>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>

        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="requestPending || !selectedLanguage || !selectedFormat"
          @click="exportQuestionnaire"
        >
          {{ $t('general.export')
          }}<loading class="ml-1" v-if="requestPending" />
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from './Dialog'
import { useDialog, useRequest } from '@/composition'
import { Button, Select } from '@/components/form'
import {
  objects as objectApi,
  organisations as organisationsApi,
  objectGroups as objectGroupsApi,
} from '@/services/api'
import { useRoute } from 'vue-router'
import Loading from '../Loading.vue'

export default {
  components: {
    Dialog,
    Button,
    Select,
    Loading,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: [String, Number],
    },
    toBeExported: {
      type: [String, Number],
    },
    groupId: {
      type: [String, Number],
    },
    objToBeExported: {
      type: [String, Number],
    },
  },
  data() {
    return {
      selectedLanguage: null,
      selectedFormat: null,
    }
  },
  setup(props, { emit }) {
    const route = useRoute()

    const exportRequest = useRequest(
      async ({ language: selectedLanguage, exportFormat: selectedFormat }) => {
        if (route.name === 'objectExport')
          await objectApi.exportObject(
            props.assetId,
            selectedLanguage,
            selectedFormat
          )
        if (route.name === 'customerDataExport')
          await organisationsApi.exportOrganisations(
            props.toBeExported,
            selectedLanguage,
            selectedFormat
          )
        if (route.name === 'objectGroupExport')
          await objectGroupsApi.exportObjectGroup(
            props.groupId,
            props.objToBeExported,
            selectedLanguage,
            selectedFormat
          )
      }
    )
    return {
      ...useDialog(props, emit, exportRequest.pending),
      exportRequest,
    }
  },
  computed: {
    availableLanguages() {
      return this.$i18n.availableLocales.map(lang => ({
        key: lang,
        text: this.$t(`general.language-${lang}`),
      }))
    },
    availableFormats() {
      return ['CSV', 'Excel'].map((format, i) => ({
        key: i + 1,
        text: format,
      }))
    },
    requestPending() {
      return this.exportRequest.pending.value
    },
  },
  methods: {
    async exportQuestionnaire() {
      await this.exportRequest.request({
        language: this.selectedLanguage.key,
        exportFormat: this.selectedFormat.key,
      })
      this.close()
    },
  },
}
</script>
