import type { IToastMessageState, IToastMessage } from './types'

const DEFAULT_MESSAGE_DURATION = 5000

export default {
  showMessage(state: IToastMessageState, message: IToastMessage): void {
    clearTimeout(state.durationTimeout)
    state.message = message
    state.durationTimeout = setTimeout(
      () => (state.message = null),
      message.duration || DEFAULT_MESSAGE_DURATION
    )
  },
}
