import axiosClient from './http'
import { uses2fa } from '@/helpers/general'

export default {
  async login(userName: string, password: string): Promise<string> {
    const res = await axiosClient.post(
      uses2fa() ? 'user/secure/login' : 'user/login',
      { userName, password }
    )
    return res.data.accessToken
  },
  async login2faStep2(userName: string, token: string): Promise<string> {
    const res = await axiosClient.get(
      `user/secure/login?userName=${encodeURIComponent(
        userName
      )}&token=${token}`
    )
    return res.data.accessToken
  },
  resetPassword(userName: string): Promise<void> {
    return axiosClient.get(
      `user/resetPassword?userName=${encodeURIComponent(userName)}`
    )
  },
  setPassword(password: string, token: string): Promise<void> {
    return axiosClient.post('user/setPassword', { password, token })
  },
}
