import { useRequest } from '@/composition'
import { configStorage } from '@/services/storage'
import { objects as objectsApi } from '@/services/api'

export default {
  async getNumberOfReviewedAnswersInAssetQuestionnaire(): Promise<number> {
    const configRequest = useRequest(objectsApi.getConfig)
    let storageApiConfig = configStorage.getApiConfig()
    if (storageApiConfig === null) {
      const reviewProcessConfig = await configRequest.request()
      if (reviewProcessConfig !== undefined) {
        storageApiConfig = {
          reviewProcess: reviewProcessConfig,
        }
        configStorage.setApiConfig(storageApiConfig)
      }
    }

    return storageApiConfig?.reviewProcess?.numberOfReviewedAnswers ?? -1
  },
}
