<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{
        $t(
          `score.evaluate-group-${
            this.accept ? 'accept' : 'decline'
          }-modal-headline`
        )
      }}
    </template>
    <template #description>
      <p>
        {{
          $t(
            `score.evaluate-group-${
              this.accept ? 'accept' : 'decline'
            }-modal-text`
          )
        }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('general.confirm') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { useDialog, useRequest } from '@/composition'
import { Button } from '@/components/form'
import Dialog from './Dialog'
import { objectGroups as objectGroupsApi } from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    accept: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()
    const evaluateGroupRequest = useRequest(() => {
      return objectGroupsApi.evaluate(props.id, props.accept)
    })
    return {
      ...useDialog(props, emit, evaluateGroupRequest.pending),
      ...evaluateGroupRequest,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('finish')
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'evaluate-group-modal-success',
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: 'evaluate-group-modal-error',
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
