import { omit, cloneDeep } from 'lodash-es'
import { i18n } from '@/plugins'
import {
  EQuestionType,
  EVirtQuestType,
  EQuestionnaireLevel,
  EAnswerState,
  EAssetState,
} from '@/enums'
import {
  IActiveQuestionnaire,
  IAnswerChangeLogContainer,
  IAssetQuestionnaire,
  ILockedStatus,
  IQuestionnaire,
} from '@/models/questionnaire'
import type {
  IComment,
  ITranslation,
  ITranslationCreate,
  IAnswer,
  IAnswerDocument,
} from '@/models/question'
import type {
  IQuestionnaireInStore,
  IClusterInStore,
  ICategoryInStore,
  ISectionInStore,
  IQuestionInStore,
  IQuestionInStoreCreate,
  ICategoryRetrieval,
  ISectionRetrieval,
  IQuestionRetrieval,
  ICategoryMove,
  ISectionMove,
  IAddItemConfig,
  LevelType,
} from './types'
import {
  initQuestionnaire,
  retrieveCategory,
  retrieveSection,
  retrieveQuestion,
  questionIsAnswered,
  findIndexById,
  tempId,
} from './helpers'

export default {
  /*** TEMPLATES ***/

  setQuestionnaire(
    state: IQuestionnaireInStore,
    questionnaire: IQuestionnaire & IAssetQuestionnaire
  ): void {
    initQuestionnaire(state, questionnaire)
  },

  setClusterWeight(
    state: IQuestionnaireInStore,
    { clusterIdx, weight }: { clusterIdx: number; weight: number }
  ): void {
    const cluster = state.clusters[clusterIdx]
    cluster.weight = weight
    cluster.modified = true
    state.modified = true
  },
  setClusterFinanceWeight(
    state: IQuestionnaireInStore,
    { clusterIdx, financeWeight }: { clusterIdx: number; financeWeight: number }
  ): void {
    const cluster = state.clusters[clusterIdx]
    cluster.financeWeight = financeWeight
    cluster.modified = true
    state.modified = true
  },
  setClusterIdentifier(
    state: IQuestionnaireInStore,
    {
      clusterIdx,
      clusterIdentifier,
    }: { clusterIdx: number; clusterIdentifier: number }
  ): void {
    const cluster = state.clusters[clusterIdx]
    cluster.clusterIdentifier = clusterIdentifier
    cluster.modified = true
    state.modified = true
  },
  setClusterTaxanomy(
    state: IQuestionnaireInStore,
    {
      clusterIdx,
      isTaxonomyCluster,
    }: { clusterIdx: number; isTaxonomyCluster: boolean }
  ): void {
    const cluster = state.clusters[clusterIdx]
    cluster.isTaxonomyCluster = isTaxonomyCluster
    cluster.modified = true
    state.modified = true
  },
  setQuestionnaireType(state: IQuestionInStore,
    type: number
  ) {
    state.type = type;
    state.modified = true
  },
  setSectionWeight(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      weight,
    }: {
      retrievalConfig: ISectionRetrieval
      weight: number
    }
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    section.weight = weight
    section.modified = true
    state.modified = true
  },

  setSectionFinanceWeight(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      financeWeight,
    }: {
      retrievalConfig: ISectionRetrieval
      financeWeight: number
    }
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    section.financeWeight = financeWeight
    section.modified = true
    state.modified = true
  },

  setClusterCategories(
    state: IQuestionnaireInStore,
    { clusterIdx, cats }: { clusterIdx: number; cats: Array<ICategoryInStore> }
  ): void {
    const cluster = state.clusters[clusterIdx]
    cluster.categories = cats
    cluster.modified = true
    state.modified = true
  },

  setCategorySections(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      sections,
    }: { retrievalConfig: ICategoryRetrieval; sections: Array<ISectionInStore> }
  ): void {
    const cat = retrieveCategory(state, retrievalConfig)!
    cat.sections = sections
    cat.modified = true
    state.modified = true
  },

  setSectionQuestions(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      questions,
    }: {
      retrievalConfig: ISectionRetrieval
      questions: Array<IQuestionInStore>
    }
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    section.questions = questions
    section.modified = true
    state.modified = true
  },

  setCategoryTitle(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      titles,
    }: { retrievalConfig: ICategoryRetrieval; titles: Record<string, string> }
  ): void {
    const cat = retrieveCategory(state, retrievalConfig)!
    cat.modified = true
    state.modified = true
    cat.name = Object.entries(titles).map(([language, name]) => ({
      language,
      name,
    }))
  },

  setSectionTitle(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      titles,
    }: { retrievalConfig: ISectionRetrieval; titles: Record<string, string> }
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    section.modified = true
    state.modified = true
    section.name = Object.entries(titles).map(([language, name]) => ({
      language,
      name,
    }))
  },

  setQuestion(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      question,
    }: { retrievalConfig: IQuestionRetrieval; question: IQuestionInStore }
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    const questionIdx = findIndexById(
      section.questions,
      retrievalConfig.questionId
    )
    section.questions.splice(questionIdx, 1, {
      ...question,
      modified: true,
    })
    state.modified = true
  },

  moveCategory(
    state: IQuestionnaireInStore,
    { toClusterIdx, fromClusterIdx, categoryId }: ICategoryMove
  ): void {
    const oldCluster = state.clusters[fromClusterIdx]
    const newCluster = state.clusters[toClusterIdx]
    const catIdx = findIndexById(oldCluster.categories, categoryId)
    const cat = oldCluster.categories[catIdx]
    oldCluster.categories.splice(catIdx, 1)
    newCluster.categories.push(cat)
    cat.modified = true
    state.modified = true
  },

  moveSection(
    state: IQuestionnaireInStore,
    { clusterIdx, toCatId, fromCatId, sectionId }: ISectionMove
  ): void {
    const oldCat = retrieveCategory(state, {
      clusterIdx,
      categoryId: fromCatId,
    })!
    const newCat = retrieveCategory(state, {
      clusterIdx,
      categoryId: toCatId,
    })!
    const sectionIdx = findIndexById(oldCat.sections, sectionId)
    const section = oldCat.sections[sectionIdx]
    oldCat.sections.splice(sectionIdx, 1)
    newCat.sections.push(section)
    section.modified = true
    state.modified = true
  },

  addItem(
    state: IQuestionnaireInStore,
    { level, clusterIdx, categoryId, sectionId, title }: IAddItemConfig
  ): void {
    let target: LevelType = state.clusters[clusterIdx]
    if (level > EQuestionnaireLevel.Category) {
      target = (target as IClusterInStore).categories.find(
        c => c.id === categoryId
      )!
    }
    if (level > EQuestionnaireLevel.Section) {
      target = (target as ICategoryInStore).sections.find(
        s => s.id === sectionId
      )!
    }

    const id = tempId()

    // need to be declared beforehand for usage inside case blocks
    let cats: Array<ICategoryInStore>,
      sections: Array<ISectionInStore>,
      questions: Array<IQuestionInStore | IQuestionInStoreCreate>,
      translations: Array<ITranslationCreate>,
      name: Array<{ name: string; language: string }>

    switch (level) {
      case EQuestionnaireLevel.Category:
        cats = (target as IClusterInStore).categories
        name = [
          {
            name: title,
            language: i18n.global.locale,
          },
        ]
        if (i18n.global.locale !== 'de') {
          // de entry always needed
          name.push({
            name: title,
            language: 'de',
          })
        }
        cats.push({
          id,
          name,
          sections: [],
          modified: true,
        })
        break
      case EQuestionnaireLevel.Section:
        sections = (target as ICategoryInStore).sections
        name = [
          {
            // var declared in previous case
            name: title,
            language: i18n.global.locale,
          },
        ]
        if (i18n.global.locale !== 'de') {
          // de entry always needed
          name.push({
            name: title,
            language: 'de',
          })
        }
        sections.push({
          id,
          name,
          weight: 0,
          questions: [],
          modified: true,
          financeWeight: 0,
        })
        break
      default:
        questions = (target as ISectionInStore).questions
        translations = [
          {
            language: i18n.global.locale,
            title,
            description: '',
            clarification: '',
          },
        ]
        if (i18n.global.locale !== 'de') {
          // de entry always needed
          translations.push({
            language: 'de',
            title,
            description: '',
            clarification: '',
          })
        }
        questions.push({
          id,
          staticTitle: '',
          type: EQuestionType.ValueQuestion,
          assetTypes: [],
          clusterType: clusterIdx,
          taxonomyRelevant: null,
          options: [],
          virtScores: [],
          translations,
          modified: true,

          // virtual question fields:
          virtualQuestionType: EVirtQuestType.DK,
          averageVariator: 0,
          parentQuestionId: null,
          isGreenHouseGas: false,
        })
    }

    state.modified = true
  },

  copyQuestion(
    state: IQuestionnaireInStore,
    retrievalConfig: IQuestionRetrieval
  ): void {
    const section = retrieveSection(state, retrievalConfig)!
    const questions = section.questions
    const questionIdx = findIndexById(questions, retrievalConfig.questionId)
    const clone = cloneDeep(questions[questionIdx])
    clone.id = tempId()
    clone.translations.forEach((t: ITranslation) => {
      t.title = `${t.title} (copy)`
    })
    questions.splice(questionIdx + 1, 0, clone)
    clone.modified = true
    state.modified = true
  },

  deleteCategory(
    state: IQuestionnaireInStore,
    { clusterIdx, categoryId }: ICategoryRetrieval
  ): void {
    const cluster = state.clusters[clusterIdx]
    const catIdx = findIndexById(cluster.categories, categoryId)
    cluster.categories.splice(catIdx, 1)
    cluster.modified = true
    state.modified = true
  },

  deleteSection(
    state: IQuestionnaireInStore,
    { clusterIdx, categoryId, sectionId }: ISectionRetrieval
  ): void {
    const category = retrieveCategory(state, { clusterIdx, categoryId })!
    const sectionIdx = findIndexById(category.sections, sectionId)
    category.sections.splice(sectionIdx, 1)
    category.modified = true
    state.modified = true
  },

  deleteQuestion(
    state: IQuestionnaireInStore,
    { clusterIdx, categoryId, sectionId, questionId }: IQuestionRetrieval
  ): void {
    const section = retrieveSection(state, {
      clusterIdx,
      categoryId,
      sectionId,
    })!
    const questionIdx = findIndexById(section.questions, questionId)
    section.questions.splice(questionIdx, 1)
    section.modified = true
    state.modified = true
  },

  /*** ANSWERING QUESTIONS ***/

  setAnswer(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      answer,
      noStatement,
    }: {
      retrievalConfig: IQuestionRetrieval
      answer: IAnswer
      noStatement: boolean
    }
  ): void {
    const question = retrieveQuestion(state, retrievalConfig)
    if (!question) {
      return
    }
    question.answer = answer
    question.modified = true
    state.modified = true

    if (noStatement || questionIsAnswered(question)) {
      question.answer.state = EAnswerState.Answered
    } else {
      question.answer.state = null
    }
  },

  setAnswerState(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      newState,
    }: { retrievalConfig: IQuestionRetrieval; newState: EAnswerState }
  ): void {
    const question = retrieveQuestion(state, retrievalConfig)
    if (question && question.answer) {
      question.answer.state = newState
      question.modified = true
      state.modified = true
    }
  },

  setAnswersSaved(
    state: IQuestionnaireInStore,
    {
      questions,
      answers,
    }: {
      questions: Array<IQuestionInStore>
      answers: Array<IAnswer>
    }
  ): void {
    questions.forEach((q, idx) => {
      q.modified = false
      if (q.answer) {
        Object.assign(q.answer, omit(answers[idx], ['comments']))
      }
    })
  },

  setModified(state: IQuestionnaireInStore, modified: boolean): void {
    state.modified = modified
  },

  addComment(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      comment,
    }: {
      retrievalConfig: IQuestionRetrieval
      comment: IComment
    }
  ): void {
    const question = retrieveQuestion(state, retrievalConfig)
    if (question?.answer) {
      question.answer.comments.push(comment)
    }
  },

  setAssetState(state: IQuestionnaireInStore, assetState: EAssetState): void {
    state.assetQuestionnaireState = assetState
  },

  setLockStatus(state: IQuestionnaireInStore, lockStatus: ILockedStatus): void {
    state.lockedStatus = lockStatus
  },

  setAnswerChangeLog(
    state: IQuestionnaireInStore,
    answerChangeLog: IAnswerChangeLogContainer[]
  ): void {
    const questions = state.clusters.flatMap(clu =>
      clu.categories.flatMap(cat => cat.sections.flatMap(s => s.questions))
    )

    answerChangeLog?.forEach(answerChangeLogContainer => {
      const question = questions.filter(
        q => q.id === answerChangeLogContainer.questionId
      )?.[0]
      if (question) {
        question.answerChangeLog = answerChangeLogContainer.answerChangeLog
      }
    })
  },

  addDocumentMutation(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      document,
    }: {
      retrievalConfig: IQuestionRetrieval
      document: IAnswerDocument
    }
  ): void {
    const question = retrieveQuestion(state, retrievalConfig)
    if (question?.answer) {
      question.answer.documents.push(document)
    }
  },

  deleteDocumentMutation(
    state: IQuestionnaireInStore,
    {
      retrievalConfig,
      documentId,
    }: {
      retrievalConfig: IQuestionRetrieval
      documentId: number
    }
  ): void {
    const question = retrieveQuestion(state, retrievalConfig)
    if (question?.answer) {
      const filteredDocuments = question.answer.documents.filter(
        document => document.id !== documentId
      )
      question.answer.documents = filteredDocuments
    }
  },

  setUnmodifiedQuestions(
    state: IQuestionnaireInStore,
    {
      questionnaire,
      id,
    }: { questionnaire: IQuestionnaire & IAssetQuestionnaire; id: number }
  ): void {
    const dontUpdate: number[] = []
    state.clusters.forEach(cluster => {
      cluster.categories.forEach(cat => {
        cat.sections.forEach(sec => {
          sec.questions.forEach(quest => {
            if (quest.modified && quest.id !== id) {
              dontUpdate.push(quest.id)
            }
          })
        })
      })
    })
    initQuestionnaire(state, questionnaire, dontUpdate)
  },
  setActiveQuestionnaire(state: IQuestionnaireInStore, { activateQuestionnaire }: { activateQuestionnaire: IActiveQuestionnaire }) {
    state.activateQuestionnaire = activateQuestionnaire
  }
}
