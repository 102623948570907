<template>
  <header class="flex bg-white shadow-md px-6 xl:px-10 min-h-fit-content z-[1]">
    <router-link class="flex-shrink-0 py-3" :to="{ name: 'home' }">
      <img src="@images/logo.svg" alt="logo ecore" />
    </router-link>
    <HeaderNav class="ml-auto" />
    <LanguageSelect />
    <HeaderMenu class="ml-6" />
  </header>
</template>

<script>
import HeaderNav from '@/components/HeaderNav'
import HeaderMenu from '@/components/HeaderMenu'
import LanguageSelect from '@/components/LanguageSelect'

export default {
  components: {
    HeaderNav,
    HeaderMenu,
    LanguageSelect,
  },
}
</script>
<style scoped>
@media print {
  header {
    display: none;
  }
}
</style>
