export interface IUserData {
  userName: string | null
  token: string | null
}

const storage_keys = {
  userName: 'userName',
  token: 'auth_token',
}

export default {
  setUser(userName: string, token: string): void {
    localStorage.setItem(storage_keys.userName, userName)
    localStorage.setItem(storage_keys.token, token)
  },
  getUser(): IUserData {
    const userName = localStorage.getItem(storage_keys.userName)
    const token = localStorage.getItem(storage_keys.token)
    return { userName, token }
  },
  clearUser(): void {
    localStorage.removeItem(storage_keys.userName)
    localStorage.removeItem(storage_keys.token)
  },
}
