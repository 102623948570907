<template>
  <Dialog :class="false && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('assetManagement.questionnaire-start-headline') }}
    </template>

    <template #description>
      <p>
        {{
          $t('assetManagement.questionnaire-start-description', {
            objectName: object.name,
          })
        }}
      </p>
    </template>

    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="false" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="false"
          variant="primary"
          @click="confirm"
        >
          {{ $t('general.create') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { useDialog, useRequest } from '@/composition'
import { questionnaires as api } from '@/services/api'
import { Button } from '@/components/form'
import Dialog from './Dialog'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    isFirstQuestionnaire: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'start'],
  setup(props, { emit }) {
    const request = useRequest(() =>
      api.startNewQuestionnaire(props.object.id, props.isFirstQuestionnaire,props.object.isBuyerAsset)
    )

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        const startedQuest = await this.request()
        this.$emit('start', startedQuest)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-start-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-start-error',
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (!opening) {
        return
      }
      this.error = false
    },
  },
}
</script>
