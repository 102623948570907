<template>
  <Root v-bind="attrs.wrapper" v-click-outside="clickOutside">
    <div class="flex flex-row mb-1">
      <label class="inline-block text-xs pl-2 mb-1" :class="labelClasses">{{ heading || '&nbsp;' }}</label>
      <InfoPopup v-if="info || infoImage" class="ml-auto" :info="info" :image="infoImage" />
    </div>

    <InputWrap
      v-bind="$attrs.field"
      :disabled="$attrs.disabled"
      v-model:isOpen="isOpen"
      ref="trigger"
    >
      <div v-if="icon" class="flex flex-center" :class="$attrs.disabled && 'bg-backgroundDisabled'">
        <Icon class="w-6 h-6 ml-3 self-center" :name="icon" />
      </div>
      <Input v-bind="attrs.input" :text="value.text" :disabled="!!$attrs.disabled" />
      <ItemsIndicator v-if="itemsIndicator" :number="items.length" />
      <Chevron :isOpen="isOpen" :disabled="!!$attrs.disabled" />
    </InputWrap>

    <div class="text-xs text-error cursor-default pl-2 mt-1">{{ errorMessage || '&nbsp;' }}</div>

    <Popup
      v-bind="{ isOpen, items }"
      ref="popup"
      @select="onSelect"
      #default="{ item }"
      :nullable="nullable"
    >
      <slot :item="item">{{ item.text }}</slot>
    </Popup>
  </Root>
</template>

<script>
import Root from './Root'
import InputWrap from './InputWrap'
import Input from './Input'
import Icon from '@/components/Icon'
import ItemsIndicator from './ItemsIndicator'
import Chevron from './Chevron'
import Popup from '@/components/form/Popup'
import InfoPopup from '@/components/form/InfoPopup.vue'
import { usePopper } from '@/composition'

const validateItem = item => item.key != undefined && item.text !== undefined

export default {
  name: 'ui-select',
  inheritAttrs: false,
  components: {
    Root,
    Input,
    InputWrap,
    Chevron,
    Popup,
    ItemsIndicator,
    Icon,
    InfoPopup,
  },
  emits: ['update:modelValue', 'open', 'close'],
  props: {
    items: {
      type: Array,
      required: true,
      validator: list => list.every(validateItem),
    },
    modelValue: {
      type: Object,
      validator: validateItem,
    },
    label: {
      type: String,
      required: false,
    },
    info: {
      type: String,
    },
    infoImage: {
      type: String,
    },
    itemsIndicator: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    return usePopper(emit, [0, 10])
  },
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    attrs() {
      const { title, disabled, class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
        disabled,
      }
      const fieldAttrs = {
        title,
        hasFocus: this.hasFocus,
        hasError: !!this.errorMessage,
      }

      return {
        wrapper: wrapperAttrs,
        field: fieldAttrs,
        input: inputAttrs,
      }
    },
    labelClasses() {
      return this.errorMessage
        ? 'text-error'
        : this.$attrs.disabled
        ? 'text-lightGray'
        : this.hasFocus
        ? 'text-primary'
        : 'text-lightBlack'
    },
    heading() {
      if (this.label) {
        return this.label
      }
      if (this.value.text) {
        return this.$attrs.placeholder
      }
      return ''
    },
    value: {
      get() {
        return this.modelValue || { key: null, text: '' }
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    clickOutside() {
      this.close()
    },
    onSelect(item) {
      this.value = item
      this.close()
    },
  },
}
</script>
