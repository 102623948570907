import { IReviewProcess } from '@/models/object'

export interface IApiConfig {
  reviewProcess: IReviewProcess | null
}

const storage_keys = {
  apiConfig: 'apiConfig',
}

export default {
  setApiConfig(apiConfig: IApiConfig): void {
    localStorage.setItem(storage_keys.apiConfig, JSON.stringify(apiConfig))
  },
  getApiConfig(): IApiConfig | null {
    const apiConfigString = localStorage.getItem(storage_keys.apiConfig)
    if (apiConfigString === null) {
      return null
    }

    const apiConfig = JSON.parse(apiConfigString)
    return apiConfig
  },
  clearApiConfig(): void {
    localStorage.removeItem(storage_keys.apiConfig)
  },
}
