<template>
  <section class="flex flex-center bg-login bg-cover">
    <div class="flex flex-col rounded-3xl bg-white px-20 py-14">
      <img class="self-start" src="@images/logo.svg" alt="logo ecore" />

      <h1 class="text-2xl font-bold tracking-wider text-lightBlack mt-12">
        <slot name="heading" />
      </h1>
      <div class="text-sm text-lightBlack tracking-wide leading-relaxed mt-2">
        <slot name="description" />
      </div>

      <slot />
    </div>
  </section>
</template>
