<template>
  <Pill
    class="
      group
      text-[18px]
      cursor-pointer
      border-2 border-[#1d5ac5]
      pl-3
      pr-1.5
      py-1
    "
    :class="rootClasses"
    @click="onSelect"
  >
    <span>{{ label }}</span>
    <Icon
      class="w-6 ml-2"
      :class="iconClasses"
      name="close"
      @click.stop="onDelete"
    />
  </Pill>
</template>

<script>
import Pill from '@/components/Pill'
import Icon from '@/components/Icon'

const stateClasses = {
  root: {
    default: 'text-lightBlack bg-white hover:text-white hover:bg-[#1d5ac5]',
    highlighted: 'text-white bg-[#1d5ac5]',
  },
  icon: {
    default: 'text-darkGray group-hover:text-white',
    highlighted: 'text-white',
  },
}

export default {
  components: {
    Pill,
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select', 'delete'],
  computed: {
    rootClasses() {
      const cls =
        stateClasses.root[this.highlighted ? 'highlighted' : 'default']
      return `${cls} ${this.disabled ? 'opacity-50 pointer-events-none' : ''}`
    },
    iconClasses() {
      const cls =
        stateClasses.icon[this.highlighted ? 'highlighted' : 'default']
      return `${cls} ${
        this.disabled
          ? ''
          : this.deletable
          ? 'transform-gpu hover:scale-125 transition duration-100'
          : 'opacity-25'
      }`
    },
  },
  methods: {
    onSelect() {
      this.$emit('select')
    },
    onDelete() {
      if (this.deletable) {
        this.$emit('delete')
      }
    },
  },
}
</script>
