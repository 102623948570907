<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('accountManagement.organisations-editContacts-headline') }}
    </template>

    <div class="flex flex-col border-b-[3px] border-lightestGray py-10">
      <span class="section-label">
        {{ $t('accountManagement.organisations-edit-contact') }}
      </span>
      <div class="flex items-center">
        <Select
          class="flex-grow"
          v-model="contactSelectModel"
          :items="contactOptions"
          :disabled="v$.currentEdit.$invalid || pending"
          itemsIndicator
        />
        <Button
          class="px-4 ml-8"
          variant="primary"
          icon="plus"
          iconClasses="w-5"
          :disabled="v$.currentEdit.$invalid || pending"
          @click="addEmptyContact"
        >
          {{ $t('accountManagement.organisations-editContacts-create') }}
        </Button>
      </div>
      <div class="flex flex-wrap mt-2">
        <SelectionPill
          v-for="{ text, key } in contactOptions"
          :key="key"
          class="ml-3 mt-3"
          :label="text"
          :highlighted="contactSelectModel.key === key"
          :disabled="
            contactSelectModel.key !== key &&
            (v$.currentEdit.$invalid || pending)
          "
          :deletable="contactOptions.length > 1"
          @select="onPillSelect(key)"
          @delete="onPillDelete(key)"
        />
      </div>
    </div>
    <div class="flex flex-col pt-10 pb-5">
      <span class="section-label">
        {{ `${$t('accountManagement.organisations-editContacts-info')}*` }}
      </span>
      <div class="input-grid mt-2">
        <Input
          v-model="currentEdit.fullName"
          :placeholder="$t('input.contactName')"
          :errorMessage="errorMessages.fullName"
          @blur="v$.currentEdit.fullName.$touch()"
        />
        <Input
          v-model="currentEdit.companyRole"
          :placeholder="$t('input.companyPosition')"
          :errorMessage="errorMessages.companyRole"
          @blur="v$.currentEdit.companyRole.$touch()"
        />
        <Input
          v-model="currentEdit.email"
          :placeholder="$t('input.email')"
          :errorMessage="errorMessages.email"
          @blur="v$.currentEdit.email.$touch()"
        />
        <Input
          v-model="currentEdit.mobile"
          :placeholder="$t('input.mobileNumber')"
          :errorMessage="errorMessages.mobile"
          @blur="v$.currentEdit.mobile.$touch()"
        />
        <Input
          v-model="currentEdit.phone"
          :placeholder="$t('input.phoneNumber')"
          :errorMessage="errorMessages.phone"
          @blur="v$.currentEdit.phone.$touch()"
        />
      </div>
      <span class="text-[14px] text-darkGray">
        {{ `*${$t('input.requiredFields')}` }}
      </span>
    </div>

    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="pending"
          variant="primary"
          @click="confirm"
        >
          {{ $t('general.save') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { clone } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { Input, Select, Button } from '@/components/form'
import SelectionPill from '@/components/SelectionPill'
import { organisations as organisationsApi } from '@/services/api'
import { useDialog } from '@/composition'
import Dialog from './Dialog'

// counter for v-for keying of contact list
let contactKey = 0

export default {
  components: {
    Dialog,
    Input,
    Button,
    Select,
    SelectionPill,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      default: () => ({ contacts: [] }),
    },
  },
  emits: ['update:modelValue', 'edit'],
  setup(props, { emit }) {
    const request = useRequest(org => organisationsApi.edit(org))
    const validators = useValidators()

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      currentEdit: Object.assign({}, this.emptyContact),
      contacts: [],
    }
  },
  validations() {
    const fieldValidations = Object.keys(this.emptyContact).reduce(
      (result, field) => {
        result[field] = { required: this.validators.required }
        return result
      },
      {}
    )
    fieldValidations.email.email = this.validators.email

    return { currentEdit: fieldValidations }
  },
  computed: {
    emptyContact() {
      return {
        key: 0,
        fullName: this.$t(
          'accountManagement.organisations-editContacts-newName'
        ),
        companyRole: '',
        email: '',
        phone: '',
        mobile: '',
      }
    },
    errorMessages() {
      return this.validators.errorMessages(
        this.v$.currentEdit,
        Object.keys(this.emptyContact)
      )
    },
    contactOptions() {
      return this.contacts.map(this.contactToOptionsEntry)
    },
    contactSelectModel: {
      get() {
        return this.contactToOptionsEntry(this.currentEdit)
      },
      set({ key }) {
        this.currentEdit = this.contacts.find(c => c.key === key)
        this.v$.currentEdit.$reset()
      },
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    contactToOptionsEntry({ key, fullName: text }) {
      return { key, text }
    },
    addEmptyContact() {
      const key = contactKey++ // just for select input, irrelevant for api call
      this.contacts.push({ ...this.emptyContact, key })
      this.contactSelectModel = { key }
    },
    onPillSelect(key) {
      if (this.contactSelectModel.key !== key) {
        this.contactSelectModel = { key }
      }
    },
    onPillDelete(key) {
      if (this.contactSelectModel.key === key) {
        let idx = this.contacts.findIndex(c => c.key === key)
        idx > 0 ? idx-- : idx++
        this.currentEdit = this.contacts[idx]
      }
      this.contacts = this.contacts.filter(c => c.key !== key)
    },
    async confirm() {
      this.v$.currentEdit.$touch()
      if (this.v$.currentEdit.$error) {
        return
      }
      try {
        const payload = clone(this.organisation)
        // remove key, just used for v-for keying of entries
        payload.contacts = this.contacts.map(c => ({ ...c, key: undefined }))
        const user = await this.request(payload)
        this.$emit('edit', user)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'organisations-editContacts-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'organisations-editContacts-error',
        })
      }
    },
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }

      this.v$.currentEdit.$reset()
      this.error = false

      if (this.organisation.contacts.length > 0) {
        this.contacts = this.organisation.contacts.map(c => ({
          ...c,
          key: contactKey++,
        }))
      } else {
        this.contacts = [Object.assign({}, this.emptyContact)]
      }
      this.currentEdit = this.contacts[0]
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
.input-grid {
  @apply grid grid-cols-2 gap-x-10;
}
.input-grid > * {
  @apply w-[358px] mt-1;
}
</style>
