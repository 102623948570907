import { computed, ref, toRef, WritableComputedRef } from 'vue'

interface IUseDialog {
  isOpen: WritableComputedRef<boolean>
  close: () => void
}

type Emit = (name: string, ...args: unknown[]) => void

export default (
  props: { modelValue: boolean },
  emit: Emit,
  preventClose = ref(false)
): IUseDialog => {
  const modelValue = toRef(props, 'modelValue')

  const isOpen = computed({
    get: () => modelValue.value,
    set: val => {
      if (!preventClose.value) {
        emit('update:modelValue', val)
      }
    },
  })

  const close = () => {
    isOpen.value = false
  }

  return {
    isOpen,
    close,
  }
}
