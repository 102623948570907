<template>
  <div class="flex items-center select-none">
    <Icon
      class="w-6 h-6 mr-2"
      :class="classes.arrow[hasPrev ? 'active' : 'inactive']"
      name="arrow-back"
      @click="goPrev"
    />

    <ul class="flex">
      <li
        v-for="button in buttons"
        :key="button.page"
        class="flex flex-center w-8 h-8"
        :class="
          classes.token[
            button.active ? 'active' : button.ellipsis ? 'ellipsis' : 'default'
          ]
        "
        @click="$emit('update:page', button.page)"
      >
        {{ button.ellipsis ? '...' : button.page }}
      </li>
    </ul>

    <Icon
      class="w-6 h-6 ml-2"
      :class="classes.arrow[hasNext ? 'active' : 'inactive']"
      name="arrow-forward"
      @click="goNext"
    />
  </div>
</template>

<script>
import Icon from '@/components/Icon'

const classes = {
  arrow: {
    active: 'text-primary cursor-pointer',
    inactive: 'text-[#878787] pointer-events-none',
  },
  token: {
    default: 'text-xs text-[#878787] hover:underline cursor-pointer',
    active:
      'text-primary font-bold transform translate-y-[-1px] pointer-events-none',
    ellipsis: 'text-xs text-[#878787] cursor-pointer',
  },
}

export default {
  components: {
    Icon,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    hasPrev: {
      type: Boolean,
      required: true,
    },
    hasNext: {
      type: Boolean,
      required: true,
    },
    goPrev: {
      type: Function,
      required: true,
    },
    goNext: {
      type: Function,
      required: true,
    },
  },
  emits: ['update:page'],
  data() {
    return {
      classes,
    }
  },
}
</script>
