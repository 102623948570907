import axios, { AxiosError, AxiosInstance } from 'axios'
import store from '@/store'
import router from '@/router'
import { langSelect } from '@/services'

const getInstance = (contentType: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 35000,
    headers: {
      'content-type': contentType,
    },
  })

  instance.interceptors.request.use(
    config => {
      if (store.getters['auth/loggedIn']) {
        const token = store.getters['auth/token']
        config.headers['Authorization'] = `Bearer ${token}`
      }
      if (!config.headers['Accept-Language']) {
        config.headers['Accept-Language'] = langSelect.getLocale()
      }
      return config
    },
    (error: AxiosError) => Promise.reject(error)
  )

  instance.interceptors.response.use(
    // status code within 2xx trigger this function
    response => response,
    // status codes outside 2xx trigger this function
    (error: AxiosError) => {
      if (
        !axios.isCancel(error) &&
        error.response?.status === 401 &&
        store.getters['auth/loggedIn'] &&
        router.currentRoute.value.name != 'assetAccessPermission' // Even though it is allowed to be accessed anonymously, it can be accessed within a session. Since a 401 response has a meaning, no redirecting should take place.
      ) {
        store.commit(
          'auth/setInitialRouteName',
          router.currentRoute.value.fullPath
        )
        store.dispatch('auth/logout')
        router.push({ name: 'login' })
      }
      return Promise.reject(error)
    }
  )
  return instance
}

export default getInstance
