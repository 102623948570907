<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ texts.title }}
    </template>
    <template #description>
      <p>{{ texts.desc1 }}</p>
      <p class="mt-8">
        {{ texts.desc2 }}
      </p>
      <p class="mt-8">
        {{ texts.desc3 }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ texts.action }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { useDialog, useRequest } from '@/composition'
import { EAssetState } from '@/enums'
import { Button } from '@/components/form'
import Dialog from './Dialog'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    newState: {
      type: Number,
      required: true,
      validator: val => EAssetState[val] !== undefined,
    },
    isBuyerAsset:{
      type:Boolean
    }
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()
    const evaluationRequest = useRequest(() =>
      store.dispatch('questionnaire/changeAssetState', {
        assetId: props.id,
        assetState: props.newState,
        isBuyerAsset:props.isBuyerAsset
      })
    )
    return {
      ...useDialog(props, emit, evaluationRequest.pending),
      ...evaluationRequest,
    }
  },
  computed: {
    texts() {
      switch (this.newState) {
        case EAssetState.Evaluation:
          return {
            title: this.$t('score.record-toEvaluation-headline'),
            desc1: this.$t('score.record-toEvaluation-description1'),
            desc2: this.$t('score.record-toEvaluation-description2'),
            desc3: this.$t('score.record-toEvaluation-description3'),
            action: this.$t('score.record-toEvaluation-action'),
            success: 'questionnaire-toEvaluation-success',
            error: 'questionnaire-toEvaluation-error',
          }
        case EAssetState.Closed:
          return {
            title: this.$t('score.record-toClosed-headline'),
            desc1: this.$t('score.record-toClosed-description1'),
            desc2: this.$t('score.record-toClosed-description2'),
            desc3: this.$t('score.record-toClosed-description3'),
            action: this.$t('score.record-toClosed-action'),
            success: 'questionnaire-toClosed-success',
            error: 'questionnaire-toClosed-error',
          }
        case EAssetState.Declined:
          return {
            title: this.$t('score.record-toDeclined-headline'),
            desc1: this.$t('score.record-toDeclined-description1'),
            desc2: this.$t('score.record-toDeclined-description2'),
            desc3: this.$t('score.record-toDeclined-description3'),
            action: this.$t('score.record-toDeclined-action'),
            success: 'questionnaire-toDeclined-success',
            error: 'questionnaire-toDeclined-error',
          }
        case EAssetState.Verification:
          return {
            title: this.$t('score.record-toVerification-headline'),
            desc1: this.$t('score.record-toVerification-description1'),
            desc2: this.$t('score.record-toVerification-description2'),
            desc3: this.$t('score.record-toVerification-description3'),
            action: this.$t('score.record-toVerification-action'),
            success: 'questionnaire-toVerification-success',
            error: 'questionnaire-toVerification-error',
          }
        default:
          return null
      }
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('finish')
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: this.texts.success,
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: this.texts.error,
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
