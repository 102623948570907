import type { IAuthState } from './types'

export default (): IAuthState => ({
  userName: '',
  token: '',
  tokenDecoded: null,
  roles: [],
  initialRouteName: '',
  organisationIsTestAccount: false,
  organisationIsFinancePartner: false,
  organisationIsBuyer:false
})
