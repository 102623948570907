<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t(`score.${textsPrefix}-toAudit-headline`) }}
    </template>
    <template #description>
      <p>
        {{ $t(`score.${textsPrefix}-toAudit-description1`, { name }) }}
      </p>
      <p class="mt-8">
        {{ $t(`score.${textsPrefix}-toAudit-description2`) }}
      </p>
      <p class="mt-8">
        {{ $t(`score.${textsPrefix}-toAudit-description3`) }}
      </p>

      <p class="mt-8" v-if="!isForGroup">
        {{ $t(`score.record-toAudit-description4`) }}
      </p>
      <Checkbox
        v-if="isForGroup"
        class="text-darkGray mt-6"
        v-model="checkBoxValue"
      >
        {{ $t(`score.group-toAudit-checkbox`) }}
      </Checkbox>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="closeModal">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending || !checkBoxValue"
          @click="confirm"
        >
          {{ $t('score.record-startAudit') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { useDialog, useRequest } from '@/composition'
import { EAssetState } from '@/enums'

import { Button, Checkbox } from '@/components/form'
import Dialog from './Dialog'
import { objectGroups as objectGroupsApi } from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
    Checkbox,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isForGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBuyerAsset:{
      type:Boolean
    }
  },
  data() {
    return {
      checkBoxValue: !this.isForGroup,
    }
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()
    const auditRequest = useRequest(() => {
      if (props.isForGroup) {
        return objectGroupsApi.setAssetGroupState(props.id, EAssetState.Review)
      } else {
        return store.dispatch('questionnaire/changeAssetState', {
          assetId: props.id,
          assetState: EAssetState.Review,
          isBuyerAsset:props.isForGroup?false:props.isBuyerAsset
        })
      }
    })
    return {
      ...useDialog(props, emit, auditRequest.pending),
      ...auditRequest,
    }
  },
  computed: {
    textsPrefix() {
      return this.isForGroup ? 'group' : 'record'
    },
    notificationPrefix() {
      return this.isForGroup ? 'objectGroups' : 'questionnaire'
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('finish', this.$props.id)
        this.closeModal()
        this.showMessage({
          type: 'success',
          translationKey: `${this.notificationPrefix}-toAudit-success`,
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: this.getTranslationKey(error),
        })
      }
    },
    closeModal() {
      if (this.isForGroup) {
        this.checkBoxValue = false
      }
      this.close()
    },

    getTranslationKey(error) {
      const objectGroupPrefix = 'objectGroups-change'
      const objectPrefix = 'questionnaire-change'
      // is just integer that backend return us, so we display different errors from translations
      const customErrorCode = error?.response?.data?.customErrorCode
      // default values
      let translationKey = this.isForGroup
        ? `${objectGroupPrefix}-0`
        : `${objectPrefix}-0`

      if (this.isForGroup) {
        if (this.$te(`notifications.${objectGroupPrefix}-${customErrorCode}`)) {
          translationKey = `${objectGroupPrefix}-${customErrorCode}`
        }
      } else {
        // for questionaries
        if (this.$te(`notifications.${objectPrefix}-${customErrorCode}`)) {
          translationKey = `${objectPrefix}-${customErrorCode}`
        }
      }
      return translationKey
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
