import { flatten, flattenDeep, isNil } from 'lodash-es'
import type { IAnswer } from '@/models/question'
import type {
  IQuestionnaireInStore,
  IClusterInStore,
  ICategoryInStore,
  ISectionInStore,
  IQuestionInStore,
  ICategoryRetrieval,
  ISectionRetrieval,
  IQuestionRetrieval,
} from './types'
import {
  EAnswerState,
  EAssetState,
  EQuestionnaireState,
  EQuestionnaireType,
  EQuestionType,
} from '@/enums'
import {
  retrieveCategory,
  retrieveSection,
  retrieveQuestion,
  questionIsAnswered,
} from './helpers'
import { ILockedStatus } from '@/models/questionnaire'

export default {
  isModified(state: IQuestionnaireInStore): boolean {
    return state.modified
  },
  lockedStatus(state: IQuestionnaireInStore): ILockedStatus | null {
    return state.lockedStatus
  },
  isTemplateEditable(state: IQuestionnaireInStore): boolean {
    return state.state === EQuestionnaireState.Draft
  },
  id(state: IQuestionnaireInStore): number {
    return state.id
  },
  state(state: IQuestionnaireInStore): EQuestionnaireState | null {
    return state.state
  },
  type(state: IQuestionnaireInStore): EQuestionnaireType | null {
    return state.type
  },
  assetState(state: IQuestionnaireInStore): EAssetState | null {
    return state.assetQuestionnaireState
  },
  canSetGroupDefaultAnswers(state: IQuestionnaireInStore): boolean | null {
    return state.canSetGroupDefaultAnswers
  },
  canBeSubmitted(state: IQuestionnaireInStore): boolean | null {
    return state.canBeSubmitted
  },
  canBeReviewed(state: IQuestionnaireInStore): boolean | null {
    return state.canBeReviewed
  },
  canBeUpdatedToActiveQuestionnaire(state: IQuestionnaireInStore): boolean | null {
    return state.canBeUpdatedToActiveQuestionnaire
  },
  clusters(state: IQuestionnaireInStore): Array<IClusterInStore> {
    return state.clusters
  },
  title(state: IQuestionnaireInStore): string {
    return state.title
  },
  getCategory(state: IQuestionnaireInStore) {
    return (
      retrievalConfig: ICategoryRetrieval
    ): ICategoryInStore | undefined => retrieveCategory(state, retrievalConfig)
  },
  getSection(state: IQuestionnaireInStore) {
    return (retrievalConfig: ISectionRetrieval): ISectionInStore | undefined =>
      retrieveSection(state, retrievalConfig)
  },
  getQuestion(state: IQuestionnaireInStore) {
    return (
      retrievalConfig: IQuestionRetrieval
    ): IQuestionInStore | undefined => retrieveQuestion(state, retrievalConfig)
  },
  getClusterVirtQuestions(state: IQuestionnaireInStore) {
    return (clusterIdx: number): Array<IQuestionInStore> =>
      flattenDeep(
        state.clusters[clusterIdx].categories.map(cat =>
          cat.sections.map(sect =>
            sect.questions.filter(q => q.type === EQuestionType.VirtualQuestion)
          )
        )
      )
  },
  getClusterQuestions(state: IQuestionnaireInStore) {
    return (clusterIdx: number): Array<IQuestionInStore> =>
      flattenDeep(
        state.clusters[clusterIdx].categories.map(cat =>
          cat.sections.map(sect => sect.questions)
        )
      )
  },
  flattenedQuestions(
    state: IQuestionnaireInStore,
    getters: { getClusterQuestions: (idx: number) => Array<IQuestionInStore>, getClustersIdentifierArray: Array<number> }
  ): Array<IQuestionInStore> {
    return flatten(getters.getClustersIdentifierArray.map(getters.getClusterQuestions))
  },
  modifiedQuestions(
    state: IQuestionnaireInStore,
    getters: { flattenedQuestions: Array<IQuestionInStore> }
  ): Array<IQuestionInStore> {
    const questions = getters.flattenedQuestions
    return questions.filter(q => q.modified)
  },
  getClusterAnswers(
    state: IQuestionnaireInStore,
    getters: { getClusterQuestions: (idx: number) => Array<IQuestionInStore> }
  ) {
    return (clusterIdx: number): Array<IAnswer> =>
      getters
        .getClusterQuestions(clusterIdx)
        .filter(q => !isNil(q.answer?.id))
        .map(q => q.answer!)
  },
  getAllAnswers(
    state: IQuestionnaireInStore,
    getters: { flattenedQuestions: Array<IQuestionInStore> }
  ) {
    return (clusterIdx: number): Array<IAnswer> =>
      getters.flattenedQuestions
        .filter(q => !isNil(q.answer?.id))
        .map(q => q.answer!)
  },
  answeredQuestionsPercentage(
    state: IQuestionnaireInStore,
    getters: { flattenedQuestions: Array<IQuestionInStore> }
  ): number {
    const questions = getters.flattenedQuestions.filter(question => question.relevantForReview)
    const answeredQuestions = questions.filter(questionIsAnswered).filter(question => question.relevantForReview)
    return questions.length == 0 ? 0 : Math.round((answeredQuestions.length * 100) / questions.length)
  },
  reviewedQuestionsPercentage(
    state: IQuestionnaireInStore,
    getters: { flattenedQuestions: Array<IQuestionInStore> }
  ): number {
    const questions = getters.flattenedQuestions
    const reviewedQuestions = questions.filter(
      ({ answer }) =>
        answer?.state === EAnswerState.Declined ||
        answer?.state === EAnswerState.Verification ||
        answer?.state === EAnswerState.ReviewAccepted
    )
    return Math.round((reviewedQuestions.length * 100) / questions.length)
  },
  evaluatedQuestionsPercentage(
    state: IQuestionnaireInStore,
    getters: { flattenedQuestions: Array<IQuestionInStore> }
  ): number {
    const questions = getters.flattenedQuestions
    const evaluatedQuestions = questions.filter(
      ({ answer }) =>
        answer?.state === EAnswerState.Declined ||
        answer?.state === EAnswerState.EvaluationAccepted
    )
    return Math.round((evaluatedQuestions.length * 100) / questions.length)
  },
  isNewQuestion() {
    return function (id: number): boolean {
      return id < 0
    }
  },
  getClustersIdentifierArray(state: IQuestionnaireInStore) {
    return Array.from(Array(state.clusters.length)).map((n, i) => i)
  },
  getActiveQuestinnaire(state: IQuestionnaireInStore) {
    return state.activateQuestionnaire
  }
}
