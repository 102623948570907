import { App } from 'vue'

export default {
  install(app: App): void {
    app.directive('clickOutside', {
      mounted(el, binding) {
        el.clickOutsideEvent = (event: Event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value()
          }
        }
        document.addEventListener('click', el.clickOutsideEvent, true)
      },
      unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent)
      },
    })
  },
}
