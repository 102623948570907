<template>
  <Dialog v-model="isOpen">
    <template #title>{{ headline }}</template>
    <div class="flex flex-col border-b-[3px] border-lightestGray py-10">
      <div class="flex flex-col mt-2">
        <Select
          v-model="clusterSelectModel"
          :items="clusterOptions"
          :placeholder="$t('score.cluster')"
          :errorMessage="errorMessages.clusterType"
          @close="v$.structureInputs.clusterType.$touch()"
        />
        <Select
          v-if="!selectIsInvisible(EQuestionnaireLevel.Category)"
          v-model="categorySelectModel"
          :items="categoryOptions"
          :disabled="selectIsDisabled(EQuestionnaireLevel.Category)"
          :placeholder="$t('score.category')"
          :errorMessage="errorMessages.categoryId"
          @close="v$.structureInputs.categoryId.$touch()"
        />
        <Select
          v-if="!selectIsInvisible(EQuestionnaireLevel.Section)"
          v-model="sectionSelectModel"
          :items="sectionOptions"
          :disabled="selectIsDisabled(EQuestionnaireLevel.Section)"
          :placeholder="$t('score.section')"
          :errorMessage="errorMessages.sectionId"
          @close="v$.structureInputs.sectionId.$touch()"
        />
        <Input
          v-model="structureInputs.title"
          :placeholder="$t('input.name')"
          :errorMessage="errorMessages.title"
          @close="v$.structureInputs.title.$touch()"
        />
      </div>
    </div>

    <template #actions>
      <div>
        <Button class="w-[240px]" @click="close">{{ $t('general.cancel') }}</Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          variant="primary"
          @click="confirm"
        >{{ $t(`general.create`) }}</Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { EQuestionnaireLevel } from '@/enums'
import { general, question as questionHelpers } from '@/helpers'
import { useValidators } from '@/composition'
import { Select, Input, Button } from '@/components/form'
import Dialog from './Dialog'
import { useDialog } from '@/composition'

const emptyCategoryStructure = {
  title: '',
  clusterType: null,
}

const emptySectionStructure = {
  ...emptyCategoryStructure,
  categoryId: null,
}

const emptyQuestionStructure = {
  ...emptySectionStructure,
  sectionId: null,
}

export default {
  components: {
    Dialog,
    Select,
    Input,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    level: {
      type: Number,
      required: true,
      validator: val => EQuestionnaireLevel[val] !== undefined,
    },
  },
  emits: ['update:modelValue', 'create'],
  setup(props, { emit }) {
    const validators = useValidators()
    return {
      ...useDialog(props, emit),
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      structureInputs: Object.assign({}, this.emptyStructure),
      EQuestionnaireLevel,
    }
  },
  validations() {
    const fieldValidations = Object.keys(this.emptyStructure).reduce(
      (result, field) => {
        result[field] = { required: this.validators.required }
        return result
      },
      {}
    )
    return {
      structureInputs: fieldValidations,
    }
  },
  computed: {
    ...mapGetters('questionnaire', [
      'clusters',
      'getCategory',
      'getSection',
      'getClustersIdentifierArray',
    ]),

    errorMessages() {
      return this.validators.errorMessages(
        this.v$.structureInputs,
        Object.keys(this.emptyStructure)
      )
    },

    headline() {
      return this.$t(`score.${this.levelString(this.level)}-create-headline`)
    },

    emptyStructure() {
      switch (this.level) {
        case EQuestionnaireLevel.Question:
          return emptyQuestionStructure
        case EQuestionnaireLevel.Section:
          return emptySectionStructure
        default:
          return emptyCategoryStructure
      }
    },

    clusterOptions() {
      return this.getClustersIdentifierArray.map(clusterIdx => ({
        key: clusterIdx,
        text: this.$t(`score.cluster-name-${clusterIdx}`),
      }))
    },

    clusterSelectModel: {
      get() {
        return this.clusterOptions.find(
          o => o.key === this.structureInputs.clusterType
        )
      },
      set({ key }) {
        if (key !== this.structureInputs.clusterType) {
          this.structureInputs.clusterType = key
          this.resetInputs(
            EQuestionnaireLevel.Category,
            EQuestionnaireLevel.Section
          )
        }
      },
    },

    categoryOptions() {
      if (!this.selectHasOptions(EQuestionnaireLevel.Category)) {
        return []
      }
      return this.clusters[this.structureInputs.clusterType].categories.map(
        ({ id, name }) => ({
          key: id,
          text: questionHelpers.defaultingLangEntry(name).name,
        })
      )
    },

    categorySelectModel: {
      get() {
        if (!this.selectHasOptions(EQuestionnaireLevel.Category)) {
          return null
        }
        return this.categoryOptions.find(
          o => o.key === this.structureInputs.categoryId
        )
      },
      set({ key }) {
        if (key !== this.structureInputs.categoryId) {
          this.structureInputs.categoryId = key
          this.resetInputs(EQuestionnaireLevel.Section)
        }
      },
    },

    sectionOptions() {
      if (!this.selectHasOptions(EQuestionnaireLevel.Section)) {
        return []
      }
      const cat = this.getCategory({
        clusterIdx: this.structureInputs.clusterType,
        categoryId: this.structureInputs.categoryId,
      })
      return cat.sections.map(({ id, name }) => ({
        key: id,
        text: questionHelpers.defaultingLangEntry(name).name,
      }))
    },

    sectionSelectModel: {
      get() {
        if (!this.selectHasOptions(EQuestionnaireLevel.Section)) {
          return null
        }
        return this.sectionOptions.find(
          o => o.key === this.structureInputs.sectionId
        )
      },
      set({ key }) {
        if (key !== this.structureInputs.sectionId) {
          this.structureInputs.sectionId = key
        }
      },
    },
  },
  methods: {
    ...mapActions('questionnaire', ['addItem']),
    async confirm() {
      this.v$.structureInputs.$touch()
      if (this.v$.structureInputs.$error) {
        return
      }
      const params = await this.addItemToQuestionnaire()
      this.$emit('create', params)
      this.close()
    },

    addItemToQuestionnaire() {
      return this.addItem({
        level: this.level,
        clusterIdx: this.structureInputs.clusterType,
        categoryId: this.structureInputs.categoryId,
        sectionId: this.structureInputs.sectionId,
        title: this.structureInputs.title,
      })
    },

    levelString(levelEnum) {
      return general.enumString(EQuestionnaireLevel, levelEnum)
    },

    levelField(levelEnum) {
      const base = this.levelString(levelEnum)
      const suffix = levelEnum === EQuestionnaireLevel.Cluster ? 'Type' : 'Id'
      return `${base}${suffix}`
    },

    selectIsDisabled(levelEnum) {
      return this.structureInputs[this.levelField(levelEnum - 1)] === null
    },

    selectIsInvisible(levelEnum) {
      return levelEnum >= this.level
    },

    selectHasOptions(levelEnum) {
      return (
        !this.selectIsDisabled(levelEnum) && !this.selectIsInvisible(levelEnum)
      )
    },

    resetInputs(...levelEnums) {
      levelEnums.forEach(levelEnum => {
        if (levelEnum < this.level) {
          const field = this.levelField(levelEnum)
          this.structureInputs[field] = null
          this.v$.structureInputs[field].$reset()
        }
      })
    },

    initInputs() {
      this.structureInputs = Object.assign({}, this.emptyStructure)

      if (this.$route.params.clusterNum !== undefined) {
        this.structureInputs.clusterType = parseInt(
          this.$route.params.clusterNum
        )
      }

      if (
        this.level > EQuestionnaireLevel.Category &&
        this.$route.params.categoryId !== undefined
      ) {
        const cat = this.getCategory({
          clusterIdx: this.structureInputs.clusterType,
          categoryId: parseInt(this.$route.params.categoryId),
        })
        this.structureInputs.categoryId = cat.id
      }

      if (
        this.level > EQuestionnaireLevel.Section &&
        this.$route.params.sectionId !== undefined
      ) {
        const section = this.getSection({
          clusterIdx: this.structureInputs.clusterType,
          categoryId: parseInt(this.$route.params.categoryId),
          sectionId: parseInt(this.$route.params.sectionId),
        })
        this.structureInputs.sectionId = section.id
      }
    },
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }
      this.initInputs()
      this.v$.structureInputs.$reset()
    },
  },
}
</script>
