import { ActionContext } from 'vuex'
import { State } from '@/store'

import type { IToastMessage, IToastMessageState } from './types'

type Context = ActionContext<IToastMessageState, State>

export default {
  showMessage({ commit }: Context, config: IToastMessage): void {
    commit('showMessage', config)
  },
}
