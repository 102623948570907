<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.questionnaire-delete-headline') }}
    </template>
    <template #description>
      <p>
        {{ $t('score.questionnaire-delete-description1', { title }) }}
      </p>
      <p class="mt-8">
        {{ $t('score.questionnaire-delete-description2') }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('general.delete') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Dialog from './Dialog'
import { useDialog } from '@/composition'
import { useRequest } from '@/composition'
import { Button } from '@/components/form'
import { questionnaires as questionnairesApi } from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: String,
      required: true,
    },
    questionnaireId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean
    }
  },
  emits: ['update:modelValue', 'delete'],
  setup(props, { emit }) {
    const request = useRequest(() =>
      questionnairesApi.deleteAssetQuest(props.assetId, props.questionnaireId,props.isBuyerAsset)
    )

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('delete', this.questionnaireId)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-delete-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-delete-error',
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
