<template>
  <section class="flex flex-col">
    <div class="flex-grow overflow-y-scroll" ref="scrollParent">
      <div class="relative h-[30vh]">
        <picture>
          <source
            type="image/webp"
            srcset="
              @/assets/images/bg-bar.webp    1x,
              @/assets/images/bg-bar@2x.webp 2x
            "
          />
          <source
            type="image/png"
            srcset="
              @/assets/images/bg-bar.png    1x,
              @/assets/images/bg-bar@2x.png 2x
            "
          />
          <img
            src="@/assets/images/bg-bar.png"
            alt="background"
            class="absolute bottom-0 w-full"
          />
        </picture>
        <h1
          class="
            relative
            text-[2.125rem] text-white
            font-light
            px-[6.25rem]
            pt-[4.5rem]
          "
        >
          {{ heading }}
        </h1>
      </div>
      <div class="pt-[2.75rem] px-[6.25rem] pb-[1.75rem]">
        <slot />
        <GoUp />
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import GoUp from '@/components/GoUp'
import Footer from './Footer'
import { ref, provide } from 'vue'

export default {
  components: {
    GoUp,
    Footer,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
  },
  setup() {
    const scrollParent = ref(null)
    provide('scrollParent', scrollParent)
    return {
      scrollParent,
    }
  },
}
</script>
