<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>{{ $t('score.questionnaire-create-headline') }}</template>

    <div class="flex flex-col py-10">
      <span class="section-label">{{ `${$t('score.questionnaire-create-questionnaire')}*` }}</span>
      <div class="flex flex-col mt-2">
        <Input
          v-model="questionnaireInputs.name"
          :placeholder="$t('input.questionnaireName')"
          :errorMessage="errorMessages.name"
          @blur="v$.questionnaireInputs.name.$touch()"
        />
      </div>
      <span class="text-[14px] text-darkGray mt-2">{{ `*${$t('input.requiredFields')}` }}</span>
    </div>

    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">{{ $t('general.cancel') }}</Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="pending"
          variant="primary"
          @click="confirm"
        >{{ $t('general.create') }}</Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { inject } from 'vue'
import { mapActions } from 'vuex'
import { useRequest, useValidators } from '@/composition'
import useVuelidate from '@vuelidate/core'
import { useDialog } from '@/composition'
import Dialog from './Dialog'
import { Input, Button } from '@/components/form'
import { questionnaires as questionnairesApi } from '@/services/api'
import { EQuestionnaireState } from '@/enums'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Dialog,
    Input,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const { t, availableLocales } = useI18n()
    const numQuestionnaires = inject('numQuestionnaires')
    const request = useRequest(questionnaire =>
      questionnairesApi.create(questionnaire, numQuestionnaires.value === 0)
    )
    const validators = useValidators()

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
      validators,
      availableLocales,
      t,
    }
  },
  inject: ['activeQuestionnaireId'],
  data() {
    return {
      v$: useVuelidate(),
      questionnaireInputs: {
        name: '',
      },
    }
  },
  validations() {
    return {
      questionnaireInputs: {
        name: {
          required: this.validators.required,
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return this.validators.errorMessages(this.v$.questionnaireInputs, [
        'name',
      ])
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      this.v$.questionnaireInputs.$touch()
      if (this.v$.questionnaireInputs.$error) {
        return
      }
      try {
        const payload = {
          id: this.activeQuestionnaireId.value,
          title: this.questionnaireInputs.name,
          state: EQuestionnaireState.Draft,
          clusters: this.createClusters(),
        }
        const questionnaire = await this.request(payload)
        this.$emit('finish', questionnaire)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-create-success',
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: `questionnaire-create-${
            error.response.status === 400 ? 'onlyOnce' : 'error'
          }`,
        })
      }
    },
    getClusterNameTranslations(translationKey) {
      return this.availableLocales.map(locale => ({
        language: locale,
        name: this.t(translationKey, translationKey, { locale }),
      }))
    },
    createClusters() {
      const numberOfClusters = process.env.VUE_APP_CLUSTER_COUNT
      const weights = this.divideWeight(100, numberOfClusters)
      return Array.from(Array(+numberOfClusters).keys()).map(n => {
        return {
          name: this.getClusterNameTranslations(`score.cluster-name-${n}`),
          weight: weights[n],
          categories: [],
        }
      })
    },
    divideWeight(number, numberOfClusters) {
      var values = []
      while (number > 0 && numberOfClusters > 0) {
        var a = Math.floor(number / numberOfClusters)
        number -= a
        numberOfClusters--
        values.push(a)
      }
      return values
    },
  },
  watch: {
    modelValue(opening) {
      if (!opening) {
        return
      }

      this.v$.questionnaireInputs.$reset()
      this.error = false

      Object.assign(this.questionnaireInputs, { name: '' })
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
</style>
