<template>
  <span class="inline-flex flex-col items-center">
    <span>
      <slot />
    </span>
    <span class="h-0 invisible" :class="highlightClass">
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  props: {
    highlightClass: {
      type: String,
      required: true,
    },
  },
}
</script>
