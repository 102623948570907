export default {
  "general": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save all"])},
    "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
    "evaluate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "language-de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "language-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "assetClass-OFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
    "assetClass-HOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "assetClass-RHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail, High Street"])},
    "assetClass-RSM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping centers"])},
    "assetClass-RWB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local suppliers"])},
    "assetClass-RWB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail parks"])},
    "assetClass-DWH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
    "assetClass-HEC1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Care real estate"])},
    "assetClass-HEC2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social real estate"])},
    "assetClass-LEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lodging, Leisure & Recreation"])},
    "assetClass-MIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mixed Use"])},
    "assetClass-RESI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential"])},
    "assetClass-pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilot"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "years": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["year"]), _normalize(["years"])])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
    "country-AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "country-AUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "country-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "country-BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "country-BRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "country-HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "country-CAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "country-CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "country-CHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "country-CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "country-CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
    "country-DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "country-EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "country-FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "country-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "country-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "country-EL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "country-HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Kong"])},
    "country-HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "country-IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "country-IND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "country-IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "country-KOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Korea"])},
    "country-JAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "country-LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "country-LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "country-LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "country-MAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "country-MEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "country-MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "country-NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "country-NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "country-NZL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "country-PHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "country-PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "country-PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "country-RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "country-SGP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "country-SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "country-SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "country-ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "country-SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "country-UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "country-USA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])},
    "country-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country not in the list"])},
    "screenTooSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please view this site on a wider screen (min 1024px)"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted user"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "download-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Label"])},
    "search-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search options"])},
    "more-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Asset class"])}
  },
  "roles": {
    "accountAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Administrator"])},
    "billingManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Manager"])},
    "evaluator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
    "scoringModelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Model Manager"])},
    "objectAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Administrator"])},
    "accountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
    "objectDataCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Data Collector"])},
    "objectAnalyst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Analyst"])},
    "internalTester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Tester"])},
    "externalAccreditedTester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "prospectiveBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prospective Buyer"])}
  },
  "pages": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Management"])},
    "scoreAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin Area"])},
    "editQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "questionnairesActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Questionnaires"])},
    "questionnairesArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived Questionnaires"])},
    "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Picture"])},
    "accountManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Management"])},
    "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "organisationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])},
    "assetManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Management"])},
    "objectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Management"])},
    "objectGroupManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Group Management"])},
    "accessRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Request"])},
    "editObjectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Info"])},
    "questionnaireResultsOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire Overview"])},
    "cluster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cluster ", _interpolate(_named("num"))])},
    "jobManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Management"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
    "jobObjectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Management"])},
    "jobObjectGroupManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Group Management"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "checkAsset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate Object"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "objectExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object"])},
    "objectGroupExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object Group"])},
    "customerDataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Data"])}
  },
  "input": {
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required Fields"])},
    "pleaseChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation name"])},
    "companyPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company position"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile number"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street number"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "contactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact name"])},
    "objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object name / name of project"])},
    "objectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group name"])},
    "objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group"])},
    "assetClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset class"])},
    "assetClass-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If one type of assetclass accounts for more than 85% of the main use, the other types of assetclass can be disregarded. The main assetclass must then be entered as 100%.\n\nOtherwise, the respective assetclasses and their percentage share of the area must be entered."])},
    "constructionYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction year / Date of construction completion"])},
    "constructionYear-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the construction year."])},
    "renovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation year"])},
    "renovationYear-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the year of the most recent renovation. For general or core renovations, the questionnaire \"New construction/project development\" must be applied"])},
    "featureQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature quality"])},
    "featureQuality-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the equipment quality of the building as defined by NEO?"])},
    "assetTotalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset total area"])},
    "assetTotalArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate the gross floor area in m²."])},
    "assetUsableArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset usable area"])},
    "assetUsableArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate the NUF according to  DIN 277 in m².\n\n(Info: The usable area (NUF) according to DIN 277, until 2016 usable area (NF), of a building is the portion of the floor area that is used according to the purpose of the building. The usable area does not include circulation areas (VF) such as entrance areas, stairwells, elevators and corridors, technical areas (TF) (boiler room, machine rooms, technical operating rooms) and, of course, not the basic construction areas (KGF) of the building such as walls and columns)."])},
    "numTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of tenants"])},
    "numTenants-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many tenants are in the property? "])},
    "historicProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical protection"])},
    "historicProtection-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the building a listed building, i.e. a building protected under state law or a building unit protected under state law?"])},
    "questionnaireName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire name"])},
    "tester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "solutionPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution partner"])},
    "testAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test account"])},
    "financePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance partner"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
    "ipms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPMS2"])},
    "ipms2-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERNATIONAL PROPERTY MEASUREMENT STANDARDS\nArea of a building up to the relevant inner room boundary, subdivided\ninto components"])},
    "scoreType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score type"])},
    "conditionsAndEnvironmentGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition & Environmental goal (Taxonomy)"])},
    "conditionsAndEnvironmentGoals-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development: until completion/ handover/ commissioning(currently only possible for the asset classes office/ hotel/ logistics/ retail (high street))\nNew construction: Existing buildings completed less than 3 years ago.\nRenovation: The ener. Refurbishment, modernization, or revitalization may not be more than 3 years old."])},
    "conditionedArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditioned area"])},
    "conditionedArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please state the conditioned (heated) area in m2. This is also used in the energy performance certificate. (EPC rating)"])},
    "projectCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project characteristics"])},
    "determinationStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "determinationStartDate-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determination of start date: LP 1 (basic evaluation)Purchase (land purchase (closing, transfer of benefits/burdens))"])},
    "acceptanceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of acceptance"])},
    "handoverDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handover date"])},
    "handoverDate-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For tenants: to all tenants / investor, etc."])},
    "projectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project status(HOAI)"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["percentage"])}
  },
  "error": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured upon your request"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out this field"])},
    "requiredRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose atleast one user role"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No valid mail address"])},
    "identicalPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords are not identical"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a number"])},
    "minLengthArray": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please add atleast ", _interpolate(_named("min")), " options."])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your input"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No valid username"])},
    "minLengthString": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field should be at least ", _interpolate(_named("minNumber")), " characters long"])},
    "passwordProperFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password should consist of at least one letter and one number"])}
  },
  "table": {
    "resultsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results per page"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results available"])},
    "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured upon loading."])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "plsLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login here"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "badCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail or password are not correct."])},
    "forgotPassword-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "forgotPassword-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a known username and we will send you a password reset email."])},
    "forgotPassword-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with a link to reset the password has been sent to the address you provided. "])},
    "setPassword-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set password"])},
    "setPassword-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Set password for your account ", _interpolate(_named("userName"))])},
    "setPassword-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password should have at least 8 characters and contain letters and numbers"])},
    "setPassword-finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Password"])},
    "setPassword-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password successfully set"])},
    "setPassword-toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To login"])},
    "checkYourEmailBox2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with a link to complete the two-factor authentication process has been sent to the address you provided."])},
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to login page"])},
    "invalidTokenOrEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link is not correct. It may have expired."])}
  },
  "accountManagement": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are account manager at ECORE, here you can manage users and organisations"])},
    "userAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can manage all user accounts of your organisation"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])},
    "createOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create organisation"])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search account..."])},
    "searchOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seach organisation..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "users-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "users-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name, First Name"])},
    "users-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "users-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "users-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "users-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "users-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])},
    "users-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to activate the account of ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), "? Exisiting information of this account are not affected."])},
    "users-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you activate this account, the user will be able to use the ECORE Portal."])},
    "users-activate-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The account ", _interpolate(_named("firstName")), " ", _interpolate(_named("LastName")), " will be informed via mail about the activation."])},
    "users-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "users-deactivate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate account"])},
    "users-deactivate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to deactivate the account ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), "?"])},
    "users-deactivate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing information of the user are not afffected by this action. If you deactivate the user, he is not longer be able to login to the ECORE portal."])},
    "users-deactivate-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The account ", _interpolate(_named("firstName")), " ", _interpolate(_named("LastName")), " will be informed via mail about the deactivation."])},
    "users-deactivate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "users-archive-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "users-archive-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really would like to delete the account ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), "?"])},
    "users-archive-description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All information of the account will be deleted. The account ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " will be informed via mail about deletion."])},
    "users-archive-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone"])},
    "users-archive-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "users-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new user"])},
    "users-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user information"])},
    "users-edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles"])},
    "users-edit-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "users-edit-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "users-edit-access-objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access per object group"])},
    "users-edit-access-objectGroup-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not make any selection for access to all object groups."])},
    "users-edit-access-object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access per object"])},
    "users-edit-access-object-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not make any selection for access to all objects."])},
    "users-edit-externalRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External"])},
    "users-edit-mainContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main contact person"])},
    "organisations-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "organisations-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "organisations-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation ID"])},
    "organisations-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "organisations-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "organisations-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "organisations-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new organisation"])},
    "organisations-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit organisation information"])},
    "organisations-edit-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation account"])},
    "organisations-edit-differingBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different billing address"])},
    "organisations-edit-billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "organisations-edit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "organisations-editContacts-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit contact"])},
    "organisations-editContacts-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "organisations-editContacts-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new"])},
    "organisations-editContacts-newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New contact"])},
    "organisations-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate organisation account"])},
    "organisations-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to activate the account of ", _interpolate(_named("organisation")), "? Information of the organisation are not affected."])},
    "organisations-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you activate this account, the organisation can be used in ECORE portal."])},
    "organisations-activate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contact will be informed about the activation via mail."])},
    "organisations-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "organisations-deactivate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactive organisation"])},
    "organisations-deactivate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to deactivate ", _interpolate(_named("organisation")), " account?"])},
    "organisations-deactivate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contact will be informed about the deactivation via mail."])},
    "organisations-deactivate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "organisations-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete organisation"])},
    "organisations-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to delete the organisation ", _interpolate(_named("name")), "?"])},
    "organisations-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone"])},
    "organisations-delete-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "solutionPartner-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SP"])},
    "organisations-edit-select-finance-partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be kind to select associated finance partner organisation"])},
    "financePartner-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FP"])},
    "isBuyer-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
    "isTestAccount-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])}
  },
  "assetManagement": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can manage all objects and object groups"])},
    "jobManagement-fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page you can see all submitted quesionnaires."])},
    "createObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create object"])},
    "createObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create object group"])},
    "searchObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search object ..."])},
    "searchObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search object group..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upload documents"])},
    "definitelyDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely declined"])},
    "objects-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
    "objects-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object name"])},
    "objects-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object ID"])},
    "objects-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "objects-createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "objects-createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
    "objects-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "objects-builtYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction year"])},
    "objects-renovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation year"])},
    "objects-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "objects-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "objects-status-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "objects-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "objects-status-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "objects-status-evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "objects-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "objects-status-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
    "objects-status-verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
    "objects-status-definitelyDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitely declined"])},
    "objects-checkedStatus-checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked"])},
    "objects-checkedStatus-unchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "objects-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Review"])},
    "objects-unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "objects-testerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "objects-objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group"])},
    "objects-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new object"])},
    "objects-edit-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object data"])},
    "objects-edit-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["details"])},
    "objects-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit object"])},
    "objects-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects successfully updated"])},
    "objects-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete object"])},
    "objects-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really would like to delete the object ", _interpolate(_named("object")), "?"])},
    "objects-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information of the object will be deleted."])},
    "objects-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone."])},
    "objects-featureQuality-low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "objects-featureQuality-mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
    "objects-featureQuality-high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
    "objects-historicProtection-noStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No statement"])},
    "objects-historicProtection-selectedAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only selected areas"])},
    "objects-historicProtection-facade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only facade"])},
    "objects-historicProtection-facadeAndSelectedAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facade and selected areas"])},
    "objects-historicProtection-none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "objectGroups-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group"])},
    "objectGroups-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "objectGroups-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group ID"])},
    "objectGroups-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "objectGroups-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "objectGroups-testerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "objectGroups-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "objectGroups-objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objects"])},
    "objectGroups-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new object group"])},
    "objectGroups-create-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object group"])},
    "objectGroups-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit object group"])},
    "objectGroups-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete object group"])},
    "objectGroups-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really would like to delete the object group ", _interpolate(_named("group")), "?"])},
    "objectGroups-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information of the object group will be deleted."])},
    "objectGroups-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone."])},
    "objectGroups-submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for review"])},
    "object-assignTester-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Reviewer"])},
    "objectgroups-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "objectgroups-status-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "objectgroups-status-reviewfailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Failed"])},
    "objectgroups-status-reviewpassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Passed"])},
    "objectgroups-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "object-assignTester-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which reviewer would you like to assign to this questionnaire?"])},
    "objectGroup-assignTester-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign Reviewer"])},
    "objectGroup-assignTester-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which reviewer would you like to assign to this object group?"])},
    "resultOverview-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "resultDetail-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "startQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start new questionnaire"])},
    "questionnaire-start-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start new questionnaire"])},
    "questionnaire-start-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to start a new questionnaire for object '", _interpolate(_named("objectName")), "'?"])},
    "questionnaire-lastChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last change on ", _interpolate(_named("date")), " by ", _interpolate(_named("user")), "."])},
    "access-request-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Request"])},
    "access-request-infotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the object already exists in the ECORE tool, you can enter the object ID of the desired object here to send an access request to the associated organisation. The object ID can be obtained by contacting the organisation directly."])},
    "access-request-objectid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object ID"])},
    "access-request-request-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request now"])},
    "access-request-language-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The language in which the object owners will be notified."])},
    "approve-access-request-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You accepted the access request from the organisation ", _interpolate(_named("organisationName")), " for the object ", _interpolate(_named("objectName")), "."])},
    "decline-access-request-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You declined the access request from the organisation ", _interpolate(_named("organisationName")), " for the object ", _interpolate(_named("objectName")), "."])},
    "approve-access-request-warning-already-answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request to access this object was already answered."])},
    "approve-access-request-error-invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceptance of the access to this object failed. The token in the link is invalid. "])},
    "approve-access-request-error-invalid-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceptance of the access to this object failed. The request could not be identified."])},
    "approve-access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceptance of the access to this object failed."])},
    "decline-access-request-warning-already-answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request to access this object was already answered."])},
    "decline-access-request-error-invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rejection the access to this object failed. The token in the link is invalid. "])},
    "decline-access-request-error-invalid-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rejection of the access to this object failed. The request could not be identified."])},
    "decline-access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rejection of the access to this object failed."])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
    "objects-status-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
    "objects-CEG-StockAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock & Climate protection"])},
    "objects-CEG-StockAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock & Climate change adaption"])},
    "objects-CEG-RenovationAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Climate protection"])},
    "objects-CEG-RenovationAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Climate change adaption"])},
    "objects-CEG-NewConstructionAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Climate protection"])},
    "objects-CEG-NewConstructionAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Climate change adaption"])},
    "objects-CEG-ProjectDevelopmentAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Climate protection"])},
    "objects-CEG-ProjectDevelopmentAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Climate change adaption"])},
    "objects-CEG-NewConstructionAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Circular economy"])},
    "objects-CEG-ProjectDevelopmentAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Circular economy"])},
    "objects-CEG-RenovationAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Circular economy"])},
    "objects-PC-GreenfieldDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenfield development (new construction)"])},
    "objects-PC-DemolitionAndNewConstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["demolition and new construction (urban environment (already def. building land) (new construction/ project development)"])},
    "objects-PC-BrownfieldDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brownfield development (contaminated land, brownfield) (new construction/ project development)"])},
    "objects-PC-Redensification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redensification(e.g.addition of new storeys, land consolidation) (renovation)"])},
    "objects-PC-RedevelopmentOfExistingBuildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redevelopment of existing buildings (renovation)"])},
    "objects-PS-BasicEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP1: Basic evaluation"])},
    "objects-PS-PreliminaryPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP2: Preliminaryplanning"])},
    "objects-PS-DesignPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP3: Design planning"])},
    "objects-PS-ApprovalPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP4: Approvalplanning"])},
    "objects-PS-ImplementationPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP5: implementationplanning"])},
    "objects-PS-PreparationForAwardOfContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP6: Preparationfor awardofcontract"])},
    "objects-PS-ParticipationInAwardingTheContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP7: participationin awardingthecontract"])},
    "objects-PS-ObjectSupervisionConstructionSupervisionAndDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP8: objectsupervision-constructionsupervisionand documentation"])},
    "objects-PS-ObjectSupervision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP9: objectsupervision"])},
    "objectGroups-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Score"])},
    "objectGroups-show-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Score"])}
  },
  "score": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can manage questionnaires"])},
    "createQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create questionnaire"])},
    "questionnaires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "questionnairesActive-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active questionnaires"])},
    "questionnairesArchived-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous questionnaires"])},
    "questionnaires-version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version ", _interpolate(_named("version"))])},
    "questionnaires-lastChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last change at ", _interpolate(_named("date")), " ", _interpolate(_named("time"))])},
    "questionnaires-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "questionnaires-inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "questionnaires-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "questionnaires-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
    "questionnaire-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new questionnaire"])},
    "questionnaire-create-questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "questionnaire-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete questionnaire"])},
    "questionnaire-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you really like to delete the questionnaire ", _interpolate(_named("title")), "?"])},
    "questionnaire-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone"])},
    "questionnaire-save-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save all"])},
    "questionnaire-save-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to save all changes to the questionnaire?"])},
    "questionnaire-save-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone"])},
    "questionnaire-duplicate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy questionnaire"])},
    "questionnaire-duplicate-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you really like to copy the questionnaire ", _interpolate(_named("title")), "?"])},
    "questionnaire-leave-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel editing"])},
    "questionnaire-leave-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire contains unsaved changes."])},
    "questionnaire-leave-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to leave this page? All changes will be lost."])},
    "questionnaire-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish questionnaire"])},
    "questionnaire-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to publish questionnaire ", _interpolate(_named("title")), "?"])},
    "questionnaire-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any new questionnaires to be filled out for an object will subsequently be based on this version. "])},
    "questionnaire-activate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The currently public questionnaire will be archived."])},
    "questionnaire-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])},
    "questionnaire-locked-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire locked"])},
    "questionnaire-locked-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The questionnaire is locked by ", _interpolate(_named("name")), ". The questionnaire will be opened in Read mode."])},
    "cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster"])},
    "cluster-name-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER I Governance (fund / company level)"])},
    "cluster-name-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER II Consumption & Emissions (quantitative)"])},
    "cluster-name-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER III Asset Check (qualitative)"])},
    "cluster-name-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER IV Asset Taxonomy Check"])},
    "cluster-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This cluster doesn't have any entries yet"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "category-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Category"])},
    "category-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the category"])},
    "category-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete category"])},
    "category-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you really like to delete the category ", _interpolate(_named("category")), "?"])},
    "category-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sections and questions within this category will also be deleted."])},
    "category-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is temporary for your session. To persist the deletion you have to save the draft."])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section"])},
    "section-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create section"])},
    "section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the section"])},
    "section-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete section"])},
    "section-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you really delete the section ", _interpolate(_named("section")), "?"])},
    "section-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All questions within this section will also be deleted."])},
    "section-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is temporary for your ession. To persist the deletion you have to save the draft."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "question-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "question-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
    "question-initialTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial title"])},
    "question-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "question-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create question"])},
    "question-basicQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "question-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "question-questionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "question-singleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single-Choice"])},
    "question-multipleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple-Choice"])},
    "question-singleChoice-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select one option."])},
    "question-multipleChoice-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can select multiple options."])},
    "question-valueQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
    "question-virtualQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual question"])},
    "question-virtualQuestionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual question type"])},
    "question-virtualQuestionType-dw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deviation from average"])},
    "question-virtualQuestionType-dw-above": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " above AVG"])},
    "question-virtualQuestionType-dw-between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " of the AVG"])},
    "question-virtualQuestionType-dw-below": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " below AVG"])},
    "question-virtualQuestionType-dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deviation from DK-path"])},
    "question-virtualQuestionType-dk-deviation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " deviation from DK-path"])},
    "question-virtualQuestionType-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure from DK-path"])},
    "question-virtualQuestionType-year-departure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Departure from DK-path in ", _interpolate(_named("numberOfYears")), " years (", _interpolate(_named("year")), ")"])},
    "question-virtualQuestionType-all-noStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No statement"])},
    "question-virtualQuestionType-crremNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRREM currently not available for objects for the selected country"])},
    "question-averageValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average value"])},
    "question-correspondingQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related question"])},
    "question-isGreenhouseGas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2 emissions"])},
    "question-option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Option ", _interpolate(_named("index"))])},
    "question-addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option"])},
    "question-optionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "question-optionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "question-exclusiveOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive option"])},
    "question-taxonomyRelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomy relevant"])},
    "question-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete question"])},
    "question-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really would like to delete the question ", _interpolate(_named("question")), "?"])},
    "question-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is temporary for your session. To persist the deletion you have to save the draft."])},
    "metadata-allClusters-header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Overview (Cluster ", _interpolate(_named("start")), " to ", _interpolate(_named("end")), ")"])},
    "metadata-allClusters-clusterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster name"])},
    "metadata-allClusters-maxScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max Score IS-Status"])},
    "metadata-allClusters-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight overall"])},
    "metadata-allClusters-maxWeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum weighted score, per cluster"])},
    "metadata-allClusters-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achieved weighted score, per cluster"])},
    "metadata-cluster-ownWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight: ", _interpolate(_named("weight")), "%"])},
    "metadata-cluster-scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scope"])},
    "metadata-cluster-weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weight in cluster ", _interpolate(_named("numeral"))])},
    "metadata-cluster-weightOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight overall"])},
    "metadata-cluster-maxScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max. score in cluster ", _interpolate(_named("numeral"))])},
    "metadata-cluster-maxWeightedScoreOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. weighted score total"])},
    "metadata-cluster-reachedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached weighted score"])},
    "metadata-cluster-reachedUnweightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached score"])},
    "metadata-cluster-totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "metadata-ecore-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Score"])},
    "metadata-taxonomy-compliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomy compliance"])},
    "metadata-taxonomy-compliant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Conform(", _interpolate(_named("taxonomyScore")), "%)"])},
    "metadata-taxonomy-nonCompliant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non conform (", _interpolate(_named("taxonomyScore")), "%)"])},
    "metadata-chartHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "metadata-chartPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "record-reachedScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reached Score: ", _interpolate(_named("score"))])},
    "record-startAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for review"])},
    "record-valueQuestionTextInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add answer"])},
    "record-toAudit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send questionnaire to review"])},
    "record-toAudit-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want submit the questionnaire for asset ", _interpolate(_named("name")), " for review?"])},
    "record-toAudit-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, you will not be able to change any answers."])},
    "record-toAudit-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verifier responsible will check your answers."])},
    "record-toAudit-description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have submitted this single object for review, you can no longer submit the object group to which this object belongs as a group for review."])},
    "group-toAudit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send object group to review"])},
    "group-toAudit-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to submit the object group ", _interpolate(_named("name")), " for review?"])},
    "group-toAudit-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once you have submitted the questionnaires for review, you will no longer be able to make any changes to them - only adjustments within the sampling inspection will be possible (your ECORE Verifier will inform you of this if necessary)."])},
    "group-toAudit-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If necessary, the information you have provided can be adjusted a maximum of two times per sheet that is going to be reviewed."])},
    "group-toAudit-checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I hereby confirm that all objects/assets that were part of this object group on the cut-off date (31.12.2021) have been recorded in this object group and that a questionnaire has been completed for each object/asset.\n\nI am aware that incorrect or missing objects/assets in the object group will lead to the loss of the ECORE label.\n\n"])},
    "record-toEvaluation-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send questionnaire to evaluation"])},
    "record-toEvaluation-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want submit the questionnaire for the asset to evaluation?"])},
    "record-toEvaluation-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, you will not be able to change any answers."])},
    "record-toEvaluation-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The evaluator responsible will check your answers."])},
    "record-toClosed-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve questionnaire"])},
    "record-toClosed-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want to approve the questionnaire for the asset?"])},
    "record-toClosed-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, you will not be able to change any answers."])},
    "record-toClosed-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the last step of the evaluation process."])},
    "record-toDeclined-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send questionnaire to evaluation"])},
    "record-toDeclined-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you really want decline the questionnaire for the asset?"])},
    "record-toDeclined-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afterwards, you will not be able to change any answers."])},
    "record-toDeclined-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The responsible person will check your answers."])},
    "record-toDeclined-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "record-toVerification-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send questionnaire to upload documents"])},
    "record-toVerification-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to request the document upload for the questions you selected?"])},
    "record-toVerification-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire will not be editable by you in the meantime."])},
    "record-toVerification-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire will be editable again once the customer has uploaded all the requested documents."])},
    "record-toVerification-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for documents upload"])},
    "record-decline-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "record-toAudit-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for review"])},
    "record-toEvaluation-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for evaluation"])},
    "record-toClosed-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "record-saveAnswersForGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save answers to asset group"])},
    "record-saveAnswersForGroup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save answers to asset group"])},
    "record-saveAnswersForGroup-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to provide all answers of Cluster I for the assets in group ", _interpolate(_named("group")), "?"])},
    "record-saveAnswersForGroup-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subsequently, the object data collector will be able to apply these answers to assets of this group."])},
    "record-saveAnswersForGroup-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This only affects answers for questionnaire ", _interpolate(_named("questionnaire")), "."])},
    "record-saveAnswersForGroup-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "record-takeAnswersFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply answers from asset group"])},
    "record-takeAnswersFromGroup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply answers from asset group"])},
    "record-takeAnswersFromGroup-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to take all answers for Cluster I from its group ", _interpolate(_named("group")), "?"])},
    "record-takeAnswersFromGroup-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already existing answers for Cluster I in this asset will be overwritten. This cannot be undone."])},
    "record-takeAnswersFromGroup-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The answers will be taken from questionnaire ", _interpolate(_named("questionnaire")), "."])},
    "record-takeAnswersFromGroup-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "review-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "review-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
    "review-inReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In review"])},
    "review-verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
    "review-allComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All comments"])},
    "review-addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
    "review-timeStamp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " ", _interpolate(_named("time"))])},
    "entity-notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not have saved the draft and reloaded the browser window.<br><br>You can use the \"Add\" button at the top to append<br> • a new category<br> • a new section<br> • or a new question to the questionnaire."])},
    "export-dialog_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select language"])},
    "export-dialog_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the language for the export."])},
    "export-dialog_description_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the file format of the export."])},
    "export-pdf-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print preview"])},
    "export-pdf-unevaluatedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unevaluated score"])},
    "export-pdf-docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EcoreScore_unevaluated"])},
    "questionnaire_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show questionnaire"])},
    "questionnaire-update-to-active-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update questionnaire"])},
    "questionnaire-update-to-active-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you really like to update the questionnaire?"])},
    "questionnaire-update-to-active-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions could be changed, added or deleted."])},
    "questionnaire-update-to-active-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This step cannot be undone."])},
    "questionnaire-update-notification-objectadministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There’s a new questionnaire version available. Please update the questionnaire."])},
    "questionnaire-update-notification-objectdatacollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There’s a new questionnaire version available. Please contact the object administrator to update it."])},
    "questionnaire-become-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become an ECORE member to see the unevaluated score of your object."])},
    "questionnaire-update-notification-objectgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There’s a new questionnaire version available for this object group. Please update the questionnaire."])},
    "question-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change logs"])},
    "question-changelog-first-answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " answered the question ", _interpolate(_named("questionname")), " initially on ", _interpolate(_named("date")), "."])},
    "question-changelog-last-modified": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " has edited the answer of the question ", _interpolate(_named("questionname")), " on ", _interpolate(_named("date")), "."])},
    "question-changelog-questionnaire-updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " updated the questionnaire to version ", _interpolate(_named("templatename")), " on ", _interpolate(_named("date")), "."])},
    "question-changelog-removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " has removed the answer of the question ", _interpolate(_named("questionname")), " on ", _interpolate(_named("date")), "."])},
    "question-addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "question-addfile-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add attachments via “Upload files”. The files are uploaded immediately, you do not need to confirm them with \"Save\".\nAllowed formats of the attachment: PDF and JPG\nMaximum size of the attachment: 10 MB"])},
    "question-addfile-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload files"])},
    "question-select-score-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score type"])},
    "question-select-score-type-option-Ecore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecore"])},
    "question-select-score-type-option-Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "submit-for-evaluation-group-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send questionnaire to evaluation"])},
    "submit-for-evaluation-group-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you submit your asset group to evaluation, you will be not able to change any review. The evaluator responsible will check your reviews."])},
    "submit-for-evaluation-group-modal-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Evaluation"])},
    "evaluate-group-accept-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept review"])},
    "evaluate-group-accept-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you really like to accept the review of the questionnaire?"])},
    "evaluate-group-decline-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline review"])},
    "evaluate-group-decline-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you really like to decline the review of the questionnaire?"])},
    "cluster-identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster identifier"])},
    "cluster-identifier-with-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cluster ", _interpolate(_named("number"))])},
    "finance-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance-Score"])},
    "metadata-cluster-reachedWeightedFinanceScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached weighted finance score"])},
    "metadata-cluster-maxWeightedFinanceScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. weighted finance score total"])},
    "metadata-allClusters-finance-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance weight overall"])},
    "metadata-cluster-finance-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance weight in cluster"])},
    "metadata-cluster-finance-weightOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance weight overall"])},
    "metadata-cluster-maxFinanceWeightedScoreOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. finance weighted score total"])},
    "metadata-allClusters-maxFinanceWeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum finance weighted score, per cluster"])},
    "questionnaire-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire version"])},
    "scoretype-ecore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE"])},
    "scoretype-finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["financial"])},
    "scoretype-stockAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock & Climate protection"])},
    "scoretype-stockAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock & Climate change adaption"])},
    "scoretype-renovationAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Climate protection"])},
    "scoretype-renovationAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Climate change adaption"])},
    "scoretype-newConstructionAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Climate protection"])},
    "scoretype-newConstructionAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Climate change adaption"])},
    "scoretype-projectDevelopmentAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Climate protection"])},
    "scoretype-projectDevelopmentAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Climate change adaption"])},
    "scoretype-newConstructionAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New construction & Circular economy"])},
    "scoretype-projectDevelopmentAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project development & Circular economy"])},
    "scoretype-renovationAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovation & Circular economy"])},
    "question-relevantForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review relevant"])}
  },
  "home": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome\nHere you can get to know & use the most important functions of the ECORE online tool"])},
    "staticHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your options when using the ECORE online tool"])},
    "staticDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all the accesses that you can use as part of your role. You can check your role at the top right."])},
    "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Organisation"])},
    "createOrganizationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers/organisations with the associated company data can be created and edited here"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create user"])},
    "createUserText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here employees/external contact persons with the corresponding data can be created and edited"])},
    "createObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create object"])},
    "createObjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the building corner data for an asset/objects for which a questionnaire is to be filled in can be entered and edited"])},
    "createObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create object group"])},
    "createObjectGroupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here portfolios/funds can be created to which the assets/objects for which a questionnaire is filled in belong."])},
    "answerQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer questionnaire"])},
    "answerQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the current questionnaire for an asset/objects can be selected and answered"])},
    "editQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit questionnaire"])},
    "editQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the current questionnaire can be copied and edited; new versions can be released"])},
    "reviewQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review questionnaire"])},
    "reviewQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here the submitted questionnaires can be checked."])},
    "news-header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 22.04.2024"])},
    "news-text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear ECORE members, as of today the questionnaire 2024_01 is available! No data will be lost when updating from 2023_03 to 2024_01!\n\nIn addition, the asset class residential is now available for project developments! The finance score for project developments (office / hotel / logistics / retail (high street) and residential) is now also available for Finance Partners!\n\nWe also present the Mixed-Use feature: complex buildings (several asset classes) can now be recorded in one sheet - simply select the split via the building corner data!\n\nThe last innovation in this update: new taxonomy catalogs are available (climate target circular economy for refurbishment and project development/new construction)!\n\nAll new catalogs can be found in the members area!"])},
    "news-header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE News 21.08.2023"])},
    "news-text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear ECORE members, today we provide you with the update for the ambition levels!\n\nIn the new question catalogue 2023_03 you will find the labels A1-A3 behind the respective numbering of a question (applies to stock buildings, new buildings & refurbishment - not project development).\n\nOnly questions with the label A1 are relevant for the calculation of the ECORE score in 2023. However, all other questions can still be viewed and edited."])},
    "news-header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 07.08.2023"])},
    "news-text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear ECORE members, today we present you the update for the project developers!\n\nThe project development catalogues can now be filled in for the asset classes office/ hotel/ logistics & retail (high street).\n\nIn addition, the taxonomy questions for stock properties and project developments are in cluster IV with the update.\n\nProperties that have already been created in ECORE are set up as \"Stock & Climate Protection\"."])},
    "news-header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 07.06.2023"])},
    "news-text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear ECORE members,\n\nToday we are pleased to provide you with the update for the new 2023 catalogue!\n\nThanks to the active support of the FA Update, we were able to further optimise and simplify the questionnaire.\nIn the members' area you will find the new catalogue with a markup to see which questions have to be answered anew after the update.\nThe tool archives your old questionnaire so that you can view it again at any time and export it if necessary.\n\nWith the next update we will provide you with Cluster IV - Taxonomy Check!"])}
  },
  "certificate": {
    "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Label"])}
  },
  "notifications": {
    "organisations-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client has been successfully unblocked."])},
    "organisations-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to unblock the client. Please try again later."])},
    "organisations-deactivate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client has been successfully blocked."])},
    "organisations-deactivate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to block the client. Please try again later."])},
    "organisations-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client has been successfully deleted."])},
    "organisations-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to delete the client. Please try again later."])},
    "organisations-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client has been successfully created."])},
    "organisations-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the client. Please try again later."])},
    "organisations-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client has been successfully updated."])},
    "organisations-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the client. Please try again later."])},
    "organisations-editContacts-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contact has been successfully updated."])},
    "organisations-editContacts-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the contact. Please try again later."])},
    "organisations-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the clients. Please try again later."])},
    "users-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been successfully unblocked."])},
    "users-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to unblock the account. Please try again later."])},
    "users-deactivate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been successfully blocked."])},
    "users-deactivate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to block the account. Please try again later."])},
    "users-archive-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been successfully deleted."])},
    "users-archive-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to delete the account. Please try again later."])},
    "users-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been successfully created."])},
    "users-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the account. Please try again later."])},
    "users-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account has been successfully updated."])},
    "users-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the account. Please try again later."])},
    "users-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the accounts. Please try again later."])},
    "questionnaire-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully created."])},
    "questionnaire-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the questionnaire. Please try again later."])},
    "questionnaire-create-onlyOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the questionnaire. There can only be one draft."])},
    "questionnaire-clone-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully cloned."])},
    "questionnaire-clone-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to clone the questionnaire. Please try again later."])},
    "questionnaire-clone-onlyOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to clone the questionnaire. There can only be one draft."])},
    "questionnaire-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully deleted."])},
    "questionnaire-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to delete the questionnaire. Please try again later."])},
    "questionnaire-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the questionnaire. Please try again later."])},
    "questionnaire-score-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the questionnaires score. Please try again later."])},
    "questionnaire-certificate-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the questionnaires certificate. Please try again later."])},
    "questionnaire-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the questionnaire. Please try again later."])},
    "questionnaire-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully updated."])},
    "questionnaire-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the questionnaire. Please try again later."])},
    "questionnaire-category-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category has been successfully updated."])},
    "questionnaire-category-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the category. Please try again later."])},
    "questionnaire-category-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category has been marked for deletion. Changes take effect when the draft is saved."])},
    "questionnaire-category-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category could not be found."])},
    "questionnaire-section-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The section has been successfully updated."])},
    "questionnaire-section-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the section. Please try again later."])},
    "questionnaire-section-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The section has been marked for deletion. Changes take effect when the draft is saved."])},
    "questionnaire-section-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The section could not be found."])},
    "questionnaire-question-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The question has been successfully updated."])},
    "questionnaire-question-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the question. Please try again later."])},
    "questionnaire-question-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The question has been marked for deletion. Changes take effect when the draft is saved."])},
    "questionnaire-question-clone-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The question has been cloned. Changes take effect when the draft is saved."])},
    "questionnaire-question-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The question could not be found."])},
    "questionnaire-question-answer-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answer has been successfully updated."])},
    "questionnaire-question-answer-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the answer. Please try again later."])},
    "questionnaire-question-answerAll-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your answers have been successfully updated."])},
    "questionnaire-question-answerAll-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update your answers. Please try again later."])},
    "questionnaire-question-reviewSaveAll-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reviews have been successfully updated."])},
    "questionnaire-question-reviewSaveAll-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update your review. Please try again later."])},
    "questionnaire-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully activated."])},
    "questionnaire-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to activate the questionnaire. Please try again later."])},
    "questionnaire-activate-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your questionnaire contains invalid input, cannot activate."])},
    "questionnaire-start-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new questionnaire has been successfully created."])},
    "questionnaire-start-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create a new questionnaire. Please try again later."])},
    "questionnaire-toAudit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully submitted for review."])},
    "questionnaire-toAudit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for review. Please try again later."])},
    "questionnaire-toAudit-errorDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not answer every question."])},
    "questionnaire-toAudit-errorDocuments2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some answer needs to be verified but doesn't have any document."])},
    "questionnaire-change-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for review. Please try again later."])},
    "questionnaire-change-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for review. Please try again later."])},
    "questionnaire-change-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You did not answer every question."])},
    "questionnaire-change-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To submit the questionnaire to evaluation, exactly 16 questions have to be reviewed."])},
    "questionnaire-change-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To submit the questionnaire to review you need to update the answer of every declined question."])},
    "questionnaire-change-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To submit the questionnaire for document upload, please set the state of answers in the questionnaire to \"upload documents\""])},
    "questionnaire-change-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To decline a questionnaire, please set the state of at least one answer in the questionnaire to \"declined\"."])},
    "questionnaire-change-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To submit the questionnaire to evaluation every every reviewed answer needs to be accepted."])},
    "questionnaire-change-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To close the questionnaire you need to accept the answers where a document upload was requested by the Verifier."])},
    "questionnaire-change-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some answer needs to be verified but doesn't have any document."])},
    "questionnaire-change-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for review. Please try again later."])},
    "questionnaire-toEvaluation-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully submitted for evaluation."])},
    "questionnaire-toEvaluation-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for evaluation. Please try again later."])},
    "questionnaire-toClosed-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully approved."])},
    "questionnaire-toClosed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to approve the questionnaire. Please try again later."])},
    "questionnaire-toDeclined-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully declined."])},
    "questionnaire-toDeclined-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to decline the questionnaire. Please try again later."])},
    "questionnaire-toVerification-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questionnaire has been successfully submitted for verification."])},
    "questionnaire-toVerification-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the questionnaire for verification. Please try again later."])},
    "questionnaire-virtScoringOptions-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the options for virtual questions. Please try again later."])},
    "questionnaire-saveAnswersForGroup-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answers of Cluster I were successfully saved to the asset group."])},
    "questionnaire-saveAnswersForGroup-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to save the answers of Cluster I to the asset group. Please try again later."])},
    "questionnaire-takeAnswersFromGroup-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answers for Cluster I were successfully taken from the asset group."])},
    "questionnaire-takeAnswersFromGroup-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to take the answers of Cluster I from the asset group. Please try again later."])},
    "objects-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object has been successfully deleted."])},
    "objects-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to delete the object. Please try again later."])},
    "objects-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object has been successfully created."])},
    "objects-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the object. Please try again later."])},
    "objects-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object has been successfully updated."])},
    "objects-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the object. Please try again later."])},
    "objects-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the object. Please try again later."])},
    "objects-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the objects. Please try again later."])},
    "objects-assetClasses-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the asset classes. Please try again later."])},
    "objects-countries-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the available countries. Please try again later."])},
    "objects-startQuestionnaire-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to start the questionnaire. Please try again later."])},
    "objectGroups-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objectgroup has been successfully deleted."])},
    "objectGroups-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to delete the objectgroup. Please try again later."])},
    "objectGroups-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objectgroup has been successfully created."])},
    "objectGroups-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to create the objectgroup. Please try again later."])},
    "objectGroups-create-error-assettype-duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each asset class can only be added once."])},
    "objectGroups-create-error-percentages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The percentages must sum up to 100."])},
    "objectGroups-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The objectgroup has been successfully updated."])},
    "objectGroups-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to update the objectgroup. Please try again later."])},
    "objectGroups-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the objectgroups. Please try again later."])},
    "objectGroups-toAudit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object group has been successfully submitted for review."])},
    "objectGroups-toAudit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the object group for review. Please try again later."])},
    "objectGroups-toAudit-error-not-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLU 1 was not answered the same in every object of the object group. Please check that CLU1 has the same answers for every object of the object group."])},
    "objectGroups-change-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the object group for review. Please try again later."])},
    "objectGroups-change-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no access to this object group."])},
    "objectGroups-change-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one object in the object group has the wrong state to submit the object group."])},
    "objectGroups-change-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the object group for review. Please try again later."])},
    "objectGroups-change-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to review the object group. Please try again later."])},
    "objectGroups-change-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please answer the field \"asset total area\" for every object of the object group."])},
    "objectGroups-change-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLU 1 was not answered the same in every object of the object group. Please check that CLU 1 has the same answers for every object of the object group."])},
    "tasks-assignTester-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reviewer was successfully assigned to the asset."])},
    "tasks-assignTester-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to assign the reviewer. Please try again later."])},
    "tasks-tester-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to load the testers. Please try again later."])},
    "comments-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The comment was successfully published."])},
    "comments-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to save the comment. Please try again later."])},
    "questionnaire-update-to-active-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire update successful."])},
    "questionnaire-update-to-active-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire update failed."])},
    "questionnaire-update-to-active-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All active objects of the group must have the same questionnaire, which must not be the active questionnaire."])},
    "questionnaire-question-max-float-number-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value you have declared exceeds the maximum allowable amount. Please check the value and enter it again. If the problem persists, please contact support[at]ecore-rating.com"])},
    "fileUpload-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is successfully uploaded."])},
    "fileUpload-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was problem with file upload. Please try again."])},
    "fileDelete-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is successfully deleted."])},
    "fileDelete-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was problem with file delete. Please try again."])},
    "fileUpload-size-format-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Either the file size is too big or the format is wrong. Please try again."])},
    "submit-for-evaluation-group-modal-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The object group was successfully submitted to evaluation."])},
    "submit-for-evaluation-group-modal-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was not possible to submit the object group to evaluation."])},
    "evaluate-group-modal-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The evaluation of the object group was successful."])},
    "evaluate-group-modal-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The evaluation of the object group failed."])},
    "access-request-warning-access-already-granted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The access to this object was already granted."])},
    "access-request-error-buyer-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested object belongs to a buyer organisation. It is not possible to request access to a buyer organisation."])},
    "access-request-error-object-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested object in invalid."])},
    "access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The request to access this object failed."])},
    "access-request-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The access to this object was successfully requested."])}
  },
  "dashboard": {
    "assetdatasearch-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the building"])},
    "assetdatasearch-survey-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey year"])},
    "general-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can get to know the most important statistics"])},
    "asset-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset data"])},
    "show-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show data"])}
  },
  "export": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can export from all objects, object groups, and customer data"])},
    "export_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])}
  }
}