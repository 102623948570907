<template>
  <div>
    <div class="flex justify-between">
      <div class="flex px-11 py-7" v-if="filters.length > 0">
        <slot
          v-for="{ key, item } in filters"
          :key="key"
          :name="`filter:${key}`"
          :item="item"
        >
          <div
            class="text-[13px] font-medium cursor-pointer mr-4"
            @click="$emit('selected:filter', key)"
          >
            <span :class="item.active ? 'text-primary' : 'text-darkGray'">
              {{ item.label }}
            </span>
            <Pill
              class="text-white leading-snug bg-primary px-2 ml-2.5"
              :class="item.active ? 'bg-primary' : 'bg-darkGray'"
            >
              {{ item.number }}
            </Pill>
          </div>
        </slot>
      </div>
      <div v-else class="pt-4"></div>
      <div class="p-5">
        <slot name="exportBtn" v-if="showExportBtn">
          <Button
            variant="secondary"
            class="px-10"
            @click="$emit('open:openExportDialog')"
            >{{ $t('pages.export') }}</Button
          >
        </slot>
      </div>
    </div>

    <table
      class="w-full"
      :class="[isFixedLayout ? 'table-fixed' : 'table-auto']"
    >
      <thead
        class="text-left text-sm text-lightBlack bg-oliveGreen"
        :class="headerClass"
      >
        <tr class="border-t border-b border-[#e7e6e6]">
          <slot
            v-for="{ key, item, colspan } in header"
            :name="`header:${key}`"
            :item="item"
          >
            <th
              class="py-2 first:pl-11 last:pr-11"
              :colspan="colspan ? colspan : 1"
              :key="key"
            >
              {{ item }}
            </th>
          </slot>
        </tr>
      </thead>

      <tbody class="text-lightBlack">
        <template v-if="list.length > 0">
          <tr
            v-for="(item, idx) in list"
            :key="idx"
            :class="rowHeight"
            class="item-row"
          >
            <slot
              v-for="{ key } in header"
              :key="key"
              :name="`col:${key}`"
              :item="item"
            >
              <td class="first:pl-11 last:pr-11">
                {{ item }}
              </td>
            </slot>
          </tr>
          <tr v-for="idx in numPlaceholders" :key="idx" class="item-row"></tr>
        </template>

        <tr v-else>
          <td class="text-center font-medium py-16" :colspan="header.length">
            {{
              pending
                ? `${$t('general.loading')} ...`
                : error
                ? `${$t('table.loadError')}.`
                : `${$t('table.empty')}.`
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Pill from '@/components/Pill'
import Button from '../form/button/Button.vue'

export default {
  components: {
    Pill,
    Button,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pending: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
      validator: list => list.every(f => 'key' in f && 'item' in f),
    },
    header: {
      type: Array,
      default: () => [],
      validator: list => list.every(f => 'key' in f && 'item' in f),
    },
    isFixedLayout: {
      type: Boolean,
      default: true,
    },
    rowHeight: {
      type: String,
      default: 'h-16',
    },
    headerClass: {
      type: String,
    },
  },
  emits: ['selected:filter', 'open:openExportDialog'],
  computed: {
    numPlaceholders() {
      if (
        this.totalItems <= this.pageSize ||
        this.list.length === this.pageSize
      ) {
        return 0
      }
      return this.pageSize - (this.list.length % this.pageSize)
    },
    showExportBtn() {
      return this.$route.name === 'customerDataExport'
    },
  },
}
</script>

<style scoped>
.item-row {
  @apply border-lighterGray border-b last:border-b-0;
}
</style>
