<template>
  <Dialog v-model="isOpen">
    <template #title>
      {{ $t('score.category-delete-headline') }}
    </template>
    <template #description>
      <p>
        {{
          $t('score.category-delete-description1', {
            category: title,
          })
        }}
      </p>
      <p class="mt-8">
        {{ $t('score.category-delete-description2') }}
      </p>
      <p class="mt-8">
        {{ $t('score.category-delete-description3') }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button class="ml-[31px] w-[240px]" variant="primary" @click="confirm">
          {{ $t('general.delete') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from './Dialog'
import { useDialog } from '@/composition'
import { Button } from '@/components/form'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'delete'],
  setup(props, { emit }) {
    return {
      ...useDialog(props, emit),
    }
  },
  methods: {
    confirm() {
      this.$emit('delete')
      this.close()
    },
  },
}
</script>
