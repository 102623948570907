import { ref, Ref } from 'vue'

export interface IUseRequest<T> {
  request: (...args: unknown[]) => Promise<T | undefined>
  cancel?: () => void
  pending: Ref<boolean>
  error: Ref<boolean>
  success: Ref<boolean>
}

export default <T>(
  requestCb: (...args: unknown[]) => Promise<T>,
  cancelCb?: () => void
): IUseRequest<T> => {
  const pending = ref(false)
  const error = ref(false)
  const success = ref(false)

  const request = async (...args: unknown[]): Promise<T | undefined> => {
    if (pending.value) {
      return
    }

    pending.value = true

    try {
      const result = await requestCb(...args)
      success.value = true
      return result
    } catch (err) {
      error.value = true
      throw err
    } finally {
      pending.value = false
    }
  }

  return { request, cancel: cancelCb, pending, error, success }
}
