import type { IQuestionnaireInStore } from './types'

export default (): IQuestionnaireInStore => ({
  id: 0,
  title: '',
  state: null, // EQuestionnaireState,
  type: 0,
  assetQuestionnaireState: null, // EAssetState for asset questionnaires
  closedAt: '', // for asset questionnaires
  creationDate: '',
  taxonomyCompliance: false, // for asset questionnaires
  canSetGroupDefaultAnswers: false, // for asset questionnaires
  modifiedBy: null, // for asset questionnaires
  modifiedDate: null, // for asset questionnaires
  canBeSubmitted: false,
  canBeReviewed: false,
  canBeUpdatedToActiveQuestionnaire: false,
  modified: false, // just for store
  lockedStatus: null,
  clusters: [],
  activateQuestionnaire: null
})
