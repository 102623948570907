import axios, { Canceler, AxiosResponse } from 'axios'
import axiosClient from './http'
import {
  IObjectGroup,
  IObjectGroupCreate,
  IObjectGroupEdit,
  IObjectGroupInfo,
} from '@/models/objectGroup'
import { ICertificate } from '@/models/object'
import { EExportFormat } from '@/enums'
import httpAxiosClient from './http'

type Id = string | number

const CancelToken = axios.CancelToken
let cancelRequest: Canceler

export default {
  async getList(): Promise<Array<IObjectGroup>> {
    try {
      const res = await axiosClient.get('assetGroup', {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },
  async create(group: IObjectGroupCreate): Promise<IObjectGroup> {
    const res = await axiosClient.post('assetGroup', group)
    return res.data
  },
  async edit(group: IObjectGroupEdit): Promise<IObjectGroup> {
    const res = await axiosClient.put('assetGroup', group)
    return res.data
  },
  delete(id: string): Promise<void> {
    return axiosClient.delete(`assetGroup/${id}`)
  },
  updateToActiveQuestionnaire(assetGroupId: number): Promise<void> {
    return axiosClient.put(`assetGroup/${assetGroupId}/questionnaire`)
  },
  setAssetGroupState(assetGroupId: number, state: number): Promise<void> {
    return axiosClient.put(`assetGroup/${assetGroupId}/assetState/${state}`)
  },
  getAssetGroupsWithInfo(): Promise<IObjectGroupInfo> {
    return axiosClient.get(`assetGroup/assetGroupInfo`)
  },
  evaluate(assetGroupId: number, accept: boolean): Promise<void> {
    return axiosClient.put(
      `assetGroup/${assetGroupId}/evaluate/?decision=${accept}`
    )
  },
  async assignTester(assetId: number, testerId: number): Promise<void> {
    const params = new URLSearchParams({
      assetGroupId: `${assetId}`,
      testerId: `${testerId}`,
    })
    const res = await axiosClient.put(`assetGroup/tester?${params}`)
    return res.data
  },

  async getCertificate(assetGroupId: number): Promise<ICertificate> {
    const res = await axiosClient.get(`assetGroup/certificate`, {
      params: { id: assetGroupId },
    })
    return res.data
  },
  async getScore(assetGroupId: number): Promise<void> {
    const rest = await axiosClient.get(`AssetGroup/${assetGroupId}/score`)
    return rest.data
  },
  async exportObjectGroup(
    groupId: Id,
    assets: Id[],
    language: string,
    exportFormat: EExportFormat,
  ): Promise<void> {
    const link = document.createElement('a')
    const params = new URLSearchParams({
      exportFormat: `${exportFormat}`,
      language,
    });
    if (assets && assets.length)
      assets.forEach(asset => {
        params.append('Assets', asset.toString());
      });
    const exportUrl = `export/assetgroup/${groupId}?${params}`
    const EXPORT_FORMAT_TYPE_MAPPING = {
      0: 'application/json',
      1: 'text/csv',
      2: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const response: AxiosResponse<Blob> = await httpAxiosClient.get(exportUrl, {
      responseType: 'blob',
    })
    const type = EXPORT_FORMAT_TYPE_MAPPING[exportFormat]
    const blob = new Blob([response.data], { type })

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', '')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  cancelRequest(): void {
    cancelRequest()
  },
}
