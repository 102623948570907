import { pick, has, get, defaults, capitalize, clone } from 'lodash-es'
import { i18n } from '@/plugins'

type Obj = Record<string, unknown>

interface IStringizable {
  toString: (this: IStringizable) => string
}

export function deKebabCase(str: string): string {
  return str.split('-').map(capitalize).join(' ')
}

export function decapitalize(val: IStringizable): string {
  return val.toString().replace(/^\w/, c => c.toLowerCase())
}

export function defaultingPick(target: Obj, source: Obj): Obj {
  return pick(defaults(clone(target), source), Object.keys(source))
}

export function searchOverKeys(
  source: Array<Obj>,
  keys: Array<string>,
  search: string
): Array<Obj> {
  return source.filter((entry: Obj) =>
    keys.find(
      key =>
        has(entry, key) &&
        String(get(entry, key)).toLowerCase().includes(search)
    )
  )
}

export function formatDate(dateString: string): string {
  const date = Date.parse(dateString)
  return new Intl.DateTimeFormat(i18n.global.locale, {
    dateStyle: 'medium',
  }).format(date)
}

export function formatTime(dateString: string): string {
  const date = Date.parse(dateString)
  return new Intl.DateTimeFormat(i18n.global.locale, {
    timeStyle: 'short',
  }).format(date)
}

export function toFloat(str: string): number {
  return parseFloat(str.replace(',', '.'))
}

export function toFloatString(num: number): string {
  return String(num).replace('.', localeDecimalSign())
}


export function enumKeys(enu: Record<number, string>): Array<number> {
  return Object.keys(enu)
    .map(Number)
    .filter(key => !isNaN(key))
}

export function enumString(enu: Record<number, string>, key: number): string {
  return decapitalize(enu[key])
}

export function uses2fa() {
  return !!JSON.parse(process.env.VUE_APP_USE_2FA)
}

function localeDecimalSign() {
  return i18n.global.locale === 'de' ? ',' : '.'
}
export function numeral(original: number): string {
  if (original < 1 || original > 3999) {
    throw new Error('Error: Input integer limited to 1 through 3,999');
  }

  const numerals = [
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
    ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
    ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
    ['M', 'MM', 'MMM'], // 1000-3000
  ];

  // TODO: Could expand to support fractions, simply rounding for now
  const digits = Math.round(original).toString().split('');
  let position = (digits.length - 1);

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      roman += numerals[position][parseInt(digit) - 1];
    }

    position -= 1;

    return roman;
  }, '');
}

export function formatWithTimezoneOffset(dateString: string) {
  const date = new Date(dateString)
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

  const timezoneOffset = -date.getTimezoneOffset();
  const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
  const timezoneMinutes = (Math.abs(timezoneOffset) % 60).toString().padStart(2, '0');
  const timezoneSign = timezoneOffset < 0 ? '+' : '-';

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneHours}:${timezoneMinutes}`;
}