import axios, { AxiosResponse, Canceler } from 'axios'
import httpAxiosClient from './http'
import fileAxiosClient from './file'
import { omit } from 'lodash-es'
import {
  EAssetState,
  EVirtQuestType,
  EExportFormat,
  EAnswerChangeLogType,
} from '@/enums'
import type { IVirtQuestScoring } from '@/models/virtQuestScoring'
import type {
  IQuestionnaireListEntry,
  IQuestionnaireCreate,
  IQuestionnaireEdit,
  IQuestionnaire,
  IAssetQuestionnaire,
  IAssetQuestionnaireListEntry,
  IQuestScore,
  ILockedStatus,
  IAnswerChangeLogContainer,
} from '@/models/questionnaire'
import type {
  IAnswer,
  IAnswerCreate,
  IAnswerEdit,
  IComment,
  ICommentCreate,
  IAnswerDocument,
} from '@/models/question'

type Id = string | number

const CancelToken = axios.CancelToken
let cancelRequest: Canceler

export default {
  /*** TEMPLATES ***/

  async getList(): Promise<Array<IQuestionnaireListEntry>> {
    try {
      const res = await httpAxiosClient.get('questionnaire', {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },

  async getById(id: Id): Promise<IQuestionnaire> {
    const res = await httpAxiosClient.get(`questionnaire/${id}`)
    return res.data
  },

  async create(
    questionnaire: IQuestionnaireCreate,
    isFirstTemplate = false
  ): Promise<IQuestionnaireListEntry> {
    // we POST when there is no active template, otherwise PUT ...
    const reqMethod = isFirstTemplate ? 'post' : 'put'
    const res = await httpAxiosClient[reqMethod]('questionnaire', questionnaire)
    return res.data
  },

  async edit(questionnaire: IQuestionnaireEdit): Promise<IQuestionnaire> {
    const payload = {
      ...questionnaire,
      clusters: questionnaire.clusters.map(cluster => ({
        ...omit(cluster, ['maxScore']),
        categories: cluster.categories.map(category => ({
          ...omit(category, ['maxScore']),
          sections: category.sections.map(section => ({
            ...omit(section, ['maxScore']),
            questions: section.questions.map(question => ({
              ...omit(question, ['maxScore']),
            })),
          })),
        })),
      })),
    }
    const res = await httpAxiosClient.put('questionnaire', payload)
    return res.data
  },

  async getVirtQuestScoring(type: EVirtQuestType): Promise<IVirtQuestScoring> {
    const res = await httpAxiosClient.get('scoringtable', { params: { type } })
    return res.data
  },

  delete(id: Id): Promise<void> {
    return httpAxiosClient.delete(`questionnaire/${id}`)
  },

  /*** ANSWERING QUESTIONNAIRES ***/

  async getAssetQuestList(
    assetId: string,
    isBuyerAsset: boolean
  ): Promise<Array<IAssetQuestionnaireListEntry>> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/simplified`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async getAssetQuest(
    assetId: string,
    questionnaireId: string,
    isBuyerAsset: boolean
  ): Promise<IAssetQuestionnaire> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/${questionnaireId}`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async getAssetQuestLatest(assetId: string, isBuyerAsset: boolean): Promise<IAssetQuestionnaire> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/latest`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async startNewQuestionnaire(
    assetId: string,
    isFirstQuestionnaire = false,
    isBuyerAsset: boolean
  ): Promise<IAssetQuestionnaireListEntry> {
    const res = await httpAxiosClient.post(
      `asset/${assetId}/questionnaire`, {}, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async updateToActiveQuestionnaire(
    assetId: number,
    isBuyerAsset: boolean
  ): Promise<IAssetQuestionnaireListEntry> {
    const res = await httpAxiosClient.put(`asset/${assetId}/questionnaire`, {}, { params: { isBuyerAsset } })
    return res.data
  },

  async getAssetScores(assetId: string, isBuyerAsset: boolean): Promise<IQuestScore[] | null> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/score`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async getAssetScore(
    assetId: string,
    questionnaireId: string,
    isBuyerAsset: boolean
  ): Promise<IQuestScore | null> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/${questionnaireId}/score`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async answerQuestions(
    answers: Array<IAnswerCreate | IAnswerEdit>
  ): Promise<Array<IAnswer>> {
    const res = await httpAxiosClient.put('asset/answer', answers)
    return res.data
  },

  async postComment(comment: ICommentCreate): Promise<IComment> {
    const res = await httpAxiosClient.put('asset/answerComment', comment)
    return res.data
  },

  async postAnswerDocument(
    answerDocumentId: number,
    formData: FormData
  ): Promise<IAnswerDocument> {
    const res = await fileAxiosClient.put(
      `/asset/answer/${answerDocumentId}/document`,
      formData
    )
    return res.data
  },

  async deleteAnswerDocument(answerDocumentId: number): Promise<void> {
    return httpAxiosClient.delete(`/asset/answer/document/${answerDocumentId}`)
  },

  async setAssetState(assetId: string, assetState: EAssetState, isBuyerAsset: boolean): Promise<void> {
    const params = new URLSearchParams({
      assetId,
      state: `${assetState}`,
      isBuyerAsset: `${isBuyerAsset}`
    })
    await httpAxiosClient.put(`asset/state?${params}`)
  },

  deleteAssetQuest(assetId: Id, questionnaireId: Id, isBuyerAsset: boolean): Promise<void> {
    return httpAxiosClient.delete(
      `asset/${assetId}/questionnaire/${questionnaireId}`, { params: { isBuyerAsset } }
    )
  },

  saveCluster1AnswersForGroup(
    questionnaireId: Id,
    answerIds: Array<number>,
    isBuyerAsset: boolean
  ): Promise<void> {
    return httpAxiosClient.put(
      `asset/questionnaire/${questionnaireId}/defaults/save`,
      answerIds,
      { params: { isBuyerAsset }}
    )
  },

  applyCluster1AnswersFromGroup(
    assetId: Id,
    questionnaireId: Id,
    isBuyerAsset: boolean
  ): Promise<void> {
    return httpAxiosClient.put(
      `asset/${assetId}/questionnaire/${questionnaireId}/defaults/fill`,
      {},
      { params: { isBuyerAsset }} 
    )
  },

  async exportAssetQuest(
    assetId: Id,
    questionnaireId: Id,
    exportFormat: EExportFormat,
    language: string,
    isBuyerAsset: boolean
  ): Promise<void> {
    const link = document.createElement('a')
    const params = new URLSearchParams({
      exportFormat: `${exportFormat}`,
      language,
      isBuyerAsset: `${isBuyerAsset}`
    })
    const exportUrl = `export/questionnaire/${assetId}/${questionnaireId}?${params}`
    const EXPORT_FORMAT_TYPE_MAPPING = {
      0: 'application/json',
      1: 'text/csv',
      2: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const response: AxiosResponse<Blob> = await httpAxiosClient.get(exportUrl, {
      responseType: 'blob',
    })
    const type = EXPORT_FORMAT_TYPE_MAPPING[exportFormat]
    const blob = new Blob([response.data], { type })

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', '')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  /** the http call returns 200, when lock was successful or we are the locker already. It returns 423, if someone else locked it */
  lockQuestionnaire(
    assetId: Id,
    questionnaireId: Id,
    isLocked: boolean,
    isBuyerAsset: boolean
  ): Promise<AxiosResponse<ILockedStatus>> {
    return httpAxiosClient.put(
      `asset/${assetId}/questionnaire/${questionnaireId}/lock`,
      null,
      { params: { isLocked, isBuyerAsset } }
    )
  },

  async getChangeLog(
    assetId: number,
    questionnaireId: number,
    isBuyerAsset: boolean
  ): Promise<IAnswerChangeLogContainer[] | null> {
    const res = await httpAxiosClient.get(
      `asset/${assetId}/questionnaire/${questionnaireId}/changelog`, { params: { isBuyerAsset } }
    )
    return res.data
  },

  async getDocumentUrl(documentId: number): Promise<string> {
    const res = await httpAxiosClient.get(`asset/answer/document/${documentId}`)
    return res.data
  },
  async getActiveQuestinnaire(): Promise<void> {
    const res = await httpAxiosClient.get('Questionnaire/active')
    return res.data
  },
  cancelRequest(): void {
    cancelRequest()
  },
}
