enum EConditionsAndEnvironmentGoals {
    StockAndClimateProtection = 0,
    StockAndClimateChangeAdaption = 1,
    RenovationAndClimateProtection = 2,
    RenovationAndClimateChangeAdaption = 3,
    NewConstructionAndClimateProtection = 4,
    NewConstructionAndClimateChangeAdaption = 5,
    ProjectDevelopmentAndClimateProtection = 6,
    ProjectDevelopmentAndClimateChangeAdaption = 7,
    NewConstructionAndCircularEconomy = 8,
    ProjectDevelopmentAndCircularEconomy = 9,
    RenovationAndCircularEconomy = 10
}

export default EConditionsAndEnvironmentGoals