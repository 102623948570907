<template>
  <div v-click-outside="close" class="flex" @click="isOpen = !isOpen">
    <div class="flex items-center text-lightGreen cursor-pointer" ref="trigger">
      <Icon name="profile" class="w-10" />
      <Icon
        name="chevron-down"
        class="w-6 ml-1 transition-transform"
        :class="isOpen && 'transform-gpu rotate-180'"
      />
    </div>
    <ul
      v-show="isOpen"
      ref="popup"
      class="flex flex-col z-[100] bg-white rounded-xl shadow-popup divide-y divide-lighterGray"
    >
      <li class="list-entry">
        {{ userName }}
      </li>
      <li class="list-entry mb-2">
        <p class="font-medium">
          {{ $t('accountManagement.users-role') }}
        </p>
        <p v-for="role in roles" :key="role" class="pt-2 ml-2">
          {{ role }}
        </p>
      </li>
      <li class="list-entry hover font-medium" @click="logout">
        <HighlightText highlightClass="font-semibold">
          {{ $t('general.logout') }}
        </HighlightText>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { user as userHelpers } from '@/helpers'
import Icon from '@/components/Icon'
import HighlightText from '@/components/HighlightText'
import { usePopper } from '@/composition'

export default {
  components: {
    Icon,
    HighlightText,
  },
  emit: ['open', 'close'],
  setup(props, { emit }) {
    const store = useStore()

    const userName = store.getters['auth/userName']
    const userRoles = store.getters['auth/userRoles']

    return {
      ...usePopper(emit, [10, 0]),
      userRoles,
      userName,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    },
  },
  computed: {
    roles() {
      return this.userRoles?.map(userHelpers.roleName)
    },
  },
}
</script>

<style scoped>
.list-entry {
  @apply text-gray text-[17px]
    py-2
    px-5;
}
.hover {
  @apply hover:font-semibold
    hover:text-primary
    hover:cursor-pointer;
}
</style>
