<template>
  <Dialog :class="userRequest.pending.value && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{
        $t(`accountManagement.users-${isNewUser ? 'create' : 'edit'}-headline`)
      }}
    </template>

    <div class="form-parent">
      <div
        class="
          flex flex-col
          items-start
          border-b-[3px] border-lightestGray
          py-10
        "
      >
        <span class="section-label">
          {{ `${$t('accountManagement.users-edit-roles')}*` }}
        </span>
        <EditRoles
          :userInputs="userInputs"
          :errorMessages="errorMessages"
          :isOrganisationTestAccount="isOrganisationTestAccount"
          :isOrganisationFinancePartner="isOrganisationFinancePartner"
          @close="v$.userInputs.userRoles.$touch()"
          @setRoles="setRoles"
        />
      </div>
      <div class="flex flex-col pt-10 pb-5">
        <span class="section-label">
          {{ `${$t('accountManagement.users-edit-info')}*` }}
        </span>
        <div class="input-grid mt-2">
          <Input
            v-model="userInputs.lastName"
            :placeholder="$t('input.lastName')"
            :errorMessage="errorMessages.lastName"
            @blur="v$.userInputs.lastName.$touch()"
          />
          <Input
            v-model="userInputs.firstName"
            :placeholder="$t('input.firstName')"
            :errorMessage="errorMessages.firstName"
            @blur="v$.userInputs.firstName.$touch()"
          />
          <EditOrganisation
            :userInputs="userInputs"
            :errorMessages="errorMessages"
            :isNewUser="isNewUser"
            ref="editOrganisation"
            @close="closeOrganisationDropDown"
            @setOrganisation="setOrganisation"
          />
          <Input
            v-model="userInputs.position"
            :placeholder="$t('input.companyPosition')"
            :errorMessage="errorMessages.position"
            @blur="v$.userInputs.position.$touch()"
          />
          <Input
            type="email"
            v-model="userInputs.email"
            :placeholder="$t('input.email')"
            :errorMessage="errorMessages.email"
            :disabled="!isNewUser"
            @blur="v$.userInputs.email.$touch()"
          />
          <Input
            v-if="!isNewUser"
            v-model="userInputs.userName"
            :placeholder="$t('input.userName')"
            :disabled="true"
          />
          <Input
            type="tel"
            v-model="userInputs.phoneNumber"
            :placeholder="$t('input.phoneNumber')"
            :errorMessage="errorMessages.phoneNumber"
            @blur="v$.userInputs.phoneNumber.$touch()"
          />
        </div>
      </div>
      <EditAccesses
        :userInputs="userInputs"
        :errorMessages="errorMessages"
        ref="editAccesses"
      />
      <div class="flex flex-col pt-10 pb-5">
        <span class="text-[14px] text-darkGray">
          {{ `*${$t('input.requiredFields')}` }}
        </span>
      </div>
    </div>

    <template #actions>
      <div>
        <Button
          class="w-[240px]"
          :disabled="userRequest.pending.value"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="userRequest.pending.value"
          variant="primary"
          @click="confirm"
        >
          {{ $t(`general.${isNewUser ? 'create' : 'save'}`) }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { pick } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { Input, Button } from '@/components/form'
import { users as usersApi } from '@/services/api'
import { general } from '@/helpers'
import { useDialog } from '@/composition'
import Dialog from './../Dialog'
import { EditAccesses, EditOrganisation, EditRoles } from './composition'

const editModeFields = [
  'lastName',
  'firstName',
  'position',
  'phoneNumber',
  'userRoles',
  'external',
  'mainContactPerson',
  'assetGroups',
  'assets',
]

const createModeFields = [
  ...editModeFields,
  'organisationId',
  'organisationName',
  'email',
]

const emptyUser = {
  firstName: '',
  lastName: '',
  organisationId: null,
  organisationName: '',
  position: '',
  email: '',
  userName: '',
  phoneNumber: '',
  userRoles: [],
  external: false,
  mainContactPerson: false,
  assetGroups: [],
  assets: [],
}

export default {
  components: {
    Dialog,
    Input,
    Button,
    EditAccesses,
    EditOrganisation,
    EditRoles,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      default: emptyUser,
    },
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()
    const userRequest = useRequest((user, isCreate) =>
      usersApi[isCreate ? 'create' : 'edit'](user)
    )
    const validators = useValidators()

    return {
      ...useDialog(props, emit, userRequest.pending),
      userRequest,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      userInputs: Object.assign({}, emptyUser),
      isOrganisationTestAccount:
        this.$refs.editOrganisation?.isOrganisationTestAccount() ?? false,
      isOrganisationFinancePartner:
        this.$refs.editOrganisation?.isOrganisationFinancePartner() ?? false,
    }
  },
  validations() {
    const fieldValidations = this.availableFields.reduce((result, field) => {
      if (field === 'assetGroups' || field === 'assets') {
        result[field] = {}
      } else {
        result[field] = { required: this.validators.required }
      }
      return result
    }, {})

    fieldValidations.userRoles.required = this.validators.requiredRole
    if (fieldValidations.email) {
      fieldValidations.email.email = this.validators.email
    }

    return { userInputs: fieldValidations }
  },
  computed: {
    isNewUser() {
      return !this.user?.id
    },
    availableFields() {
      return this.isNewUser ? createModeFields : editModeFields
    },
    errorMessages() {
      return this.validators.errorMessages(
        this.v$.userInputs,
        this.availableFields
      )
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      this.v$.userInputs.$touch()
      if (this.v$.userInputs.$error) {
        return
      }
      try {
        const payload = pick(this.userInputs, this.availableFields)
        if (!this.isNewUser) {
          payload.id = this.user.id
        }
        if (!this.$refs.editAccesses.canChooseAssetGroups) {
          payload.assetGroups = []
        }
        if (!this.$refs.editAccesses.canChooseAssets) {
          payload.assets = []
        }

        const user = await this.userRequest.request(payload, this.isNewUser)
        this.$emit('finish', user)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: this.isNewUser
            ? 'users-create-success'
            : 'users-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: this.isNewUser
            ? 'users-create-error'
            : 'users-edit-error',
        })
      }
    },
    setOrganisation(organisationId, organisationName) {
      this.userInputs.organisationId = organisationId
      this.userInputs.organisationName = organisationName

      this.isOrganisationTestAccount =
        this.$refs.editOrganisation?.isOrganisationTestAccount() ?? false
      this.isOrganisationFinancePartner =
        this.$refs.editOrganisation?.isOrganisationFinancePartner() ?? false
    },
    setRoles(roles, external, mainContactPerson) {
      this.userInputs.userRoles = roles
      this.userInputs.external = external
      this.userInputs.mainContactPerson = mainContactPerson
    },
    closeOrganisationDropDown(){
      if(this.v$.userInputs.organisationId)
        this.v$.userInputs.organisationId.$touch()
    }
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }

      this.v$.userInputs.$reset()
      this.userRequest.error.value = false

      if (this.isNewUser) {
        Object.assign(this.userInputs, emptyUser)
      } else {
        const user = general.defaultingPick(this.user, emptyUser)
        Object.assign(this.userInputs, user)
      }

      this.isOrganisationTestAccount =
        this.$refs.editOrganisation?.isOrganisationTestAccount() ?? false
      this.isOrganisationFinancePartner =
        this.$refs.editOrganisation?.isOrganisationFinancePartner() ?? false
    },
  },
}
</script>

<style scoped>
.form-parent >>> .section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
.form-parent >>> .input-grid {
  @apply grid grid-cols-2 gap-x-10;
}
.form-parent >>> .input-grid > * {
  @apply w-[358px] mt-1;
}
</style>
