import axios, { Canceler, AxiosResponse } from 'axios'
import axiosClient from './http'
import {
  IOrganisation,
  IOrganisationCreate,
  IOrganisationEdit,
} from '@/models/organisation'
import { EExportFormat } from '@/enums'
import httpAxiosClient from './http'

type Id = string | number

const CancelToken = axios.CancelToken
let cancelRequest: Canceler

export default {
  async getList(
    isFinancePartner?: boolean,
    isBuyerOrganisation?: boolean
  ): Promise<Array<IOrganisation>> {
    try {
      let queryString = ''
      if (typeof isFinancePartner !== 'undefined') {
        queryString = `isFinancePartner=${isFinancePartner}&`
      }
      if (typeof isBuyerOrganisation !== 'undefined') {
        queryString = `${queryString}isBuyerOrganisation=${isBuyerOrganisation}&`
      }
      let url = 'organisations'
      if (queryString !== '') {
        url = `${url}?${queryString.slice(0, -1)}` // Removing trailing &
      }

      const res = await axiosClient.get(url, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },
  async getById(id: string): Promise<IOrganisation> {
    const res = await axiosClient.get(`organisations/${id}`)
    return res.data
  },
  async create(org: IOrganisationCreate): Promise<IOrganisation> {
    const res = await axiosClient.post(`organisations`, org)
    return res.data
  },
  async edit(org: IOrganisationEdit): Promise<IOrganisation> {
    const res = await axiosClient.put(`organisations`, org)
    return res.data
  },
  activate(id: string): Promise<void> {
    return axiosClient.post(`organisations/activate/${id}`)
  },
  deactivate(id: string): Promise<void> {
    return axiosClient.delete(`organisations/${id}`)
  },
  delete(id: string): Promise<void> {
    return axiosClient.delete(`organisations/${id}`)
  },
  async exportOrganisations(
    Organisations: Id[],
    language: string,
    exportFormat: EExportFormat,
  ): Promise<void> {
    const link = document.createElement('a')
    const params = new URLSearchParams({
      exportFormat: `${exportFormat}`,
      language,
    })
    Organisations.forEach(Organisation => {
      params.append('Organisations', Organisation.toString());
    });
    const exportUrl = `export/users?${params}`
    const EXPORT_FORMAT_TYPE_MAPPING = {
      0: 'application/json',
      1: 'text/csv',
      2: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const response: AxiosResponse<Blob> = await httpAxiosClient.get(exportUrl, {
      responseType: 'blob',
    })
    const type = EXPORT_FORMAT_TYPE_MAPPING[exportFormat]
    const blob = new Blob([response.data], { type })

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', '')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },
  cancelRequest(): void {
    cancelRequest()
  },
}
