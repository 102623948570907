<template>
  <Dialog
    :class="pending && 'cursor-wait'"
    v-model="isOpen"
    :error="error && `${$t('error.request')}.`"
    :success="success && `${$t('login.forgotPassword-success')}.`"
  >
    <template #title>
      {{ $t('login.forgotPassword-headline') }}
    </template>
    <template #description>
      <p class="w-[540px]">
        {{ $t('login.forgotPassword-description') }}
      </p>
      <form class="mt-6 -mb-8" novalidate @submit.stop.prevent="onSubmit">
        <Input
          class="w-[360px]"
          type="text"
          icon="profile"
          :placeholder="$t('input.userName')"
          :errorMessage="errorMessages.userName"
          v-model="forgotPw.userName"
          @blur="v$.forgotPw.userName.$touch()"
          @keyup.enter="confirm"
        />
      </form>
    </template>
    <template #actions>
      <div>
        <Button
          v-if="!success"
          class="w-[200px]"
          :disabled="pending"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </Button>
        <Button
          v-if="!success"
          class="ml-[31px] w-[200px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('general.send') }}
        </Button>
        <Button
          v-else
          type="button"
          variant="primary"
          class="self-center px-16 mt-6"
          @click="close"
        >
          {{ $t('login.setPassword-toLogin') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { Button, Input } from '@/components/form'
import { useDialog } from '@/composition'
import Dialog from './Dialog'

export default {
  components: {
    Dialog,
    Button,
    Input,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore()
    const request = useRequest(userName =>
      store.dispatch('auth/resetPassword', userName)
    )
    const validators = useValidators()

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      forgotPw: {
        userName: '',
      },
      success: false,
    }
  },
  validations() {
    return {
      forgotPw: {
        userName: {
          required: this.validators.required,
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return {
        userName: this.v$.forgotPw.userName.$errors[0]?.$message,
      }
    },
  },
  methods: {
    async confirm() {
      this.v$.forgotPw.$touch()
      if (this.v$.forgotPw.$error) {
        return
      }
      try {
        await this.request(this.forgotPw.userName)
        this.success = true
      } catch {
        // error handled through error flag
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.v$.forgotPw.$reset()
        this.error = false
        this.success = false
        this.forgotPw.userName = ''
      }
    },
  },
}
</script>
