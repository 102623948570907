<template>
  <div class="flex flex-col">
    <h1 class="text-[2.125rem] font-light text-white mt-[3.25rem]">
      {{ heading }}
    </h1>
    <p class="text-[17px] text-white mt-[1.25rem]">
      {{ description }}
    </p>

    <div class="flex items-center mt-10 min-h-[60px]">
      <Button
        v-for="button in buttons"
        :key="button.label"
        class="xl:min-w-[16.25rem] px-8 mr-8"
        variant="tertiary"
        :disabled="button.disabled"
        @click="button.onClick"
      >
        {{ button.label }}
      </Button>
      <Search
        v-if="hasPagination"
        class="ml-auto"
        :placeholder="searchPlaceholder"
        v-model="search"
      />
    </div>

    <TableFrame class="mt-12" v-bind="{ tabs, search, hasPagination }" />
  </div>
</template>

<script>
import Search from '@/components/Search'
import { Button } from '@/components/form'
import { TableFrame } from '@/components/dataTable'

export default {
  components: {
    Button,
    Search,
    TableFrame,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    buttons: {
      type: Array,
      validator: buttons => buttons.every(button => button.label),
    },
    tabs: {
      type: Object,
      validator: list => list.every(tab => tab.routeName && tab.label),
      required: true,
    },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    searchPlaceholder() {
      if (this.$i18n.locale === 'de') {
        return `${this.$t(`pages.${this.$route.name}`)} suchen ...`
      } else {
        return `Search ${this.$t(
          `pages.${this.$route.name}`
        ).toLowerCase()} ...`
      }
    },
  },
}
</script>
