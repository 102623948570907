import { ref, toRef, watch } from 'vue'
import type { Ref } from 'vue'
import { round } from 'lodash-es'
import { general } from '@/helpers'

interface IUseFloatInput {
  stringModel: Ref<string>
  emitFloat: () => void
}

type Emit = (name: string, val: number) => void

export default function (
  props: { modelValue: number },
  emit: Emit,
  rounding = Infinity
): IUseFloatInput {
  const modelValue = toRef(props, 'modelValue')
  const stringModel = ref('')

  watch(
    modelValue,
    val => {
      stringModel.value = general.toFloatString(round(val, rounding))
    },
    { immediate: true }
  )

  function emitFloat() {
    const float = general.toFloat(stringModel.value)
    emit('update:modelValue', isNaN(float) ? 0 : round(float, rounding))
  }

  return {
    stringModel,
    emitFloat,
  }
}
