<template>
  <Root v-bind="attrs.wrapper" v-click-outside="clickOutside">
    <div class="flex flex-row mb-1">
      <label class="inline-block text-xs pl-2 mb-1" :class="labelClasses">
        {{ heading || '&nbsp;' }}
      </label>
      <InfoPopup
        v-if="info || infoImage"
        class="ml-auto"
        :info="info"
        :image="infoImage"
      />
    </div>
    <div class="relative h-0">
      <div
        v-show="allowHover && isHover && selectedOptions.length > 1"
        class="
          z-[100]
          text-sm
          bg-lightBlue
          text-darkBlue
          border border-blue
          rounded-md
          p-1
          shadow-popup
          whitespace-pre-wrap
          max-w-[320px]
          absolute
          left-[5px]
          bottom-[-10px]
        "
      >
        {{ selectionText }}
      </div>
    </div>

    <InputWrap
      v-bind="attrs.field"
      v-model:isOpen="isPopupOpen"
      ref="trigger"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <Input
        v-bind="attrs.input"
        :text="selectionText"
        :disabled="!!$attrs.disabled"
      />
      <ItemsIndicator
        :number="selectedOptions.length"
        :disabled="!!$attrs.disabled"
      />
      <Chevron :isOpen="isPopupOpen" :disabled="!!$attrs.disabled" />
    </InputWrap>

    <div class="text-xs text-error cursor-default pl-2 mt-1">
      {{ errorMessage || '&nbsp;' }}
    </div>

    <Popup
      v-bind="{ isOpen: isPopupOpen, items }"
      ref="popup"
      @select="onSelect"
      #default="{ item }"
    >
      <div class="flex justify-between">
        <span
          :class="isSelected(item) && 'text-lightGreen'"
          highlightClass="font-semibold"
        >
          {{ item.text }}
        </span>
        <Checkbox class="cursor-pointer mr-3" :modelValue="isSelected(item)" />
      </div>
    </Popup>
  </Root>
</template>

<script>
import Root from './Root'
import InputWrap from './InputWrap'
import Input from './Input'
import Chevron from './Chevron'
import Popup from '@/components/form/Popup'
import ItemsIndicator from './ItemsIndicator'
import { Checkbox } from '@/components/form'
import { usePopper } from '@/composition'
import InfoPopup from '@/components/form/InfoPopup.vue'

export default {
  inheritAttrs: false,
  components: {
    Root,
    InputWrap,
    Input,
    ItemsIndicator,
    Chevron,
    Popup,
    Checkbox,
    InfoPopup,
  },
  emits: ['update:modelValue', 'open', 'close'],
  props: {
    items: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
      validator(selection) {
        return selection.every(
          entry => entry.key !== undefined && entry.text !== undefined
        )
      },
    },
    label: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    info: {
      type: String,
    },
    infoImage: {
      type: String,
    },
    allowHover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    return usePopper(emit, [0, 10], true)
  },
  data() {
    return {
      hasFocus: false,
      itemOptions: [],
      isHover: false,
    }
  },
  computed: {
    attrs() {
      const { title, disabled, class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
        disabled,
      }
      const fieldAttrs = {
        title,
        hasFocus: this.hasFocus,
        hasError: !!this.errorMessage,
      }

      return {
        wrapper: wrapperAttrs,
        field: fieldAttrs,
        input: inputAttrs,
      }
    },
    selectionText() {
      if (this.selectedOptions.length > 0) {
        return this.selectedOptions.map(option => option.text).join(', ')
      }
      return this.$attrs.placeholder
    },
    selectedOptions() {
      return this.itemOptions.filter(
        option => option.selected && !option.disabled
      )
    },
    selectedOptionsLength: function () {
      return this.selectedOptions.length
    },
    labelClasses() {
      return this.errorMessage
        ? 'text-error'
        : this.$attrs.disabled
        ? 'text-lightGray'
        : this.hasFocus
        ? 'text-primary'
        : 'text-lightBlack'
    },
    heading() {
      if (this.label) {
        return this.label
      }
      if (this.modelValue.length > 0) {
        return this.$attrs.placeholder
      }
      return ''
    },
    isPopupOpen: {
      get() {
        return this.isOpen && !this.$attrs.disabled
      },
      set(value) {
        if (!this.$attrs.disabled) {
          this.isOpen = value
        }
      },
    },
  },
  methods: {
    clickOutside() {
      this.close()
    },
    onSelect(item) {
      const entry = this.itemOptions.find(({ key }) => key === item.key)
      entry.selected = !entry.selected && !item.disabled
    },
    isSelected(item) {
      return !!this.modelValue.find(
        ({ key }) => key === item.key && !item.disabled
      )
    },
    setItemOptions() {
      this.itemOptions = this.items.map(item => ({
        ...item,
        selected: !!this.modelValue.find(({ key }) => key === item.key),
      }))
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.setItemOptions()
      },
      immediate: true,
    },
    items() {
      this.setItemOptions()
    },
    selectedOptionsLength() {
      // May also be affected if 'disabled' property changes for a selected item
      this.$emit('update:modelValue', this.selectedOptions)
    },
  },
}
</script>
