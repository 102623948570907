<template>
  <input
    class="
      w-0
      flex-1
      rounded-l-md
      outline-none
      text-[15px]
      font-medium
      overflow-ellipsis
      pointer-events-none
      bg-origin-content
      p-3
      disabled:text-darkGray
    "
    :class="disabled ? 'bg-backgroundDisabled text-darkGray' : 'bg-white'"
    :value="text"
    :disabled="disabled"
    
  />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    // native input is always disabled, for when parent is explicitly disabled
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
