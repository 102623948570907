<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('accountManagement.users-activate-headline') }}
    </template>
    <template #description>
      <p>
        {{
          $t('accountManagement.users-activate-description1', {
            firstName: user.firstName,
            lastName: user.lastName,
          })
        }}
      </p>
      <p class="mt-8">
        {{ $t('accountManagement.users-activate-description2') }}
      </p>
      <p class="mt-8">
        {{
          $t('accountManagement.users-activate-description3', {
            firstName: user.firstName,
            lastName: user.lastName,
          })
        }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('accountManagement.users-activate-action') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Dialog from './Dialog'
import { useDialog } from '@/composition'
import { useRequest } from '@/composition'
import { Button } from '@/components/form'
import { users as usersApi } from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'activate'],
  setup(props, { emit }) {
    const request = useRequest(() => usersApi.activate(props.user.id))

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('activate', this.user)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'users-activate-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'users-activate-error',
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
