<template>
  <Disclosure v-slot="{ open }">
    <tr class="border-lighterGray last:border-b-0 h-16" :class="open ? 'border-b-0' : 'border-b'">
      <td class="pl-8">
        <DisclosureButton as="template">
          <Icon class="inline-block cursor-pointer transition-transform w-8 lg:w-6" :class="{
            'transform-gpu rotate-180': open,
            'opacity-50 pointer-events-none': !group.assets?.length,
            'mr-9 lg:mr-6': canEdit,
          }" name="chevron-down" />
        </DisclosureButton>
        {{ group.name }}
      </td>
      <td>
        {{ group.id }}
      </td>
      <td>
        {{ group.organisationName }}
      </td>
      <td class="text-center" v-show="showScore">
        {{ group.score }}
      </td>
      <td class="text-right whitespace-nowrap pr-11">
        <Button v-if="canSeeScore" variant="secondary" class=" mx-7
              lg:mx-5 px-5" @click="showScoreFunction(group)">
          {{ $t('assetManagement.objectGroups-show-score') }}
        </Button>
        <template v-if="canEdit">

          <Icon v-if="canUpdateToActiveQuestionnaire" class="inline-block stroke-current cursor-pointer w-6 lg:w-5"
            :class="{
              'opacity-50 pointer-events-none':
                !canAssetGroupBeUpdateToActiveQuestionnaire,
            }" name="reset" @click="$emit('updateToActiveQuestionnaire')" />
          <Icon class="
              inline-block
              stroke-current
              cursor-pointer
              w-6
              lg:w-5
              ml-7
              lg:ml-5
            " name="edit" @click="$emit('edit')" />
          <Icon class="
              inline-block
              stroke-current
              cursor-pointer
              w-6
              lg:w-5
              ml-7
              lg:ml-5
            " :class="{
              'opacity-50 pointer-events-none': !!group.assets?.length,
            }" name="trash" @click="$emit('delete')" />
          <Icon class="inline-block cursor-pointer w-6 lg:w-5 ml-7 lg:ml-5" name="plus-circled" @click="$emit('add')" />
          <Icon v-if="!isProspectiveBuyer" :class="{
            'opacity-50 pointer-events-none': !canDownloadLable,
          }" class="inline-block cursor-pointer w-6 lg:w-5 ml-7 lg:ml-5" name="certificate-download"
            @click="$emit('downloadPage')" />

          <Button v-if="!isProspectiveBuyer" class="ml-7 px-5" variant="primary" :disabled="!group.canBeSubmitted"
            @click="$emit('submit')">
            {{ $t('assetManagement.objectGroups-submit-for-review') }}
          </Button>
        </template>
      </td>
    </tr>
    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform-gpu scale-95 opacity-0"
      enter-to-class="transform-gpu scale-100 opacity-100" leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform-gpu scale-100 opacity-100" leave-to-class="transform-gpu scale-95 opacity-0">
      <DisclosurePanel as="tr">
        <td class="bg-[#f3f3f3] border-lighterGray border-b px-11 pt-5 pb-6" colspan="4">
          <div class="font-bold text-xs uppercase">
            {{ $t('assetManagement.objectGroups-objects') }} ({{
              group.assets.length
            }})
          </div>
          <ul class="flex flex-wrap mt-3">
            <li v-for="{ assetId, name, isBuyerAsset } in group.assets" :key="assetId" class="
                font-bold
                text-primary
                cursor-pointer
                hover:underline
                ml-8
                first:ml-0
              " @click="$emit('edit:object', assetId, isBuyerAsset)">
              {{ name }}
            </li>
          </ul>
        </td>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { EAssetState, EUserRole, EAssetGroupState } from '@/enums'
import { user } from '@/helpers'
import Icon from '@/components/Icon'
import { Button } from '@/components/form'
import {
  objectGroups as objectGroupsApi,
} from '@/services/api'
import { ref } from 'vue'

export default {
  components: {
    Icon,
    Button,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    showScore: {
      type: Boolean,
    }
  },
  emits: [
    'updateToActiveQuestionnaire',
    'edit',
    'delete',
    'add',
    'edit:object',
    'submit',
    'downloadPage',
    'showScoreToRow'
  ],
  computed: {
    canEdit() {
      return user.hasPermission([
        EUserRole.ObjectAdministrator,
        EUserRole.AccountAdministrator,
        EUserRole.ProspectiveBuyer,
      ])
    },
    isProspectiveBuyer() {
      return user.hasPermission([EUserRole.ProspectiveBuyer])
    },
    canUpdateToActiveQuestionnaire() {
      return user.hasPermission([
        EUserRole.ObjectAdministrator,
        EUserRole.ProspectiveBuyer,
      ])
    },
    canDownloadLable() {
      return (
        user.hasPermission([
          EUserRole.ObjectAdministrator,
          EUserRole.Evaluator,
        ]) && this.group.state === EAssetGroupState.Closed
      )
    },
    canAssetGroupBeUpdateToActiveQuestionnaire() {
      return (
        this.group.assets?.some(asset => asset.state === EAssetState.Active) &&
        this.group.assets
          ?.filter(asset => asset.state === EAssetState.Active)
          .map(asset => asset.questionnaireId)
          .filter((v, i, a) => a.indexOf(v) === i /*distinct*/).length <= 2 // 2 because current active questionnaire template is fine. Besides that one, only 1 is questionnaire template should exist.
      )
    },
    canSeeScore() {
      return user.hasPermission([EUserRole.AccountAdministrator, EUserRole.ObjectAdministrator, EUserRole.ObjectDataCollector])
    }
  },
  methods: {
    async showScoreFunction(group) {
      const { score } = await objectGroupsApi.getScore(group.id);
      this.$emit('showScoreToRow', { group, score: `${score}%` })
    }
  }
}
</script>
