<template>
  <svg viewBox="0 0 100 100" class="fill-current">
    <use :xlink:href="`${iconMap[name]}#icon`" />
  </svg>
</template>

<script>
const context = require.context('@/assets/icons', true, /\.svg$/)
const iconMap = context.keys().reduce((map, key) => {
  const name = key.match(/([\w\d-]+).svg$/)[1]
  map[name] = context(key)
  return map
}, {})

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      iconMap,
    }
  },
}
</script>
