import { pick } from 'lodash-es'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import { useRequest } from '@/composition'
import { objectGroups as api } from '@/services/api'

export interface IUseObjectGroups {
  request: () => Promise<void>
  options: Ref<Array<IGroupOption>>
  pending: Ref<boolean>
  error: Ref<boolean>
  success: Ref<boolean>
}

export interface IGroupOption {
  key: number
  text: string
  org: string
}

export default function (): IUseObjectGroups {
  const store = useStore()

  const groupOptions = ref<Array<IGroupOption>>([])
  const groupsRequest = useRequest(api.getList)

  const request = async () => {
    try {
      const list = await groupsRequest.request()
      if (!list) {
        return
      }
      groupOptions.value = list.map(({ id, name, organisationName }) => ({
        key: id,
        text: name,
        org: organisationName,
      }))
    } catch {
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objectGroups-list-fetch-error',
      })
    }
  }

  return {
    request,
    options: groupOptions,
    ...pick(groupsRequest, ['pending', 'error', 'success']),
  }
}
