import { i18n } from '@/plugins'
import { langStorage } from '@/services/storage'

export default {
  setLocale(lang: string): void {
    i18n.global.locale = lang
    langStorage.setLocale(lang)
  },
  getLocale(): string {
    return langStorage.getLocale() || i18n.global.locale
  },
  restoreLocale(): void {
    i18n.global.locale = this.getLocale()
  },
}
