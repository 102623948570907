<template>
  <section class="relative flex flex-col overflow-y-scroll" ref="scrollParent">
    <div class="absolute inset-x-0 top-0 select-none" id="background">
      <picture>
        <source
          type="image/webp"
          srcset="
            @/assets/images/bg-bar.webp    1x,
            @/assets/images/bg-bar@2x.webp 2x
          "
        />
        <source
          type="image/png"
          srcset="
            @/assets/images/bg-bar.png    1x,
            @/assets/images/bg-bar@2x.png 2x
          "
        />
        <img
          src="@/assets/images/bg-bar.png"
          alt="background"
          class="w-full h-[495px]"
        />
      </picture>
    </div>
    <div
      class="relative flex-grow"
      :class="!noPad && 'pt-[1.25rem] px-[6.25rem] pb-[1.75rem]'"
    >
      <Breadcrumbs
        v-if="!noBreadcrumbs"
        id="breadcrumbs"
        class="text-[13px] text-white"
        :class="noPad && 'mt-[1.25rem] mx-[6.25rem]'"
        :customItems="breadcrumbs"
      />
      <slot />
      <GoUp :class="noPad && 'mx-[6.25rem] mb-[1.75rem]'" />
    </div>
    <Footer />
  </section>
</template>

<script>
import { ref, provide } from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import GoUp from '@/components/GoUp'
import Footer from './Footer'

export default {
  components: {
    Breadcrumbs,
    GoUp,
    Footer,
  },
  props: {
    noPad: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Array,
    },
    noBreadcrumbs: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const scrollParent = ref(null)
    provide('scrollParent', scrollParent)
    return {
      scrollParent,
    }
  },
}
</script>

<style scoped>
@media print {
  #breadcrumbs,
  #background {
    display: none;
  }
}
</style>
