<template>
  <Dialog v-model="isOpen">
    <template #title>
      {{ $t('score.questionnaire-locked-headline') }}
    </template>
    <template #description>
      <p>
        {{
          $t('score.questionnaire-locked-description', {
            name: lockedStatus?.currentLockerName,
          })
        }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="ml-[31px] w-[240px]" variant="primary" @click="confirm">
          {{ $t('general.okay') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useDialog } from '@/composition'
import { Button } from '@/components/form'
import Dialog from './Dialog'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    lockedStatus: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'activate'],
  setup(props, { emit }) {
    return {
      ...useDialog(props, emit),
    }
  },
  methods: {
    confirm() {
      this.close()
    },
  },
}
</script>
