import axios, { Canceler } from 'axios'
import axiosClient from './http'
import { IUser, IUserEdit, IUserCreate } from '@/models/user'
import { EUserRole } from '@/enums'

const CancelToken = axios.CancelToken
let cancelRequest: Canceler

export default {
  async getList(): Promise<Array<IUser>> {
    try {
      const res = await axiosClient.get('user', {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },
  async getTesters(): Promise<Array<IUser>> {
    try {
      const res = await axiosClient.get('user/testers', {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data.filter(
        (u: IUser) => !u.userRoles.includes(EUserRole.InternalTester)
      )
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },
  activate(id: string): Promise<void> {
    return axiosClient.post(`user/activate/${id}`)
  },
  deactivate(id: string): Promise<void> {
    return axiosClient.delete(`user/${id}`)
  },
  archive(id: string): Promise<void> {
    return axiosClient.delete(`user/archive/${id}`)
  },
  async edit(user: IUserEdit): Promise<IUser> {
    const res = await axiosClient.put('user', user)
    return res.data
  },
  async create(user: IUserCreate): Promise<IUser> {
    const res = await axiosClient.post('user', user)
    return res.data
  },
  cancelRequest(): void {
    cancelRequest()
  },
}
