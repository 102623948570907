<template>
  <div
    v-bind="attrs.wrapper"
    class="wrapper flex rounded-xl bg-tertiary text-white px-[19px] py-[15px]"
  >
    <Icon class="w-7 h-7 mr-3" name="search" />
    <input
      class="
        input
        bg-transparent
        text-[17px]
        font-medium
        placeholder-lightWhite
        focus:outline-none
        truncate
      "
      v-bind="attrs.input"
      v-model="value"
    />
    <Icon class="w-7 h-7 ml-3 cursor-pointer" name="close" @click="clear" />
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'search',
  components: { Icon },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    attrs() {
      const { class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
      }

      return {
        wrapper: wrapperAttrs,
        input: inputAttrs,
      }
    },
  },
  methods: {
    clear() {
      this.value = ''
    },
  },
}
</script>

<style scoped>
.wrapper {
  flex-basis: 328px;
  flex-grow: 0;
  flex-shrink: 1;
}

.input {
  /* cancel native width */
  width: 120px;
  flex-grow: 1;
  flex-shrink: 0;
}
</style>
