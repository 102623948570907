<template>
  <nav
    class="flex xl:text-[17px] gap-x-1 xl:gap-x-2 px-8 xl:px-14 pt-2"
    :class="[moreThenThreeRoutes ? 'text-[16px]' : 'text-[17px]']"
  >
    <template v-for="route in routes" :key="route.id">
      <router-link
        v-if="route.permitted"
        class="flex items-center border-b-4 px-4"
        :class="
          isOnRoute(route.name)
            ? 'text-primary font-extrabold border-lightGreen'
            : 'text-darkGray border-transparent'
        "
        :to="{ name: route.name }"
      >
        <HighlightText highlightClass="font-semibold xl:font-extrabold">
          {{ route.label }}
        </HighlightText>
      </router-link>
    </template>
  </nav>
</template>

<script>
import HighlightText from '@/components/HighlightText'
import { user } from '@/helpers'

const routeNames = [
  'home',
  'score',
  'accountManagement',
  'assetManagement',
  'jobManagement',
  'export',
]

export default {
  components: {
    HighlightText,
  },
  computed: {
    routes() {
      const routes = this.$router.getRoutes()
      return routeNames.map(name => {
        const route = routes.find(entry => entry.name === name)
        return {
          label: this.$t(`pages.${name}`),
          name,
          permitted:
            name == 'export'
              ? user.userIsInFinancePartner()
              : user.hasPermission(route.meta.roles),
        }
      })
    },
    moreThenThreeRoutes() {
      return this.routes.filter(route => route.permitted === true).length > 3
    },
  },
  methods: {
    isOnRoute(name) {
      /* route /asset-management needs to be highlighted both for views named
         'assetManagement' and 'editObject'. the nav header only has entry
         'assetManagement' though.
      */
      const alsoCheckForEditObject = name === 'assetManagement'

      return this.$route.matched.slice(1).find(part => {
        if (part.name === name) {
          return true
        }
        if (alsoCheckForEditObject && part.name === 'editObject') {
          return true
        }
        return false
      })
    },
  },
}
</script>
