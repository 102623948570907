<template>
  <div
    class="flex justify-end sticky bottom-[24px] pointer-events-none"
    ref="container"
    id="go-up"
  >
    <button
      class="
        flex flex-center
        rounded-full
        bg-[#b0dd7f]
        text-white
        shadow-raisedButton
        focus:outline-none
        cursor-pointer
        pointer-events-auto
        w-14
        h-14
        -mr-4
      "
      :class="!visible && 'invisible'"
      @click="scrollUp"
    >
      <Icon class="transform -rotate-90 w-6" name="arrow-forward" />
    </button>
  </div>
</template>

<script>
import { throttle } from 'lodash-es'
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  inject: ['scrollParent'],
  props: {
    visibilityTreshold: {
      type: Number,
      default: 250,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    scrollUp() {
      this.scrollParent.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    handleVisiblity() {
      this.visible = this.scrollParent.scrollTop > this.visibilityTreshold
    },
  },
  mounted() {
    this.handleVisiblity()
    this.handleVisiblityThrottled = throttle(this.handleVisiblity, 250)
    this.scrollParent.addEventListener('scroll', this.handleVisiblityThrottled)
  },
  beforeUnmount() {
    if (this.scrollParent) {
      this.scrollParent.removeEventListener(
        'scroll',
        this.handleVisiblityThrottled
      )
    }
  },
}
</script>
<style scoped>
@media print {
  #go-up {
    display: none;
  }
}
</style>
