import { pick, omit, sortBy, partialRight } from 'lodash-es'
import { EAnswerState, EAssetState, EQuestionType } from '@/enums'
import { i18n } from '@/plugins'
import type {
  ICategory,
  ICluster,
  IQuestionnaire,
  IQuestionnaireEdit,
  IAssetQuestionnaire,
  ISection,
} from '@/models/questionnaire'
import type {
  IAnswer,
  IAnswerCreate,
  IAnswerEdit,
  IOption,
  IQuestion,
  IQuestionEdit,
  ITranslationEdit,
} from '@/models/question'
import type {
  ICategoryInStore,
  ISectionInStore,
  IQuestionInStore,
  IQuestionnaireInStore,
  ICategoryRetrieval,
  ISectionRetrieval,
  IQuestionRetrieval,
  LevelType,
} from './types'

export function initQuestionnaire(
  state: IQuestionnaireInStore,
  questionnaire: IQuestionnaire & IAssetQuestionnaire,
  dontUpdateQuestions: number[] = []
): void {
  state.id = questionnaire.id
  state.title = questionnaire.title
  state.state = questionnaire.state
  state.type = questionnaire.type
  state.closedAt = questionnaire.closedAt
  state.assetQuestionnaireState = questionnaire.assetQuestionnaireState
  state.canSetGroupDefaultAnswers = questionnaire.canSetGroupDefaultAnswers
  state.modified = false
  state.canBeSubmitted = questionnaire?.canBeSubmitted ?? false
  state.canBeReviewed = questionnaire?.canBeReviewed ?? false
  state.canBeUpdatedToActiveQuestionnaire = questionnaire?.canBeUpdatedToActiveQuestionnaire ?? false
  state.clusters = questionnaire.clusters.map(c => {
    return {
      ...c,
      categories: [],
      modified: false,
    }
  })
  const sort = partialRight(sortBy, 'order')

  state.clusters = questionnaire.clusters.map((cluster: ICluster, clusterIdx: number) => ({
    ...cluster,
    modified: false,

    categories: sort(cluster.categories).map((cat: ICategory) => ({
      ...cat,
      modified: false,

      sections: sort(cat.sections).map((section: ISection) => ({
        ...section,
        modified: false,

        questions: sort(section.questions).map((quest: IQuestion) => {
          if (dontUpdateQuestions.includes(quest.id)) {
            return retrieveQuestion(state, {
              clusterIdx,
              categoryId: cat.id,
              sectionId: section.id,
              questionId: quest.id,
            })
          }
          const assetState = questionnaire.assetQuestionnaireState

          const answerState = quest.answer?.state
          const answerable =
            assetState === EAssetState.Active ||
            (assetState === EAssetState.Declined &&
              (answerState === undefined ||
                answerState === EAnswerState.Declined))

          const result: IQuestionInStore = {
            ...quest,
            modified: false,
            answerable,
            virtScores: [],
            answerChangeLog: [],
          }
          if (quest.type === EQuestionType.VirtualQuestion) {
            quest.options.forEach((opt, idx) => {
              result.virtScores[idx] = opt.score
            })
            result.options = []
          }
          return result
        }),
      })),
    })),
  }))
}

export function cleanTemplateRequestPayload(
  state: IQuestionnaireInStore,
  payload: Omit<IQuestionnaireEdit, 'clusters'>
): IQuestionnaireEdit {
  return {
    ...payload,
    clusters: state.clusters.map(cluster => ({
      ...omit(cluster, ['modified']),

      categories: cluster.categories.map((cat, idx) => ({
        ...omit(cat, ['id', 'modified']),
        id: cat.id < 0 ? null : cat.id,
        order: idx,

        sections: cat.sections.map((section, idx) => ({
          ...omit(section, ['id', 'modified']),
          id: section.id < 0 ? null : section.id,
          order: idx,

          questions: section.questions.map((quest, idx) => {
            const options: Array<IOption> = quest.virtScores.map(
              (score, idx) => {
                const option: IOption = {
                  id: null,
                  score: score,
                  order: idx,
                  isExclusive: false,
                  translations: [],
                }
                return option
              }
            )
            const result: IQuestionEdit & {
              translations: Array<ITranslationEdit>
            } = {
              ...omit(quest, ['modified', 'answerable', 'virtScores']),
              order: idx,
              options:
                quest.type === EQuestionType.VirtualQuestion
                  ? options
                  : quest.options,
            }

            return result
          }),
        })),
      })),
    })),
  }
}

export function retrieveCategory(
  state: IQuestionnaireInStore,
  { clusterIdx, categoryId }: ICategoryRetrieval
): ICategoryInStore | undefined {
  return state.clusters[clusterIdx].categories.find(
    ({ id }) => id === categoryId
  )
}

export function retrieveSection(
  state: IQuestionnaireInStore,
  { clusterIdx, categoryId, sectionId }: ISectionRetrieval
): ISectionInStore | undefined {
  return retrieveCategory(state, { clusterIdx, categoryId })?.sections.find(
    ({ id }) => id === sectionId
  )
}

export function retrieveQuestion(
  state: IQuestionnaireInStore,
  { clusterIdx, categoryId, sectionId, questionId }: IQuestionRetrieval
): IQuestionInStore | undefined {
  return retrieveSection(state, {
    clusterIdx: clusterIdx,
    categoryId: categoryId,
    sectionId: sectionId,
  })?.questions.find(({ id }) => id === questionId)
}

export function prepareAnswerToSend<T extends IAnswerCreate | IAnswerEdit>(
  answer: IAnswer,
  state: EAnswerState
): T {
  return {
    ...pick(answer, [
      'id',
      'assetId',
      'questionId',
      'inputAnswer',
      'multipleChoiceOption',
      'isBuyerAsset'
    ]),
    state,
  } as T
}

export function questionIsAnswered(question: IQuestionInStore): boolean {
  const answer = question.answer
  if (!answer) {
    return false
  }
  return (
    answer.multipleChoiceOption.length > 0 ||
    answer.inputAnswer !== '' ||
    answer.state !== null
  ) /* state !== null => when it is an input answer and the option "no answer" is selected */
}

export function findIndexById(
  collection: Array<LevelType>,
  id: number
): number {
  return collection.findIndex(el => el.id === id)
}

export let currentTmpId = 0
export function tempId(): number {
  return --currentTmpId
}
