<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{
        $t(
          `accountManagement.organisations-${
            isNewOrg ? 'create' : 'edit'
          }-headline`
        )
      }}
    </template>

    <div class="flex flex-col border-b-[3px] border-lightestGray py-10 mt-2">
      <span class="section-label">
        {{ `${$t('accountManagement.organisations-edit-account')}*` }}
      </span>
      <div class="input-grid mt-2">
        <Input
          v-model="orgInputs.name"
          :placeholder="$t('input.organisationName')"
          :errorMessage="errorMessages.orgInputs.name"
          @blur="v$.orgInputs.name.$touch()"
        />
        <Input
          v-model="orgInputs.city"
          :placeholder="$t('input.city')"
          :errorMessage="errorMessages.orgInputs.city"
          @blur="v$.orgInputs.city.$touch()"
        />
        <Input
          v-model="orgInputs.street"
          :placeholder="$t('input.street')"
          :errorMessage="errorMessages.orgInputs.street"
          @blur="v$.orgInputs.street.$touch()"
        />
        <div class="flex justify-between">
          <Input
            class="w-[45%]"
            v-model="orgInputs.number"
            :placeholder="$t('input.streetNumber')"
            :errorMessage="errorMessages.orgInputs.number"
            @blur="v$.orgInputs.number.$touch()"
          />
          <Input
            class="w-[45%]"
            v-model="orgInputs.zip"
            :placeholder="$t('input.zip')"
            :errorMessage="errorMessages.orgInputs.zip"
            @blur="v$.orgInputs.zip.$touch()"
          />
        </div>
        <Input
          v-model="orgInputs.country"
          :placeholder="$t('input.country')"
          :errorMessage="errorMessages.orgInputs.country"
          @blur="v$.orgInputs.country.$touch()"
        />
      </div>
      <div class="input-grid mt-2">
        <Checkbox class="self-start my-4" v-model="differingBilling">
          <span class="uppercase">
            {{ $t('accountManagement.organisations-edit-differingBilling') }}
          </span>
        </Checkbox>
        <Checkbox
          class="self-start my-4"
          v-model="orgInputs.isSolutionPartner"
          :disabled="isAmOfFinanceOrg"
        >
          <span class="uppercase">
            {{ $t('input.solutionPartner') }}
          </span>
        </Checkbox>
        <Checkbox
          class="self-start my-4"
          v-model="orgInputs.isFinancePartner"
          :disabled="
            !isAccountAdministrator ||
            !isNewOrg ||
            orgInputs.isBuyer ||
            orgInputs.isTestAccount
          "
        >
          <span class="uppercase">
            {{ $t('input.financePartner') }}
          </span>
        </Checkbox>
        <Checkbox
          class="self-start my-4"
          v-model="orgInputs.isBuyer"
          :disabled="
            !isAccountAdministrator ||
            !isNewOrg ||
            orgInputs.isFinancePartner ||
            orgInputs.isTestAccount
          "
        >
          <span class="uppercase">
            {{ $t('input.buyer') }}
          </span>
        </Checkbox>
        <Checkbox
          v-if="canShowTestAccount"
          class="self-start my-4"
          v-model="orgInputs.isTestAccount"
          :disabled="
            orgInputs.isFinancePartner || orgInputs.isBuyer || isAmOfFinanceOrg
          "
        >
          <span class="uppercase">
            {{ $t('input.testAccount') }}
          </span>
        </Checkbox>
      </div>
    </div>
    <div
      v-if="orgInputs.isBuyer"
      class="
        flex flex-col
        border-b-[3px] border-lightestGray
        bg-[#F0F7EE]
        py-10
      "
    >
      <Select
        class="flex-1 ml-12"
        v-model="orgInputs.financePartnerOrganisation"
        :items="getFinancePartnerOrgs"
        :placeholder="
          $t('accountManagement.organisations-edit-select-finance-partner')
        "
        :errorMessage="errorMessages.orgInputs.financePartnerOrganisation"
      />
    </div>
    <div
      v-if="differingBilling"
      class="
        flex flex-col
        border-b-[3px] border-lightestGray
        bg-[#F0F7EE]
        py-10
      "
    >
      <span class="section-label">
        {{ `${$t('accountManagement.organisations-edit-billingAddress')}*` }}
      </span>
      <div class="input-grid mt-2">
        <Input
          v-model="billingInputs.streetName"
          :placeholder="$t('input.street')"
          :errorMessage="errorMessages.billingInputs.streetName"
          @blur="v$.billingInputs.streetName.$touch()"
        />
        <div class="flex justify-between">
          <Input
            class="w-[45%]"
            v-model="billingInputs.streetNr"
            :placeholder="$t('input.streetNumber')"
            :errorMessage="errorMessages.billingInputs.streetNr"
            @blur="v$.billingInputs.streetNr.$touch()"
          />
          <Input
            class="w-[45%]"
            v-model="billingInputs.postcode"
            :placeholder="$t('input.zip')"
            :errorMessage="errorMessages.billingInputs.postcode"
            @blur="v$.billingInputs.postcode.$touch()"
          />
        </div>
        <Input
          v-model="billingInputs.city"
          :placeholder="$t('input.city')"
          :errorMessage="errorMessages.billingInputs.city"
          @blur="v$.billingInputs.city.$touch()"
        />
        <Input
          v-model="billingInputs.country"
          :placeholder="$t('input.country')"
          :errorMessage="errorMessages.billingInputs.country"
          @blur="v$.billingInputs.country.$touch()"
        />
      </div>
    </div>

    <div class="flex flex-col pt-10 pb-5">
      <span class="section-label">
        {{ `${$t('accountManagement.organisations-edit-contact')}*` }}
      </span>
      <div class="input-grid mt-2">
        <Input
          v-model="contactInputs.fullName"
          :placeholder="$t('input.contactName')"
          :errorMessage="errorMessages.contactInputs.fullName"
          @blur="v$.contactInputs.fullName.$touch()"
        />
        <Input
          v-model="contactInputs.companyRole"
          :placeholder="$t('input.companyPosition')"
          :errorMessage="errorMessages.contactInputs.companyRole"
          @blur="v$.contactInputs.companyRole.$touch()"
        />
        <Input
          v-model="contactInputs.email"
          :placeholder="$t('input.email')"
          :errorMessage="errorMessages.contactInputs.email"
          @blur="v$.contactInputs.email.$touch()"
        />
        <Input
          v-model="contactInputs.mobile"
          :placeholder="$t('input.mobileNumber')"
          :errorMessage="errorMessages.contactInputs.mobile"
          @blur="v$.contactInputs.mobile.$touch()"
        />
        <Input
          v-model="contactInputs.phone"
          :placeholder="$t('input.phoneNumber')"
          :errorMessage="errorMessages.contactInputs.phone"
          @blur="v$.contactInputs.phone.$touch()"
        />
      </div>
      <span class="text-[14px] text-darkGray">
        {{ `*${$t('input.requiredFields')}` }}
      </span>
    </div>

    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="pending"
          variant="primary"
          @click="confirm"
        >
          {{ $t(`general.${isNewOrg ? 'create' : 'save'}`) }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { pick } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { EUserRole } from '@/enums'
import { user as userHelpers } from '@/helpers'
import { Input, Checkbox, Button, Select } from '@/components/form'
import { organisations as organisationsApi } from '@/services/api'
import { general } from '@/helpers'
import { useDialog } from '@/composition'
import Dialog from './Dialog'

const emptyBillingAddress = {
  city: '',
  streetName: '',
  streetNr: '',
  postcode: '',
  country: '',
}

const emptyOrg = {
  name: '',
  city: '',
  street: '',
  number: '',
  zip: '',
  country: '',
  isSolutionPartner: false,
  isTestAccount: false,
  isFinancePartner: false,
  isBuyer: false,
  financePartnerOrganisation: false,
}

const emptyContact = {
  fullName: '',
  companyRole: '',
  email: '',
  phone: '',
  mobile: '',
}

export default {
  components: {
    Dialog,
    Input,
    Checkbox,
    Button,
    Select,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    organisation: {
      type: Object,
      default: emptyOrg,
    },
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const request = useRequest((org, isCreate) =>
      organisationsApi[isCreate ? 'create' : 'edit'](org)
    )
    const getOrganisations = useRequest(() => organisationsApi.getList(true)) // true -> isFinancePartner
    const validators = useValidators()

    return {
      ...useDialog(props, emit, request.pending),
      ...request,
      getOrganisations,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      differingBilling: false,
      orgInputs: Object.assign({}, emptyOrg),
      billingInputs: Object.assign({}, emptyBillingAddress),
      contactInputs: Object.assign({}, emptyContact),
      allOrganisations: [],
    }
  },
  validations() {
    const buildValidations = fields =>
      fields.reduce((result, field) => {
        result[field] = { required: this.validators.required }
        return result
      }, {})

    const orgValidations = buildValidations(Object.keys(emptyOrg))
    const billingValidations = buildValidations(
      Object.keys(emptyBillingAddress)
    )
    const contactValidations = buildValidations(Object.keys(emptyContact))

    contactValidations.email.email = this.validators.email

    return {
      orgInputs: orgValidations,
      billingInputs: billingValidations,
      contactInputs: contactValidations,
    }
  },
  computed: {
    isNewOrg() {
      return !this.organisation?.id
    },
    errorMessages() {
      const orgInputs = this.validators.errorMessages(
        this.v$.orgInputs,
        Object.keys(emptyOrg)
      )
      const billingInputs = this.validators.errorMessages(
        this.v$.billingInputs,
        Object.keys(emptyBillingAddress)
      )
      const contactInputs = this.validators.errorMessages(
        this.v$.contactInputs,
        Object.keys(emptyContact)
      )

      return { orgInputs, billingInputs, contactInputs }
    },
    inputsValid() {
      return (
        !this.v$.orgInputs.$error &&
        !this.v$.contactInputs.$error &&
        (!this.differingBilling || !this.v$.billingInputs.$error)
      )
    },
    canShowTestAccount() {
      return this.isNewOrg || this.organisation.isTestAccount
    },
    isAccountAdministrator() {
      return userHelpers.hasPermission([EUserRole.AccountAdministrator])
    },
    getFinancePartnerOrgs() {
      return this.allOrganisations.map(org => ({
        key: org.id,
        text: org.name,
      }))
    },
    isBuyer() {
      return this.orgInputs.isBuyer
    },
    isAmOfFinanceOrg() {
      return (
        userHelpers.hasPermission([EUserRole.AccountManager]) &&
        this.$store.getters['auth/organisationIsFinancePartner']
      )
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      this.v$.$touch()
      if (!this.inputsValid) {
        return
      }
      try {
        const payload = pick(this.orgInputs, Object.keys(emptyOrg))

        payload.billingAddress = this.differingBilling
          ? this.billingInputs
          : null
        payload.contacts = [this.contactInputs]

        if (!this.isNewOrg) {
          if (this.organisation.contacts.length > 1) {
            payload.contacts.concat([this.organisation.contacts.slice(1)])
          }
          payload.id = this.organisation.id
        }
        payload.financePartnerOrganisationId =
          payload.financePartnerOrganisation.key
        delete payload.financePartnerOrganisation

        const org = await this.request(payload, this.isNewOrg)
        this.$emit('finish', org)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: this.isNewOrg
            ? 'organisations-create-success'
            : 'organisations-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: this.isNewOrg
            ? 'organisations-create-error'
            : 'organisations-edit-error',
        })
      }
    },
    clearInputs() {
      Object.assign(this.orgInputs, emptyOrg)
      Object.assign(this.billingInputs, emptyBillingAddress)
      Object.assign(this.contactInputs, emptyContact)
      this.differingBilling = false
    },
    async populateInputs() {
      let org = general.defaultingPick(this.organisation, emptyOrg)
      if (this.organisation.isBuyer) {
        const connectedFinancePartner = this.allOrganisations.find(
          org => org.id === this.organisation.financePartnerOrganisationId
        )
        if (connectedFinancePartner)
          org.financePartnerOrganisation = {
            key: connectedFinancePartner.id,
            text: connectedFinancePartner.name,
          }
        else {
          const ownOrg = await this.fetchOwnOrganisation()
          if (ownOrg)
            org.financePartnerOrganisation = {
              key: ownOrg.id,
              text: ownOrg.name,
            }
        }
      }
      Object.assign(this.orgInputs, org)

      if (this.organisation.billingAddress) {
        this.differingBilling = true
        const billing = general.defaultingPick(
          this.organisation.billingAddress,
          emptyBillingAddress
        )
        Object.assign(this.billingInputs, billing)
      } else {
        this.differingBilling = false
        Object.assign(this.billingInputs, emptyBillingAddress)
      }

      if (this.organisation.contacts.length > 0) {
        const contact = general.defaultingPick(
          this.organisation.contacts[0],
          emptyContact
        )
        Object.assign(this.contactInputs, contact)
      } else {
        Object.assign(this.contactInputs, emptyContact)
      }
    },
    async fetchOwnOrganisation() {
      const ownOrganisationRequest = useRequest(() =>
        this.$store.dispatch('auth/getOrganisation')
      )
      const ownOrg = await ownOrganisationRequest.request()
      return ownOrg
    },
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }

      this.v$.$reset()
      this.error = false

      if (
        userHelpers.hasPermission([EUserRole.AccountAdministrator]) &&
        (this.isNewOrg || this.organisation.isBuyer)
      ) {
        this.allOrganisations = await this.getOrganisations.request()
      }

      if (this.isNewOrg) {
        this.clearInputs()
        if (this.isAmOfFinanceOrg) {
          this.orgInputs.isBuyer = true
          const ownOrg = await this.fetchOwnOrganisation()
          if (ownOrg) {
            this.orgInputs.financePartnerOrganisation = {
              key: ownOrg.id,
              text: ownOrg.name,
            }
          }
        }
      } else {
        this.populateInputs()
      }
    },
    isBuyer(value) {
      // orgInputs.financePartnerOrganisation should be false when buyer is not selected (because false is valid),
      // and initial null when buyer is selected (because null will throw error for that field)
      if (!value) {
        this.orgInputs.financePartnerOrganisation = false
      } else if (this.orgInputs.financePartnerOrganisation == false) {
        // If entering the modal for editing a buyer org, financePartnerOrganisation is not touched. If changing isBuyer checkbox on org creation, financePartnerOrganisation selection is removed.
        this.orgInputs.financePartnerOrganisation = null
      }
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
.input-grid {
  @apply grid grid-cols-2 gap-x-10;
}
.input-grid > * {
  @apply w-[358px] mt-1;
}
</style>
