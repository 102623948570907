<template>
  <label v-bind="attrs.wrapper" class="flex items-center">
    <div class="relative inline-block w-[50px] h-[20px]">
      <input
        type="checkbox"
        v-model="value"
        v-bind="attrs.input"
        class="hidden"
      />
      <span
        :class="switchClass"
        class="rounded-full absolute cursor-pointer inset-0"
      >
        <span
          aria-hidden="true"
          :class="sliderClass"
          class="
            pointer-events-none
            inline-block
            h-[25px]
            w-[25px]
            rounded-full
            shadow-xl
            transform-gpu
            ring-0
            transition
            ease-in-out
            duration-200
            translate-y-[-2.5px]
          "
        />
      </span>
    </div>
    <slot />
  </label>
</template>
<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs
      const wrapperAttrs = {
        title,
        class: _class,
      }
      return {
        input: inputAttrs,
        wrapper: wrapperAttrs,
      }
    },
    sliderClass() {
      if (this.attrs.input.disabled) {
        return 'bg-lighterGray'
      } else {
        return this.modelValue
          ? 'translate-x-[25px] bg-lightGreen'
          : 'translate-x-0 bg-lightestGray'
      }
    },
    switchClass() {
      if (this.attrs.input.disabled) {
        return 'bg-lightestGray'
      } else {
        return this.modelValue ? 'bg-primary' : 'bg-lightGray translate-x-0'
      }
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
