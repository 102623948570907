<template>
  <router-view class="font-inter h-screen print:h-auto" />
  <teleport to="body">
    <ToastMessage />
  </teleport>
  <ScreenOverlay class="lg:hidden" id="screen-overlay" />
</template>

<script>
import ScreenOverlay from '@/components/ScreenOverlay'
import ToastMessage from '@/components/ToastMessage'
import { langSelect } from '@/services'

export default {
  components: {
    ScreenOverlay,
    ToastMessage,
  },
  created() {
    langSelect.restoreLocale()
    this.$store.dispatch('auth/tryLoginFromStorage')
  },
  watch: {
    '$i18n.locale': {
      handler(locale) {
        document.getElementsByTagName('html')[0].lang = locale
      },
      immediate: true,
    },
  },
}
</script>

<style>
@media print {
  @page {
    size: A4 portait;
    margin: 1cm;
  }
  /* To remove scrollbars for printing */
  ::-webkit-scrollbar {
    display: none;
  }
  #screen-overlay {
    display: none;
  }
}
</style>
