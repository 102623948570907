const storage_key = 'locale_setting'

export default {
  setLocale(lang: string): void {
    localStorage.setItem(storage_key, lang)
  },
  getLocale(): string | null {
    return localStorage.getItem(storage_key)
  },
}
