<template>
  <div
    class="text-darkGray focus:outline-none w-full"
    :tabindex="$attrs.disabled ? -1 : 0"
    @focus="focusChange(true)"
    @blur="focusChange(false)"
    @click="click"
  >
    <slot />
  </div>
</template>

<script>
export default {
  emits: ['change:focus'],
  methods: {
    focusChange(hasFocus) {
      this.$emit('change:focus', hasFocus)
    },
    click(event) {
      if (this.$attrs.disabled) {
        event.stopPropagation()
      }
    },
  },
}
</script>
