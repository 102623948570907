enum EProjectStatus {
    BasicEvaluation,
    PreliminaryPlanning,
    DesignPlanning,
    ApprovalPlanning,
    ImplementationPlanning,
    PreparationForAwardOfContract,
    ParticipationInAwardingTheContract,
    ObjectSupervisionConstructionSupervisionAndDocumentation,
    ObjectSupervision
}

export default EProjectStatus