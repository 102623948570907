import { pick } from 'lodash-es'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import { useRequest } from '@/composition'
import { objects as objectsApi } from '@/services/api'
import { object as objectHelpers } from '@/helpers'

export interface IUseCountries {
  request: () => Promise<void>
  options: Ref<Array<ICountryOption>>
  pending: Ref<boolean>
  error: Ref<boolean>
  success: Ref<boolean>
}

export interface ICountryOption {
  key: string
  text: string
}

export default function (): IUseCountries {
  const store = useStore()

  const countryOptions = ref<ICountryOption[]>([])
  const countriesRequest = useRequest(objectsApi.getCountries)

  const request = async () => {
    try {
      const list = await countriesRequest.request()
      if (!list) {
        return
      }
      countryOptions.value = list
        .map(country => ({
          key: country.countryName, // used as country identifier in the BE
          text: objectHelpers.countryName(country.code),
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
      //Place Other Countries option to display in the bottom of the list.
      const otherCountries = countryOptions.value.filter(country => {
        return country.key == 'Other Countries'
      })
      countryOptions.value = countryOptions.value.filter(country => {
        return country.key != 'Other Countries'
      })
      countryOptions.value = [...countryOptions.value, ...otherCountries]
    } catch {
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objects-countries-fetch-error',
      })
    }
  }

  return {
    request,
    options: countryOptions,
    ...pick(countriesRequest, ['pending', 'error', 'success']),
  }
}
