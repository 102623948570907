<template>
  <div @mouseleave="isOpen = false">
    <Icon
      v-if="variant === 'icon'"
      ref="trigger"
      class="text-blue w-5"
      name="information"
      @mouseenter="isOpen = true"
    />
    <div
      v-if="variant === 'text'"
      ref="trigger"
      class="truncate"
      @mouseenter="isOpen = true"
    >
      {{ info }}
    </div>
    <div
      v-show="isOpen"
      ref="popup"
      class="z-[100] text-sm bg-lightBlue text-darkBlue border border-blue rounded-md p-1 shadow-popup whitespace-pre-wrap"
      :class="image ? 'max-w-[650px]' : 'max-w-[320px]'"
    >
      {{ info }}
      <img v-if="image" :src="require(`@images/${image}.png`)" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { usePopper } from '@/composition'

export default {
  components: { Icon },
  props: {
    variant: {
      type: String,
      default: 'icon',
      validator: val => ['icon', 'text'].includes(val),
    },
    info: {
      type: String,
    },
    image: {
      type: String,
    },
  },
  emits: ['open', 'close'],
  setup(props, { emit }) {
    return usePopper(
      emit,
      [2, 2],
      false,
      props.variant === 'icon' ? 'bottom-end' : 'top-start'
    )
  },
}
</script>
