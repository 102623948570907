<template>
  <div class="flex flex-col">
    <div class="flex">
      <router-link
        v-for="{ routeName, label } in tabs"
        :key="label"
        class="text-center text-[17px] min-w-[185px] py-[11px] px-[38px]"
        :class="
          $route.name === routeName
            ? 'bg-white text-primary font-semibold rounded-t-md pointer-events-none'
            : 'text-white'
        "
        :to="{ name: routeName }"
      >
        {{ label }}
      </router-link>

      <div v-if="hasPagination" class="flex items-center ml-auto">
        <span class="text-[13px] font-semibold text-white mr-2">
          {{ $t('table.resultsPerPage') }}
        </span>
        <MiniSelect :items="pageSizes" v-model="pageSize" />
      </div>
    </div>

    <router-view
      class="flex-grow bg-white rounded-b-md rounded-tr-md"
      v-bind="{
        search,
        pageSize,
        pageSlice: slice,
        totalItems: numItems,
      }"
      @update:table="onTableUpdate"
    />

    <PageSelector
      v-if="hasPagination"
      class="self-center mt-8"
      v-bind="{ buttons, hasPrev, hasNext, goPrev, goNext }"
      @update:page="page = $event"
    />
  </div>
</template>

<script>
import usePaginator from 'vue-use-paginator'
import PageSelector from './PageSelector'
import MiniSelect from './MiniSelect'

const pageSizes = [5, 10, 25, 50, 100]

export default {
  components: {
    PageSelector,
    MiniSelect,
  },
  props: {
    hasPagination: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      validator: list => list.every(tab => tab.routeName && tab.label),
      required: true,
    },
  },
  setup() {
    return usePaginator({ pageSize: 5, numButtons: 5 })
  },
  methods: {
    onTableUpdate(numItems) {
      this.numItems = numItems
    },
  },
  watch: {
    $route() {
      this.page = 1
      this.numItems = 0
    },
    toBeExported(val) {
      console.log(val)
    },
  },
  created() {
    this.pageSizes = pageSizes
  },
}
</script>
