<template>
  <div class="rounded-full overflow-hidden" ref="trigger">
    <Icon :name="`lang-${language}`" class="w-5 h-5" />
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
  },
}
</script>
