<template>
  <div
    class="flex flex-center rounded-r-md w-[48px]"
    :class="disabled ? 'bg-backgroundDisabled' : 'bg-lightestGreen'"
  >
    <Icon
      name="chevron-down"
      class="text-gray transition-transform w-6"
      :class="{ 'transform-gpu rotate-180': isOpen, 'opacity-50': disabled }"
    />
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
  },
}
</script>
