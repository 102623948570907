<template>
  <transition
    class="transition-all duration-700 ease-in-out"
    enter-class="opacity-0 transform-gpu scale-100"
    enter-to-class="opacity-100 transform-gpu scale-100"
    leave-class="opacity-100 transform-gpu scale-100"
    leave-to-class="opacity-0 transform-gpu scale-90"
  >
    <div
      v-if="message"
      class="
        text-white
        min-h-16
        fixed
        z-20
        bottom-20
        left-10
        inline-flex
        flex-center
        rounded-md
        py-[13px]
        px-[20px]
      "
      :class="classes"
    >
      <Icon v-if="icon" class="w-8 mr-3" :name="icon" />

      <span class="max-w-[600px]">{{
        $t(`notifications.${this.message.translationKey}`)
      }}</span>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Icon from '@/components/Icon'

const messageVariants = {
  info: {
    icon: 'icon-information',
    classes: 'bg-gradient-to-r from-messageInfoStart to-messageInfoEnd',
  },
  success: {
    icon: 'icon-success',
    classes: 'bg-gradient-to-r from-messageSuccessStart to-messageSuccessEnd',
  },
  warning: {
    icon: 'icon-alert',
    classes: 'bg-gradient-to-r from-messageWarningStart to-messageWarningEnd',
  },
  error: {
    icon: 'icon-error',
    classes: 'bg-gradient-to-r from-messageErrorStart to-messageErrorEnd',
  },
}

export default {
  components: { Icon },

  computed: {
    ...mapGetters('toastMessage', ['message']),

    classes() {
      return messageVariants[this.message.type].classes
    },
    icon() {
      return messageVariants[this.message.type].icon
    },
  },
}
</script>
