<template>
  <div
    class="flex flex-center w-[65px]"
    :class="disabled ? 'bg-backgroundDisabled' : 'bg-white'"
  >
    <Pill class="self-center bg-blue text-white w-8">
      {{ number }}
    </Pill>
  </div>
</template>

<script>
import Pill from '@/components/Pill'

export default {
  components: {
    Pill,
  },
  props: {
    number: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
