<template>
  <div
    class="inline-flex rounded-lg border border-lighterGray cursor-pointer w-[64px] h-[35px]"
    :class="[bgColor ? `${bgColor} text-white` : ' bg-white  text-darkGray']"
    v-bind="attrs.wrapper"
    v-click-outside="close"
    ref="trigger"
    @click="isOpen = !isOpen"
  >
    <input
      v-if="modelValue"
      v-bind="attrs.input"
      :value="modelValue"
      readonly="true"
      class="flex-1 w-0 text-[12px] text-center font-medium focus:outline-none cursor-pointer select-none bg-transparent"
    />
    <span
      v-else
      class="block text-[12px] font-medium hover:font-bold px-3 py-2"
      >{{ title }}</span
    >
    <Icon
      name="chevron-down"
      class="transition-transform w-4 mr-2"
      :class="isOpen && 'transform-gpu rotate-180'"
    />
    <ul
      v-show="isOpen"
      ref="popup"
      class="flex flex-col z-[100] bg-white border border-lighterGray rounded-lg select-none cursor-pointer py-1"
    >
      <li
        v-for="item in items"
        :key="item"
        class="block text-darkGray text-[12px] font-medium hover:font-bold px-3 py-1"
        @click="value = item"
      >
        <HighlightText highlightClass="font-bold">
          {{ item }}
        </HighlightText>
      </li>
    </ul>
  </div>
</template>
<script>
import Icon from '@/components/Icon'
import HighlightText from '@/components/HighlightText'
import { usePopper } from '@/composition'

export default {
  components: {
    Icon,
    HighlightText,
  },
  emits: ['update:modelValue', 'open', 'close'],
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return [5, 10, 25, 50, 100]
      },
    },
    modelValue: {
      type: Number,
    },
    title: {
      type: String,
    },
    bgColor: {
      type: String,
    },
  },
  setup(props, { emit }) {
    return usePopper(emit, [0, 2], true)
  },
  computed: {
    attrs() {
      const { class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
      }

      return {
        wrapper: wrapperAttrs,
        input: inputAttrs,
      }
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
