<template>
  <label class="flex align-center" v-bind="attrs.wrapper">
    <input class="hidden" type="radio" v-bind="attrs.input" v-model="value" />
    <span
      class="
        flex-shrink-0
        w-[20px]
        h-[20px]
        border-[2.5px] border-solid
        rounded-full
        relative
        self-center
      "
      :class="radioClasses"
    >
    </span>
    <span class="text-[15px]" :class="{ 'ml-3': this.$slots.default }">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'ui-radio',
  inheritAttrs: false,
  props: {
    modelValue: {},
    taxonomy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs
      const wrapperAttrs = {
        title,
        class: _class,
      }
      return {
        input: inputAttrs,
        wrapper: wrapperAttrs,
      }
    },
    radioClasses() {
      return this.modelValue === this.$attrs.value && !this.taxonomy
        ? 'border-lightGreen bg-lightGreen bg-clip-content p-[3px]'
        : this.modelValue === this.$attrs.value && this.taxonomy
        ? 'border-cyan bg-cyan bg-clip-content p-[3px]'
        : this.$attrs.disabled
        ? 'border-gray bg-white'
        : 'border-darkGray bg-white'
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
