<template>
  <Dialog
    :class="assignRequest.pending.value && 'cursor-wait'"
    v-model="isOpen"
  >
    <template #title>
      {{ $t(`assetManagement.${translationPrefix}-assignTester-headline`) }}
    </template>
    <template #description>
      <p>
        {{
          $t(`assetManagement.${translationPrefix}-assignTester-description`)
        }}
      </p>
      <Select
        v-model="selectedTester"
        :items="availableTesters"
        :placeholder="$t('input.tester')"
      >
      </Select>
    </template>
    <template #actions>
      <div>
        <Button
          class="w-[240px]"
          :disabled="assignRequest.pending.value"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </Button>

        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="assignRequest.pending.value || !selectedTester"
          @click="confirm"
        >
          {{ $t('general.assign') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Dialog from './Dialog'
import { useDialog, useRequest } from '@/composition'
import { Button, Select } from '@/components/form'
import {
  users as usersApi,
  objects as objectsApi,
  objectGroups as objectGroupsApi,
} from '@/services/api'
import { isNil, clone } from 'lodash-es'

export default {
  components: {
    Dialog,
    Button,
    Select,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: Number,
    },
    currentTester: {
      type: Object,
      required: false,
      validator: val => !isNil(val.key) && !isNil(val.text),
    },
    isForGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue', 'finish'],
  data() {
    return {
      selectedTester: null,
      availableTesters: [],
    }
  },
  setup(props, { emit }) {
    const testersRequest = useRequest(() => usersApi.getTesters())
    const assignRequest = useRequest(testerId =>
      objectsApi.assignTester(props.assetId, testerId)
    )
    const assignForGroupRequest = useRequest(testerId =>
      objectGroupsApi.assignTester(props.assetId, testerId)
    )
    return {
      ...useDialog(props, emit, assignRequest.pending),
      testersRequest,
      assignRequest,
      assignForGroupRequest,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        if (!this.isForGroup) {
          await this.assignRequest.request(this.selectedTester.key)
        } else {
          // call assign tester for the group
          await this.assignForGroupRequest.request(this.selectedTester.key)
        }
        this.$emit('finish')
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'tasks-assignTester-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'tasks-assignTester-error',
        })
      }
    },
  },
  computed: {
    translationPrefix() {
      return this.isForGroup ? 'objectGroup' : 'object'
    },
  },
  watch: {
    async modelValue(opening) {
      if (opening) {
        this.error = false
        this.selectedTester = clone(this.currentTester)
        try {
          const availableTesters = await this.testersRequest.request()
          this.availableTesters = availableTesters.map(tester => {
            return {
              key: tester.id,
              text: `${tester.firstName} ${tester.lastName} - ${tester.organisationName}`,
            }
          })
        } catch {
          this.showMessage({
            type: 'error',
            translationKey: 'tasks-tester-fetch-error',
          })
        }
      } else {
        this.selectedTester = null
      }
    },
  },
}
</script>
