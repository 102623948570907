<template>
  <div>
    <template v-for="({ routeName, text }, idx) in parts" :key="routeName">
      <span v-if="idx !== 0"> > </span>
      <router-link
        v-if="routeName"
        class="hover:underline"
        :to="{ name: routeName }"
      >
        {{ text }}
      </router-link>
      <span v-else>
        {{ text }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    customItems: {
      type: Array,
    },
  },
  computed: {
    parts() {
      if (this.customItems) {
        return this.customItems
      }
      return this.$route.matched.slice(1).map(part => ({
        routeName: part.name,
        text: this.$t(`pages.${part.name}`),
      }))
    },
  },
}
</script>
