import { EUserRole } from '@/enums'
import type { IAuthState, IJwtPayload } from './types'

export default {
  loggedIn(state: IAuthState): boolean {
    return !!state.tokenDecoded && !loginExpired(state.tokenDecoded)
  },
  token(state: IAuthState): string {
    return state.token
  },
  userRoles(state: IAuthState): Array<EUserRole> {
    return state.roles
  },
  isExternalRole(state: IAuthState): boolean {
    return state.tokenDecoded?.external === 'True'
  },
  userName(state: IAuthState): string {
    return state.userName
  },
  initialRouteName(state: IAuthState): string {
    return state.initialRouteName
  },
  organisationIsTestAccount(state: IAuthState): boolean {
    return state.organisationIsTestAccount
  },
  organisationIsFinancePartner(state: IAuthState): boolean {
    return state.organisationIsFinancePartner
  },
  organisationIsBuyer(state: IAuthState): boolean | undefined {
    return state.tokenDecoded?.orgBuyer
  },
  organisationId(state: IAuthState): string | undefined {
    return state.tokenDecoded?.orgId
  },
}

function loginExpired(token: IJwtPayload): boolean {
  return token.exp <= Math.floor(Date.now() / 1000)
}
