<template>
  <div
    class="text-darkGray focus:outline-none"
    :tabindex="$attrs.disabled ? -1 : 0"
    v-bind="attrs.wrapper"
    @focus="onFocus"
  >
    <div class="flex flex-row mb-1">
      <label class="inline-block text-xs pl-2" :class="labelClasses">
        {{ heading || '&nbsp;' }}
      </label>
      <InfoPopup
        v-if="info || infoImage"
        class="ml-auto"
        :info="info"
        :image="infoImage"
      />
    </div>
    <div
      v-bind="attrs.field"
      class="flex border rounded-md bg-origin-content cursor-text p-3"
      :class="[
        outlineClasses,
        $attrs.disabled ? 'bg-backgroundDisabled' : 'bg-white',
      ]"
    >
      <Icon
        v-if="icon && iconVariant === 'start'"
        class="text-primary w-6 h-6 mr-3"
        :name="icon"
      />
      <input
        class="
          flex-1
          text-[15px]
          font-medium
          focus:outline-none
          w-full
          bg-transparent
        "
        :class="{ truncate }"
        v-bind="attrs.input"
        v-model="value"
        @focus="onInputFocus"
        @blur="onBlur"
        ref="input"
      />
      <Icon
        v-if="icon && iconVariant === 'end'"
        class="text-darkGray w-6 h-6 ml-3"
        :name="icon"
      />
    </div>

    <div
      class="
        text-xs text-error
        cursor-default
        overflow-x-hidden
        whitespace-nowrap
        overflow-ellipsis
        pl-2
        mt-1
      "
    >
      {{ errorMessage || '&nbsp;' }}
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash-es'
import Icon from '@/components/Icon'
import InfoPopup from '@/components/form/InfoPopup.vue'

export default {
  name: 'ui-input',
  components: { Icon, InfoPopup },
  inheritAttrs: false,
  props: {
    modelValue: {
      default: '',
    },
    icon: {
      type: String,
    },
    iconVariant: {
      type: String,
      default: 'start',
      validator: val => ['start', 'end'].includes(val),
    },
    label: {
      type: String,
    },
    info: {
      type: String,
    },
    infoImage: {
      type: String,
    },
    visibleHeader: {
      type: Boolean,
      default: true,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    errorHighlight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
      }
      const fieldAttrs = {
        title,
      }

      return {
        wrapper: wrapperAttrs,
        field: fieldAttrs,
        input: inputAttrs,
      }
    },
    labelClasses() {
      return this.errorMessage
        ? 'text-error'
        : this.$attrs.disabled
        ? 'text-lightGray'
        : this.hasFocus
        ? 'text-primary'
        : 'text-lightBlack'
    },
    outlineClasses() {
      return this.hasFocus
        ? 'border-transparent ring-2 ring-primary'
        : this.errorMessage || this.errorHighlight
        ? 'border-error'
        : 'border-lighterGray'
    },
    heading() {
      if (this.visibleHeader) {
        if (this.label) {
          return this.label
        }
        if (!isNil(this.modelValue) && this.modelValue.toString() !== '') {
          return this.$attrs.placeholder
        }
      }

      return ''
    },
  },
  methods: {
    onFocus() {
      this.$refs.input.focus()
    },
    onBlur() {
      this.hasFocus = false
    },
    onInputFocus() {
      this.hasFocus = true
    },
  },
}
</script>
