<template>
  <Dialog
    class="fixed z-10 inset-0"
    :open="modelValue"
    @close="$emit('update:modelValue', false)"
  >
    <DialogOverlay class="fixed inset-0 overlay-blur" @wheel="onScroll" />
    <div class="flex overflow-y-auto h-screen" ref="scrollContainer">
      <transition appear>
        <div
          v-if="modelValue"
          class="
            dialog-content
            relative
            bg-white
            rounded-3xl
            shadow-dialog
            focus:outline-none
            max-w-[900px]
            m-auto
          "
          tabindex="0"
        >
          <DialogTitle
            class="
              font-bold
              text-2xl text-lightBlack
              border-b-[3px] border-lightestGray
              py-[30px]
            "
          >
            <slot name="title" />
          </DialogTitle>

          <DialogDescription
            v-if="$slots.description"
            class="font-[17px] text-darkGray pt-[34px] pb-[45px]"
          >
            <slot name="description" />
          </DialogDescription>

          <slot />

          <div
            class="text-sm text-center font-bold fit-content"
            :class="[
              error ? 'text-error' : 'text-lightGreen',
              { invisible: !error && !success },
            ]"
          >
            <slot name="notification">
              {{ error || success || '&nbsp;' }}
            </slot>
          </div>

          <div class="flex justify-center pb-[60px] pt-[30px] py-[60px]">
            <slot name="actions" />
          </div>
        </div>
      </transition>
    </div>
  </Dialog>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'

export default {
  components: { Dialog, DialogOverlay, DialogTitle, DialogDescription },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    error: {
      default: '',
    },
    success: {
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onScroll(event) {
      this.$refs.scrollContainer.scrollTop += event.deltaY
    },
  },
}
</script>

<style scoped>
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .overlay-blur {
    @apply bg-dialogBackdrop backdrop-filter backdrop-blur-xl;
  }
}
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .overlay-blur {
    @apply bg-dialogBackdropFallback;
  }
}
</style>

<style>
.dialog-content > * {
  @apply px-[75px];
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.35s ease;
}

.fit-content {
  max-width: fit-content;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
