<template>
  <LoggedOutView :class="requestPending && 'cursor-wait'">
    <template #heading>
      {{ $t('login.welcome') }}
    </template>
    <template #description>
      <div v-if="!showInfoMessage2fa">{{ $t('login.plsLogIn') }}</div>
      <div v-if="showInfoMessage2fa" class="max-w-[340px]">
        {{ $t('login.checkYourEmailBox2fa') }}
      </div>
    </template>

    <form
      v-if="!showInfoMessage2fa"
      class="flex flex-col w-80 mt-4"
      novalidate
      @submit.stop.prevent="confirm"
    >
      <Input
        type="text"
        icon="profile"
        :placeholder="$t('input.userName')"
        :errorMessage="errorMessages.userName"
        :errorHighlight="!requestPending && loginFailed"
        v-model="credentials.userName"
        @blur="v$.credentials.userName.$touch()"
      />
      <Input
        type="password"
        icon="lock"
        :placeholder="$t('input.password')"
        :errorMessage="errorMessages.password"
        :errorHighlight="!requestPending && loginFailed"
        v-model="credentials.password"
        @blur="v$.credentials.password.$touch()"
      />
      <span
        class="
          self-end
          text-sm text-darkGray
          font-bold
          tracking-wide
          cursor-pointer
          hover:underline
          mt-2
        "
        tabindex="0"
        @click="openForgotPasswordDialog"
      >
        {{ $t('login.forgotPassword') }}
      </span>
      <div
        class="text-xs font-semibold text-error mt-6"
        :class="{ invisible: !loginFailed }"
      >
        {{ `${$t('login.badCredentials')}` }}
      </div>
      <Button
        class="self-center px-16 mt-6"
        :class="requestPending && 'cursor-wait'"
        type="submit"
        variant="primary"
        :disabled="requestPending"
      >
        {{ $t('login.submit') }}
      </Button>
    </form>
    <ForgotPasswordDialog v-model="forgotPasswordDialog" />
  </LoggedOutView>
</template>

<script>
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { LoggedOutView } from '@/components/layout'
import { Input, Button } from '@/components/form'
import { ForgotPasswordDialog } from '@/components/dialog'
import { uses2fa } from '@/helpers/general'

export default {
  components: {
    LoggedOutView,
    Input,
    Button,
    ForgotPasswordDialog,
  },
  setup() {
    const store = useStore()

    const {
      request: loginRequest,
      pending: requestPending,
      error: loginFailed,
    } = useRequest(credentials => store.dispatch('auth/login', credentials))

    const validators = useValidators()

    return {
      loginRequest,
      requestPending,
      loginFailed,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      forgotPasswordDialog: false,
      credentials: {
        userName: '',
        password: '',
      },
      showInfoMessage2fa: false,
    }
  },
  validations() {
    return {
      credentials: {
        userName: {
          required: this.validators.required,
        },
        password: {
          required: this.validators.required,
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return this.validators.errorMessages(this.v$.credentials, [
        'userName',
        'password',
      ])
    },
  },
  methods: {
    async confirm() {
      this.v$.credentials.$touch()
      if (this.v$.credentials.$error) {
        return
      }
      try {
        await this.loginRequest(this.credentials)
        if (uses2fa()) {
          this.showInfoMessage2fa = true
        } else {
          this.navigateAfterLogin()
        }
      } catch {
        // error handled through loginFailed flag
      }
    },
    navigateAfterLogin() {
      const initialRouteName = this.$store.getters['auth/initialRouteName']
      this.$store.commit('auth/setInitialRouteName', '')
      this.$router.push({ path: initialRouteName || '/' })
    },
    openForgotPasswordDialog() {
      this.forgotPasswordDialog = true
    },
  },
}
</script>
