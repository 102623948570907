<template>
  <div
    v-click-outside="close"
    class="flex items-center"
    @click="isOpen = !isOpen"
  >
    <LanguagePill
      class="cursor-pointer"
      :language="$i18n.locale"
      ref="trigger"
    />
    <ul
      v-show="isOpen"
      ref="popup"
      class="
        flex flex-col
        z-[100]
        bg-white
        rounded-xl
        shadow-popup
        divide-y divide-lighterGray
      "
    >
      <li
        v-for="lang in $i18n.availableLocales"
        :key="lang"
        class="
          flex
          items-center
          text-gray text-[17px]
          font-medium
          py-2
          px-5
          cursor-pointer
          hover:font-semibold hover:text-primary
        "
        @click="setLocale(lang)"
      >
        <LanguagePill :language="lang" />
        <HighlightText highlightClass="font-semibold">
          <span class="pl-4">{{ $t(`general.language-${lang}`) }}</span>
        </HighlightText>
      </li>
    </ul>
  </div>
</template>

<script>
import LanguagePill from '@/components/LanguagePill'
import HighlightText from '@/components/HighlightText'
import { usePopper } from '@/composition'
import { langSelect } from '@/services'

export default {
  components: {
    LanguagePill,
    HighlightText,
  },
  emit: ['open', 'close'],
  setup(props, { emit }) {
    return usePopper(emit, [35, 20])
  },
  methods: {
    setLocale(lang) {
      langSelect.setLocale(lang)
    },
  },
}
</script>
