<template>
  <div class="flex flex-col">
    <Header />
    <main class="flex-grow flex bg-backgroundMain overflow-y-hidden">
      <router-view class="flex-grow" />
    </main>
  </div>
</template>

<script>
import Header from './Header'

export default {
  components: {
    Header,
  },
}
</script>

<style scoped>
@media print {
  main {
    background-color: white;
  }
}
</style>
