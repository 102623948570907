import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import Login from '@/views/Login.vue'
import SetPassword from '@/views/SetPassword.vue'
import LoginStep2 from '@/views/LoginStep2.vue'
import AssetAccessPermission from '@/views/AssetAccessPermission.vue'
import Layout from '@/components/layout/Layout.vue'
import Home from '@/views/Home.vue'
import { user } from '@/helpers'
import { EUserRole } from '@/enums'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      auth: false,
    },
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: SetPassword,
    meta: {
      auth: false,
    },
  },
  {
    path: '/login-step2',
    name: 'loginStep2',
    component: LoginStep2, // TODO: Change component
    meta: {
      auth: false,
    },
  },
  {
    path: '/asset-access-permission',
    name: 'assetAccessPermission',
    component: AssetAccessPermission,
    meta: {
      auth: false,
    },
  },
  {
    path: '/',
    component: Layout,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: '/score',
        name: 'score',
        meta: {
          roles: [EUserRole.ScoringModelManager],
        },
        component: () =>
          import(/* webpackChunkName: "score" */ '@/views/score/Score.vue'),
        redirect: { name: 'scoreAdmin' },
        children: [
          {
            path: 'admin',
            name: 'scoreAdmin',
            component: () =>
              import(
                /* webpackChunkName: "scoreAdmin" */
                '@/views/score/Admin.vue'
              ),
            redirect: { name: 'questionnairesActive' },
            children: [
              {
                path: 'active',
                name: 'questionnairesActive',
                component: () =>
                  import(
                    /* webpackChunkName: "scoreAdmin" */
                    '@/views/score/Questionnaires.vue'
                  ),
                beforeEnter(to, from) {
                  /* don't switch to draft table if we are on the published
                      table and user clicks on a link to score admin */
                  if (
                    from.name === 'questionnairesArchived' &&
                    to.redirectedFrom?.name === 'score'
                  ) {
                    return false
                  }
                },
              },
              {
                path: 'archived',
                name: 'questionnairesArchived',
                component: () =>
                  import(
                    /* webpackChunkName: "scoreAdmin" */
                    '@/views/score/Questionnaires.vue'
                  ),
              },
            ],
          },
          {
            path: 'questionnaire/:questionnaireId',
            name: 'editQuestionnaire',
            component: () =>
              import(
                /* webpackChunkName: "editQuestionnaire" */
                '@/views/score/Questionnaire.vue'
              ),
            props: true,
            redirect: { name: 'editCluster', params: { clusterNum: '0' } },
            children: [
              {
                path: 'metadata',
                name: 'metadata',
                component: () =>
                  import(
                    /* webpackChunkName: "editQuestionnaire" */
                    '@/views/score/Metadata.vue'
                  ),
              },
              {
                path: 'cluster/:clusterNum',
                name: 'editCluster',
                component: () =>
                  import(
                    /* webpackChunkName: "editQuestionnaire" */
                    '@/views/score/Cluster.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId',
                name: 'editClusterCategory',
                component: () =>
                  import(
                    /* webpackChunkName: "editQuestionnaire" */
                    '@/views/score/ClusterCategory.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId',
                name: 'editClusterSection',
                component: () =>
                  import(
                    /* webpackChunkName: "editQuestionnaire" */
                    '@/views/score/ClusterSection.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId/question/:questionId',
                name: 'editQuestion',
                component: () =>
                  import(
                    /* webpackChunkName: "editQuestionnaire" */
                    '@/views/score/Question.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/account-management',
        name: 'accountManagement',
        redirect: { name: 'userManagement' },
        meta: {
          roles: [
            EUserRole.AccountAdministrator,
            EUserRole.ScoringModelManager,
            EUserRole.AccountManager,
            EUserRole.ProspectiveBuyer,
          ],
        },
        beforeEnter(to, from, next) {
          if (
            (user.hasPermission([EUserRole.AccountAdministrator]) ||
              (user.hasPermission([EUserRole.AccountManager]) &&
                user.userIsInFinancePartner())) &&
            to.name !== 'organisationManagement'
          ) {
            next({ name: 'organisationManagement' })
          } else {
            next()
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "accountManagement" */
            '@/views/accountManagement/AccountManagement.vue'
          ),
        children: [
          {
            path: 'organisations',
            name: 'organisationManagement',
            component: () =>
              import(
                /* webpackChunkName: "organisationManagement" */
                '@/views/accountManagement/Organisations.vue'
              ),
          },
          {
            path: 'users',
            name: 'userManagement',
            component: () =>
              import(
                /* webpackChunkName: "userManagement" */
                '@/views/accountManagement/Users.vue'
              ),
          },
        ],
      },
      {
        path: '/asset-management/download/:assetId/group/:isGroup',
        name: 'certificate',
        props: true,
        beforeEnter: (to, from, next) => {
          const { isGroup } = to.params
          if (
            user.hasPermission([
              EUserRole.ObjectAdministrator,
              EUserRole.Evaluator,
            ]) ||
            (isGroup === 'false' && user.userIsInFinancePartner())
          ) {
            next()
          } else {
            next({ name: 'home' })
          }
        },
        component: () =>
          import(
            /* webpackChunkName: "certificate" */
            '@/views/assetManagement/Certificate.vue'
          ),
      },
      {
        path: '/asset-management',
        name: 'assetManagement',
        redirect: { name: 'objectGroupManagement' },
        meta: {
          roles: [
            EUserRole.ObjectAdministrator,
            EUserRole.AccountAdministrator,
            EUserRole.ObjectDataCollector,
            EUserRole.ObjectAnalyst,
            EUserRole.ProspectiveBuyer,
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "assetManagement" */
            '@/views/assetManagement/AssetManagement.vue'
          ),
        children: [
          {
            path: 'objects',
            name: 'objectManagement',
            component: () =>
              import(
                /* webpackChunkName: "objectManagement" */
                '@/views/assetManagement/Objects.vue'
              ),
            beforeEnter(to, from) {
              /* don't switch to user table if we are on the object group
                  table and user clicks on a link to asset management */
              if (
                from.name === 'objectGroupManagement' &&
                to.redirectedFrom?.name === 'assetManagement'
              ) {
                return false
              }
            },
          },
          {
            path: 'object-groups',
            name: 'objectGroupManagement',
            component: () =>
              import(
                /* webpackChunkName: "objectGroupManagement" */
                '@/views/assetManagement/ObjectGroups.vue'
              ),
          },
          {
            path: 'access-request',
            name: 'accessRequest',
            component: () =>
              import(
                /* webpackChunkName: "accessRequest" */
                '@/views/assetManagement/AccessRequest.vue'
              ),
          },
        ],
      },
      {
        path: '/asset-management/objects/:assetId/:isBuyerAsset',
        name: 'editObject',
        redirect: { name: 'objectOverview' },
        component: () =>
          import(
            /* webpackChunkName: "editObject" */
            '@/views/assetManagement/editObject/EditObject.vue'
          ),
        props: true,
        children: [
          {
            path: 'edit',
            name: 'objectOverview',
            component: () =>
              import(
                /* webpackChunkName: "editObject" */
                '@/views/assetManagement/editObject/Overview.vue'
              ),
            props: true,
            redirect: { name: 'editObjectData' },

            children: [
              {
                path: 'data',
                name: 'editObjectData',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/BaseData.vue'
                  ),
                props: true,
              },
              {
                path: 'results/:questionnaireId',
                name: 'questionnaireResults',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/QuestionnaireResults.vue'
                  ),
                props: true,
                redirect: { name: 'questionnaireResultOverview' },
                children: [
                  {
                    path: 'questionnaire-overview/',
                    name: 'questionnaireResultOverview',
                    component: () =>
                      import(
                        /* webpackChunkName: "editObject" */
                        '@/views/assetManagement/editObject/QuestionnaireOverview.vue'
                      ),
                    props: true,
                  },
                  {
                    path: 'questionnaire-details/',
                    name: 'questionnaireResultDetails',
                    component: () =>
                      import(
                        /* webpackChunkName: "editObject" */
                        '@/views/assetManagement/editObject/QuestionnaireDetails.vue'
                      ),
                    props: true,
                  },
                ],
              },
            ],
          },
          {
            path: 'questionnaire/:questionnaireId',
            name: 'answerQuestionnaire',
            redirect: { name: 'metadataResultOverview' },
            component: () =>
              import(
                /* webpackChunkName: "editObject" */
                '@/views/assetManagement/editObject/AnswerQuestionnaire.vue'
              ),
            props: true,
            children: [
              {
                path: 'data',
                name: 'showObjectData',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/BaseData.vue'
                  ),
                props: true,
              },
              {
                path: 'metadata',
                name: 'metadataAnswers',
                redirect: { name: 'metadataResultOverview' },
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/Metadata.vue'
                  ),
                props: true,
                children: [
                  {
                    path: 'metadata-overview/',
                    name: 'metadataResultOverview',
                    component: () =>
                      import(
                        /* webpackChunkName: "editObject" */
                        '@/views/assetManagement/editObject/QuestionnaireOverview.vue'
                      ),
                    props: true,
                  },
                  {
                    path: 'metadata-details/',
                    name: 'metadataResultDetails',
                    component: () =>
                      import(
                        /* webpackChunkName: "editObject" */
                        '@/views/assetManagement/editObject/QuestionnaireDetails.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: 'cluster/:clusterNum',
                name: 'answerCluster',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/Cluster.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId',
                name: 'answerClusterCategory',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/ClusterCategory.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId',
                name: 'answerClusterSection',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/ClusterSection.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId/question/:questionId',
                name: 'answerQuestion',
                component: () =>
                  import(
                    /* webpackChunkName: "editObject" */
                    '@/views/assetManagement/editObject/Question.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/job-management',
        name: 'jobManagement',
        redirect: { name: 'jobObjectGroupManagement' },
        meta: {
          roles: [
            EUserRole.InternalTester,
            EUserRole.ExternalAccreditedTester,
            EUserRole.Evaluator,
            EUserRole.AccountAdministrator,
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "jobManagement" */ '@/views/jobManagement/JobManagement.vue'
          ),
        children: [
          {
            path: 'overview',
            name: 'jobOverview',
            component: () =>
              import(
                /* webpackChunkName: "jobManagement" */
                '@/views/jobManagement/JobOverview.vue'
              ),
            props: true,
            children: [
              {
                path: 'object-groups',
                name: 'jobObjectGroupManagement',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/ObjectGroups.vue'
                  ),
              },
              {
                path: 'object',
                name: 'jobObjectManagement',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/Objects.vue'
                  ),
              },
            ],
          },
          {
            path: 'check/:assetId/:isBuyerAsset',
            name: 'checkAsset',
            redirect: { name: 'metadataCheck' },
            component: () =>
              import(
                /* webpackChunkName: "jobManagement" */
                '@/views/jobManagement/Check.vue'
              ),
            props: true,
            children: [
              {
                path: 'data',
                name: 'checkShowObjectData',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/ObjectData.vue'
                  ),
                props: true,
              },
              {
                path: 'metadata',
                name: 'metadataCheck',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/Metadata.vue'
                  ),
                props: true,
                redirect: { name: 'metadataCheckOverview' },

                children: [
                  {
                    path: 'metadata-overview/',
                    name: 'metadataCheckOverview',
                    component: () =>
                      import(
                        /* webpackChunkName: "jobManagement" */
                        '@/views/assetManagement/editObject/QuestionnaireOverview.vue'
                      ),
                    props: true,
                  },
                  {
                    path: 'metadata-details/',
                    name: 'metadataCheckDetails',
                    component: () =>
                      import(
                        /* webpackChunkName: "jobManagement" */
                        '@/views/assetManagement/editObject/QuestionnaireDetails.vue'
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: 'cluster/:clusterNum',
                name: 'checkCluster',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/Cluster.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId',
                name: 'checkClusterCategory',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/ClusterCategory.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId',
                name: 'checkClusterSection',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/ClusterSection.vue'
                  ),
                props: true,
              },
              {
                path: 'cluster/:clusterNum/category/:categoryId/section/:sectionId/question/:questionId',
                name: 'checkQuestion',
                component: () =>
                  import(
                    /* webpackChunkName: "jobManagement" */
                    '@/views/jobManagement/check/Question.vue'
                  ),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: '/faq',
        name: 'faq',
        component: () =>
          import(/* webpackChunkName: "faq" */ '@/views/FAQ.vue'),
      },
      {
        path: '/contact',
        name: 'contact',
        component: () =>
          import(/* webpackChunkName: "contact" */ '@/views/Contact.vue'),
      },
      {
        path: '/impressum',
        name: 'imprint',
        component: () =>
          import(/* webpackChunkName: "imprint" */ '@/views/Imprint.vue'),
      },
      {
        path: '/data-protection',
        name: 'dataProtection',
        component: () =>
          import(
            /* webpackChunkName: "dataProtection" */ '@/views/DataProtection.vue'
          ),
      },
      {
        path: '/file-preview/:extension/:url',
        name: 'filePreview',
        component: () =>
          import(
            /* webpackChunkName: "FilePreview" */ '@/views/FilePreview.vue'
          ),
        props: true,
      },
      {
        path: '/export',
        name: 'export',
        beforeEnter(to, from, next) {
          if (user.userIsInFinancePartner()) {
            next()
          } else {
            next({ name: 'home' })
          }
        },
        redirect: { name: 'objectGroupExport' },
        component: () =>
          import(
            /* webpackChunkName: "Export" */ '@/views/export/Export.vue'
          ),
        children: [
          {
            path: 'object',
            name: 'objectExport',
            component: () =>
              import(
                /* webpackChunkName: "ObjectExport" */
                '@/views/export/ObjectExport.vue'
              ),
          },
          {
            path: 'object-group',
            name: 'objectGroupExport',
            component: () =>
              import(
                /* webpackChunkName: "ObjectGroupExport" */
                '@/views/export/ObjectGroupExport.vue'
              ),
          },
          {
            path: 'customer-data',
            name: 'customerDataExport',
            component: () =>
              import(
                /* webpackChunkName: "CustomerDataExport" */
                '@/views/export/CustomerDataExport.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

/*** logged in guard ***/
router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn']

  if (to.name === 'login' && loggedIn) {
    next({ name: 'home' })
    return
  }

  if (to.meta.auth && !loggedIn) {
    store.commit('auth/setInitialRouteName', to.fullPath)
    next({ name: 'login' })
    return
  }

  next()
})

/*** role guard ***/
router.beforeEach((to, from, next) => {
  const permittedRoles = (to.meta.roles as Array<EUserRole>) || undefined
  if (user.hasPermission(permittedRoles)) {
    next()
  } else {
    next({ name: 'home' })
  }
})

export default router
