import { createStore } from 'vuex'
import auth, { IAuthState } from './auth'
import questionnaire, { IQuestionnaireInStore } from './questionnaire'
import toastMessage, { IToastMessageState } from './toastMessage'

export interface State {
  auth: IAuthState
  questionnaire: IQuestionnaireInStore
  toastMessage: IToastMessageState
}

export default createStore<State>({
  modules: {
    auth,
    questionnaire,
    toastMessage,
  },
})
