import { EAssetState, EAssetGroupState } from '@/enums'
import { i18n } from '@/plugins'

export function statusText(status: any): string {
  const prefix = 'assetManagement.objects'

  switch (status) {
    case EAssetState.Review:
      return i18n.global.t(`${prefix}-checkedStatus-unchecked`)
    case EAssetState.Evaluation:
    case EAssetState.Closed:
      return i18n.global.t(`${prefix}-checkedStatus-checked`)
    case EAssetState.Declined:
      return i18n.global.t(`${prefix}-status-declined`)
    case EAssetState.Verification:
      return i18n.global.t(`${prefix}-status-verification`)
    case EAssetState.DefinitelyDeclined:
      return i18n.global.t(`${prefix}-status-definitelyDeclined`)
    case EAssetState.Active:
    default:
      // Unexpected
      return i18n.global.t(`${prefix}-status-open`)
  }
}

export function groupStatusText(status: any): string {
  switch (status) {
    case EAssetGroupState.Active:
      return i18n.global.t('assetManagement.objectgroups-status-active')
    case EAssetGroupState.Review:
      return i18n.global.t('assetManagement.objectgroups-status-review')
    case EAssetGroupState.ReviewFailed:
      return i18n.global.t('assetManagement.objectgroups-status-reviewfailed')
    case EAssetGroupState.ReviewPassed:
      return i18n.global.t('assetManagement.objectgroups-status-reviewpassed')
    case EAssetGroupState.Closed:
      return i18n.global.t('assetManagement.objectgroups-status-closed')
    default:
      // Unexpected
      return ''
  }
}
