enum EUserRole {
  AccountAdministrator,
  BillingManager,
  Evaluator,
  ScoringModelManager,
  ObjectAdministrator,
  ObjectDataCollector,
  ObjectAnalyst,
  InternalTester,
  ExternalAccreditedTester,
  ImportAdmin,
  AccountManager,
  ProspectiveBuyer,
}

export default EUserRole
