<template>
  <DynamicView :noPad="true" :noBreadcrumbs="true">
    <h1
      class="pt-[2.25rem] px-[6.25rem] text-[2.125rem] font-light text-white mt-[2.5rem]"
    >
      {{ $t('pages.home') }}
    </h1>
    <p
      class="text-white text-[17px] whitespace-pre-wrap leading-loose px-[6.25rem] mt-4 mb-14"
    >
      {{ $t('home.welcome') }}
    </p>
    <!-- autoplay -->
    <vueper-slides
      :pauseOnHover="false"
      :arrows="false"
      fixed-height
      class="bg-lightestGreen text-darkerGray no-shadow h-[400px] xl:h-[350px]"
    >
      <vueper-slide
        v-for="i in 4"
        :key="i"
        :title="i.toString()"
        class="overflow-y-auto"
      >
        <template #content>
          <div class="px-[6.25rem] py-20">
            <h1 class="text-[2.125rem] font-semibold pb-10">
              {{ $t('home.news-header' + i) }}
            </h1>
            <p class="text-[18px] leading-9 pb-10">
              {{ $t('home.news-text' + i) }}
            </p>
          </div>
        </template>
      </vueper-slide>
      <template #bullet="{ active }">
        <span
          class="w-2 h-2 rounded-full"
          :class="active ? 'bg-primary' : 'bg-lightGray'"
        />
      </template>
    </vueper-slides>

    <div class="bg-white text-darkerGray rounded-t-md px-[6.25rem] py-20">
      <h1 class="text-[2.125rem] font-semibold pb-10">
        {{ $t('home.staticHeader') }}
      </h1>
      <p class="text-[18px] leading-9 pb-10">
        {{ $t('home.staticDescription') }}
      </p>
      <div class="flex flex-row flex-wrap justify-center py-6 px-20">
        <QuicklinkCard
          class="mx-9"
          v-for="(item, index) in actions"
          :key="index"
          v-bind="item"
          @click="$router.push({ name: item.route })"
        />
      </div>
    </div>
  </DynamicView>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { EUserRole } from '@/enums'
import { user as userHelpers } from '@/helpers'
import DynamicView from '@/components/layout/DynamicView'
import QuicklinkCard from '@/components/QuicklinkCard'

export default {
  components: {
    DynamicView,
    QuicklinkCard,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      quicklinks: [
        {
          label: this.$t('home.createOrganization'),
          icon: 'organization',
          text: this.$t('home.createOrganizationText'),
          roles: [EUserRole.AccountAdministrator],
          route: 'organisationManagement',
        },
        {
          label: this.$t('home.createUser'),
          icon: 'user',
          text: this.$t('home.createUserText'),
          roles: [
            EUserRole.AccountAdministrator,
            EUserRole.ScoringModelManager,
            EUserRole.AccountManager,
          ],
          route: 'userManagement',
        },
        {
          label: this.$t('home.createObject'),
          icon: 'object',
          text: this.$t('home.createObjectText'),
          roles: [
            EUserRole.AccountAdministrator,
            EUserRole.ObjectAdministrator,
          ],
          route: 'objectManagement',
        },
        {
          label: this.$t('home.createObjectGroup'),
          icon: 'object-group',
          text: this.$t('home.createObjectGroupText'),
          roles: [
            EUserRole.AccountAdministrator,
            EUserRole.ObjectAdministrator,
          ],
          route: 'objectGroupManagement',
        },
        {
          label: this.$t('home.answerQuestionnaire'),
          icon: 'questionnaire',
          text: this.$t('home.answerQuestionnaireText'),
          roles: [EUserRole.ObjectDataCollector],
          route: 'assetManagement',
        },
        {
          label: this.$t('home.editQuestionnaire'),
          icon: 'questionnaire',
          text: this.$t('home.editQuestionnaireText'),
          roles: [EUserRole.ScoringModelManager],
          route: 'score',
        },
        {
          label: this.$t('home.reviewQuestionnaire'),
          icon: 'questionnaire',
          text: this.$t('home.reviewQuestionnaireText'),
          roles: [EUserRole.Evaluator],
          route: 'jobObjectGroupManagement',
        },
      ],
    }
  },
  computed: {
    actions() {
      return this.quicklinks.filter(
        a =>
          userHelpers.hasPermission(a.roles) &&
          (a.route === 'objectManagement' || a.route == 'objectGroupManagement'
            ? !userHelpers.userIsInFinancePartner()
            : true)
      )
    },
  },
}
</script>

<style>
.vueperslides__bullet {
  margin: 1.5em 0.3em;
}
</style>
