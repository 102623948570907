<template>
  <Dialog :class="requestPending && 'cursor-wait'" v-model="isOpen">
    <template #title>{{ $t('score.questionnaire-duplicate-headline') }}</template>

    <template #description>
      <p>
        {{
        $t('score.questionnaire-duplicate-description', {
        title: questionnaire.title,
        })
        }}
      </p>
    </template>

    <div class="flex flex-col pb-10">
      <span class="section-label">{{ `${$t('score.questionnaire-create-questionnaire')}*` }}</span>
      <div class="flex flex-col mt-2">
        <Input
          v-model="questionnaireInputs.name"
          :placeholder="$t('input.questionnaireName')"
          :errorMessage="errorMessages.name"
          @blur="v$.questionnaireInputs.name.$touch()"
        />
      </div>
      <span class="text-[14px] text-darkGray mt-2">{{ `*${$t('input.requiredFields')}` }}</span>
    </div>

    <template #actions>
      <div>
        <Button
          class="w-[240px]"
          :disabled="createRequest.pending.value"
          @click="close"
        >{{ $t('general.cancel') }}</Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="requestPending || fetchRequest.error.value"
          variant="primary"
          @click="confirm"
        >{{ $t('general.create') }}</Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { inject } from 'vue'
import { mapActions } from 'vuex'
import { useRequest, useValidators } from '@/composition'
import useVuelidate from '@vuelidate/core'
import { useDialog } from '@/composition'
import Dialog from './Dialog'
import { Input, Button } from '@/components/form'
import { questionnaires as questionnairesApi } from '@/services/api'
import { EQuestionnaireState } from '@/enums'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    Dialog,
    Input,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    questionnaire: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'duplicate'],
  setup(props, { emit }) {
    const numQuestionnaires = inject('numQuestionnaires')

    const fetchRequest = useRequest(
      () => questionnairesApi.getById(props.questionnaire.id),
      questionnairesApi.cancelRequest
    )
    const createRequest = useRequest(questionnaire =>
      questionnairesApi.create(questionnaire, numQuestionnaires.value === 0)
    )
    const validators = useValidators()
    const { t, availableLocales } = useI18n()

    return {
      ...useDialog(props, emit, createRequest.pending),
      fetchRequest,
      createRequest,
      validators,
      availableLocales,
      t,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      questionnaireInputs: {
        name: '',
      },
      fetchedQuestionnaire: null,
    }
  },
  validations() {
    return {
      questionnaireInputs: {
        name: {
          required: this.validators.required,
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return this.validators.errorMessages(this.v$.questionnaireInputs, [
        'name',
      ])
    },
    requestPending() {
      return this.fetchRequest.pending.value || this.createRequest.pending.value
    },
    requestError() {
      return this.fetchRequest.error.value || this.createRequest.error.value
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      this.v$.questionnaireInputs.$touch()
      if (this.v$.questionnaireInputs.$error) {
        return
      }
      try {
        const payload = {
          id: this.fetchedQuestionnaire.id,
          clusters: this.fetchedQuestionnaire.clusters,
          title: this.questionnaireInputs.name,
          state: EQuestionnaireState.Draft,
          type: this.fetchedQuestionnaire.type,
        }
        const numberOfCluster = +process.env.VUE_APP_CLUSTER_COUNT
        if (payload.clusters.length < numberOfCluster) {
          for (
            let index = payload.clusters.length;
            index < numberOfCluster;
            index++
          ) {
            const newClusters = {
              name: this.getClusterNameTranslations(
                `score.cluster-name-${index}`
              ),
              weight: 0,
              categories: [],
            }
            payload.clusters.push(newClusters)
          }
        }
        const questionnaire = await this.createRequest.request(payload)
        this.$emit('duplicate', questionnaire)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-clone-success',
        })
      } catch (error) {
        console.log(error)
        this.showMessage({
          type: 'error',
          translationKey: `questionnaire-clone-${
            error.response.status === 400 ? 'onlyOnce' : 'error'
          }`,
        })
      }
    },
    getClusterNameTranslations(translationKey) {
      console.log(this.t(translationKey, translationKey, { locale: 'de' }))
      return this.availableLocales.map(locale => ({
        language: locale,
        name: this.t(translationKey, translationKey, { locale }),
      }))
    },
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }

      this.fetchedQuestionnaire = null
      this.fetchRequest.error.value = false
      this.createRequest.error.value = false
      Object.assign(this.questionnaireInputs, {
        name: this.questionnaire.title,
      })
      this.v$.questionnaireInputs.$reset()

      this.fetchedQuestionnaire = await this.fetchRequest.request()
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
</style>
