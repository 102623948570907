<template>
  <Disclosure v-slot="{ open }">
    <tr
      class="border-lighterGray last:border-b-0 h-16"
      :class="open ? 'border-b-0' : 'border-b'"
    >
      <td class="pl-8">
        <DisclosureButton as="template">
          <Icon
            class="
              inline-block
              cursor-pointer
              transition-transform
              w-8
              lg:w-6
              mr-9
              lg:mr-6
            "
            :class="{
              'transform-gpu rotate-180': open,
              'opacity-50 pointer-events-none': !group.assets?.length,
            }"
            name="chevron-down"
          />
        </DisclosureButton>
        {{ group.name }}
      </td>
      <td>
        {{ group.id }}
      </td>
      <td>
        {{ group.organisationName }}
      </td>
      <template v-if="isEvaluator">
        <td>{{ groupStatusText(group.state) }}</td>
        <td>{{ `${group?.tester?.firstName} ${group?.tester?.lastName}` }}</td>
      </template>
      <td class="text-center whitespace-nowrap pr-11">
        <Button
          v-if="!isEvaluator"
          class="ml-7 px-5"
          variant="primary"
          @click="$emit('submit')"
          :disabled="!group.canBeSubmitted"
        >
          {{ $t('general.evaluate') }}
        </Button>
        <template v-else>
          <Button
            class="ml-7 px-5"
            @click="$emit('evaluate', group, false)"
            :disabled="disableDeclineButton(group.state)"
          >
            {{ $t('general.decline') }}
          </Button>
          <Button
            class="ml-7 px-5"
            variant="primary"
            @click="$emit('evaluate', group, true)"
            :disabled="disableAcceptButton(group.state)"
          >
            {{ $t('general.accept') }}
          </Button>
          <Button
            v-if="isEvaluator"
            class="ml-7 px-5"
            variant="primary"
            :disabled="disableDownloadLabelButton(group.state)"
            @click="
              this.$router.push({
                name: 'certificate',
                params: { assetId: group.id, isGroup: true },
              })
            "
          >
            {{ $t('general.download-label') }}
          </Button>
        </template>
      </td>
    </tr>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform-gpu scale-95 opacity-0"
      enter-to-class="transform-gpu scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform-gpu scale-100 opacity-100"
      leave-to-class="transform-gpu scale-95 opacity-0"
    >
      <DisclosurePanel as="tr">
        <td class="border-lighterGray border-b px-11 pt-5 pb-6" colspan="6">
          <div class="font-bold text-xs uppercase">
            {{ $t('assetManagement.objectGroups-objects') }} ({{
              group.assets.length
            }})
          </div>
          <table class="table-auto w-full ml-2 mt-2">
            <thead class="text-left text-sm text-lightBlack">
              <tr class="h-8">
                <th class="pl-4">{{ $t('assetManagement.objects-name') }}</th>
                <th>{{ $t('assetManagement.objects-status') }}</th>
                <th>{{ $t('assetManagement.objects-score') }}</th>
                <th>{{ $t('assetManagement.objects-builtYear') }}</th>
                <th>{{ $t('assetManagement.objects-renovationYear') }}</th>
                <th>
                  {{ $t('assetManagement.objects-actions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="asset in group.assets" :key="asset.assetId">
                <tr
                  class="bg-oliveGreen h-16"
                  @click="$emit('edit:object', asset.assetId)"
                >
                  <td class="text-primary font-bold pl-4 rounded-l-lg">
                    {{ asset.name }}
                  </td>
                  <td>{{ statusText(asset.state) }}</td>
                  <td>{{ asset.score }}</td>
                  <td>{{ asset.constructionYear }}</td>
                  <td>{{ asset.renovationYear }}</td>
                  <td class="rounded-r-lg">
                    <Button
                      class="px-8"
                      variant="secondary"
                      :disabled="!isReview(asset.state) && !isEvaluator"
                      @click="goToMetaOverview(asset.assetId)"
                    >
                      {{ $t('general.check') }}
                    </Button>
                  </td>
                </tr>
                <!-- spacer -->
                <tr class="h-2"></tr>
              </template>
            </tbody>
          </table>
        </td>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { EAssetState, EAssetGroupState } from '@/enums'
import { stateText } from '@/helpers'
import Icon from '@/components/Icon'
import { Button } from '@/components/form'

export default {
  components: {
    Icon,
    Button,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    isEvaluator: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['submit', 'evaluate'],
  methods: {
    goToMetaOverview(assetId) {
      this.$router.push({
        name: 'metadataCheckOverview',
        params: { assetId,isBuyerAsset:false },
      })
    },
    groupStatusText: stateText.groupStatusText,
    statusText: stateText.statusText,
    isReview(state) {
      return state === EAssetState.Review
    },
    disableDeclineButton(state) {
      return ![
        EAssetGroupState.ReviewPassed,
        EAssetGroupState.ReviewFailed,
      ].includes(state)
    },
    disableAcceptButton(state) {
      return ![EAssetGroupState.ReviewPassed].includes(state)
    },
    disableDownloadLabelButton(state) {
      return ![EAssetGroupState.Closed].includes(state)
    },
  },
}
</script>
