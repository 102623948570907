<template>
  <div
    class="
      fixed
      z-[9999]
      top-0
      h-full
      p-16
      flex
      items-center
      text-center
      font-inter
      text-4xl
      leading-tight
      overlay-blur
    "
  >
    {{ $t('general.screenTooSmall') }}
  </div>
</template>

<style scoped>
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .overlay-blur {
    @apply bg-dialogBackdrop backdrop-filter backdrop-blur-xl;
  }
}
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .overlay-blur {
    @apply bg-dialogBackdropFallback;
  }
}
</style>
