<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.record-saveAnswersForGroup-headline') }}
    </template>
    <template #description>
      <p>
        {{
          $t('score.record-saveAnswersForGroup-description1', {
            group: object.assetGroup.name,
          })
        }}
      </p>
      <p class="mt-8">
        {{ $t('score.record-saveAnswersForGroup-description2') }}
      </p>
      <p class="mt-8">
        {{
          $t('score.record-saveAnswersForGroup-description3', {
            questionnaire: questionnaireTitle,
          })
        }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('score.record-saveAnswersForGroup-action') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import Dialog from './Dialog'
import { useDialog } from '@/composition'
import { useRequest } from '@/composition'
import { Button } from '@/components/form'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
    questionnaireTitle: {
      type: String,
      required: true,
    },
    isBuyerAsset: {
      type:Boolean
    }
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()
    const request = useRequest(() =>
      store.dispatch('questionnaire/saveCluster1AnswersForGroup', props.isBuyerAsset)
    )
    return {
      ...useDialog(props, emit, request.pending),
      ...request,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('finish')
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-saveAnswersForGroup-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'questionnaire-saveAnswersForGroup-error',
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
