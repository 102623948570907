<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.export-dialog_headline') }}
    </template>
    <template #description>
      <p>
        {{ $t('score.export-dialog_description') }}
      </p>
      <Select
        v-model="selectedLanguage"
        :items="availableLanguages"
        :placeholder="$t('general.language')"
      >
      </Select>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>

        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending || !selectedLanguage"
          @click="exportQuestionnaire"
        >
          {{ $t('general.export') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from './Dialog'
import { useDialog, useRequest } from '@/composition'
import { Button, Select } from '@/components/form'
import { questionnaires as questionnairesApi } from '@/services/api'

export default {
  components: {
    Dialog,
    Button,
    Select,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    assetId: {
      type: [String, Number],
      required: true,
    },
    questionnaireId: {
      type: [String, Number],
      required: true,
    },
    exportFormat: {
      type: String,
      required: true,
    },
    isBuyerAsset:{
      type:Boolean,
      required:true
    }
  },
  data() {
    return {
      selectedLanguage: null,
    }
  },
  setup(props, { emit }) {
    const exportRequest = useRequest(selectedLanguage =>
      questionnairesApi.exportAssetQuest(
        props.assetId,
        props.questionnaireId,
        props.exportFormat,
        selectedLanguage,
        props.isBuyerAsset
      )
    )
    return {
      ...useDialog(props, emit, exportRequest.pending),
      exportRequest,
    }
  },
  computed: {
    availableLanguages() {
      return this.$i18n.availableLocales.map(lang => ({
        key: lang,
        text: this.$t(`general.language-${lang}`),
      }))
    },
  },
  methods: {
    async exportQuestionnaire() {
      await this.exportRequest.request(this.selectedLanguage.key)
      this.close()
    },
  },
}
</script>
