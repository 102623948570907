import { pick } from 'lodash-es'
import type { ActionContext } from 'vuex'
import type { State } from '@/store'
import { questionnaires as api } from '@/services/api'
import {
  currentTmpId,
  cleanTemplateRequestPayload,
  prepareAnswerToSend,
} from './helpers'
import {
  EQuestionnaireLevel,
  EQuestionnaireState,
  EAnswerState,
  EAssetState,
} from '@/enums'
import type {
  IQuestionnaireInStore,
  IQuestionInStore,
  IQuestionRetrieval,
  IAddItemConfig,
  IRouteParams,
} from './types'
import type {
  IAnswer,
  IAnswerCreate,
  IAnswerEdit,
  IComment,
  ICommentCreate,
  IAnswerDocument,
} from '@/models/question'
import type { ILockedStatus, IQuestionnaireEdit } from '@/models/questionnaire'
import axios from 'axios'

type Context = ActionContext<IQuestionnaireInStore, State>

export default {
  /*** TEMPLATES ***/

  async fetch({ commit }: Context, id: string): Promise<void> {
    const result = await api.getById(id)
    commit('setQuestionnaire', result)
  },

  async edit({ state, commit }: Context): Promise<void> {
    const payload: Omit<IQuestionnaireEdit, 'clusters'> = {
      ...pick(state, ['id', 'title', 'state', 'type']),
    }
    const result = await api.edit(cleanTemplateRequestPayload(state, payload))
    commit('setQuestionnaire', result)
  },

  async activate({ state, commit }: Context): Promise<void> {
    const payload: Omit<IQuestionnaireEdit, 'clusters'> = {
      ...pick(state, ['id', 'title', 'type']),
      state: EQuestionnaireState.Active,
    }
    const result = await api.edit(cleanTemplateRequestPayload(state, payload))
    commit('setQuestionnaire', result)
  },

  addItem({ commit }: Context, config: IAddItemConfig): IRouteParams {
    commit('addItem', config)

    const tmpId = currentTmpId.toString()

    const params: IRouteParams = {
      clusterNum: config.clusterIdx.toString(),
      categoryId: '',
    }

    if (config.level === EQuestionnaireLevel.Category) {
      params.categoryId = tmpId
      return params
    }

    params.categoryId = config.categoryId.toString()

    if (config.level === EQuestionnaireLevel.Section) {
      params.sectionId = tmpId
      return params
    }

    params.sectionId = config.sectionId.toString()
    params.questionId = tmpId

    return params
  },

  copyQuestion({ commit }: Context, config: IQuestionRetrieval): number {
    commit('copyQuestion', config)
    return currentTmpId
  },

  /*** ANSWERING QUESTIONS ***/

  async fetchForAsset(
    { commit }: Context,
    { assetId, questionnaireId, isBuyerAsset }: { assetId: string; questionnaireId: string, isBuyerAsset: boolean }
  ): Promise<void> {
    const result = await api.getAssetQuest(assetId, questionnaireId, isBuyerAsset)
    commit('setQuestionnaire', result)
  },

  async fetchForAssetLatest(
    { commit }: Context,
    { assetId, isBuyerAsset }: { assetId: string, isBuyerAsset: boolean }
  ): Promise<void> {
    const result = await api.getAssetQuestLatest(assetId, isBuyerAsset)
    commit('setQuestionnaire', result)
  },

  async addComment(
    { commit }: Context,
    {
      retrievalConfig,
      comment,
    }: {
      retrievalConfig: IQuestionRetrieval
      comment: ICommentCreate
    }
  ): Promise<IComment> {
    const commentResult = await api.postComment(comment)

    commit('addComment', {
      retrievalConfig,
      comment: commentResult,
    })

    return commentResult
  },

  async saveAnswer(
    { getters, commit, dispatch }: Context,
    {
      retrievalConfig,
      assetId,
      isBuyerAsset
    }: { retrievalConfig: IQuestionRetrieval; assetId: string, isBuyerAsset: boolean }
  ): Promise<void> {
    const question: IQuestionInStore = getters.getQuestion(retrievalConfig)
    if (question?.answer) {
      question.answer.isBuyerAsset = isBuyerAsset
      const toSend = prepareAnswerToSend(question.answer, EAnswerState.Answered)
      await api.answerQuestions([toSend])
      dispatch('fetchAnswerChangeLog', {
        assetId: parseInt(assetId),
        questionnaireId: getters.id,
        isBuyerAsset
      })
      if (getters.modifiedQuestions.length === 0) {
        commit('setModified', false)
      }
      const result = await api.getAssetQuestLatest(assetId, isBuyerAsset)

      commit('setUnmodifiedQuestions', { questionnaire: result, id: question.id })

    }
  },

  async saveModifiedAnswers(
    { getters, commit }: Context,
    { assetId, isBuyerAsset }: { assetId: string, isBuyerAsset: boolean }
  ): Promise<void> {
    const questions: Array<IQuestionInStore> = getters.modifiedQuestions
    const toSend: Array<IAnswerCreate | IAnswerEdit> = questions
      .filter(q => q.answer)
      .map(q => prepareAnswerToSend(q.answer!, EAnswerState.Answered))
    await api.answerQuestions(toSend)
    commit('setModified', false)
    const result = await api.getAssetQuestLatest(assetId, isBuyerAsset)
    commit('setQuestionnaire', result)
  },

  async saveModifiedReviewedAnswers({
    getters,
    commit,
  }: Context): Promise<void> {
    const questions: Array<IQuestionInStore> = getters.modifiedQuestions
    const toSend: Array<IAnswerEdit> = questions
      .filter(q => q.answer)
      .map(q =>
        prepareAnswerToSend(q.answer!, q.answer?.state ?? EAnswerState.Answered)
      )
    const answers = await api.answerQuestions(toSend)
    commit('setAnswersSaved', { questions, answers })
    commit('setModified', false)
  },

  async changeAssetState(
    { commit }: Context,
    {
      assetId,
      assetState,
      isBuyerAsset
    }: {
      assetId: string
      assetState: EAssetState,
      isBuyerAsset: boolean
    }
  ): Promise<void> {
    await api.setAssetState(assetId, assetState, isBuyerAsset)
    commit('setAssetState', assetState)
  },

  saveCluster1AnswersForGroup({ getters }: Context, isBuyerAsset: boolean): Promise<void> {
    const questionnaireId = getters.id
    const answerIds: Array<number> = getters
      .getClusterAnswers(0)
      .map((a: IAnswer) => a.id)
    return api.saveCluster1AnswersForGroup(questionnaireId, answerIds, isBuyerAsset ?? false)
  },

  async applyCluster1AnswersFromGroup(
    { getters, dispatch }: Context,
    param: { assetId: string | number,
    isBuyerAsset: boolean }
  ): Promise<void> {
    const questionnaireId = getters.id
    const assetId = param.assetId
    const isBuyerAsset = param.isBuyerAsset
    await api.applyCluster1AnswersFromGroup(assetId, questionnaireId, isBuyerAsset)
    dispatch('fetchForAsset', { assetId, questionnaireId, isBuyerAsset })
  },

  async lockQuestionnaire(
    { commit }: Context,
    {
      assetId,
      questionnaireId,
      isLocked,
      isBuyerAsset
    }: { assetId: string; questionnaireId: string; isLocked: boolean, isBuyerAsset: boolean }
  ): Promise<ILockedStatus | void> {
    try {
      await api.lockQuestionnaire(assetId, questionnaireId, isLocked, isBuyerAsset)
      commit('setLockStatus', null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 423) {
          const lockStatus = error.response?.data as ILockedStatus
          commit('setLockStatus', lockStatus)
        }
      } else {
        commit('setLockStatus', null)
      }
    }
  },

  async fetchAnswerChangeLog(
    { commit }: Context,
    { assetId, questionnaireId, isBuyerAsset }: { assetId: number; questionnaireId: number, isBuyerAsset: boolean }
  ): Promise<void> {
    const result = await api.getChangeLog(assetId, questionnaireId, isBuyerAsset)
    commit('setAnswerChangeLog', result)
  },

  async uploadDocumentAction(
    { commit }: Context,
    {
      retrievalConfig,
      answerId,
      file,
    }: {
      retrievalConfig: IQuestionRetrieval
      answerId: number
      file: FormData
    }
  ): Promise<IAnswerDocument> {
    const postDocumentResult = await api.postAnswerDocument(answerId, file)
    commit('addDocumentMutation', {
      retrievalConfig,
      document: postDocumentResult,
    })

    return postDocumentResult
  },

  async deleteDocumentAction(
    { commit }: Context,
    {
      retrievalConfig,
      documentId,
    }: {
      retrievalConfig: IQuestionRetrieval
      documentId: number
    }
  ): Promise<void> {
    await api.deleteAnswerDocument(documentId)
    commit('deleteDocumentMutation', {
      retrievalConfig,
      documentId,
    })
  },

  async getDocumentUrlAction(
    context: Context,
    {
      documentId,
    }: {
      documentId: number
    }
  ): Promise<string> {
    return await api.getDocumentUrl(documentId)
  },
  async getActiveQuestinnaire({ commit }: Context): Promise<void> {
    const activateQuestionnaire = await api.getActiveQuestinnaire();
    commit('setActiveQuestionnaire', { activateQuestionnaire })
  }
}
