<template>
  <Dialog :class="requestPending && 'cursor-wait'" v-model="isOpen">
    <template #title>{{
      $t('assetManagement.objects-create-headline')
    }}</template>

    <div class="flex flex-col border-b-[3px] border-lightestGray py-10 mt-2">
      <span class="section-label">{{
        `${$t('assetManagement.objects-edit-data')}*`
      }}</span>
      <div class="grid grid-cols-2 gap-x-10 mt-2">
        <Input
          v-model="createObjectInputs.name"
          :placeholder="$t('input.objectName')"
          :errorMessage="errorMessages.name"
          @blur="v$.createObjectInputs.name.$touch()"
        />
        <div
          class="col-start-1 col-end-3 grid grid-cols-2 gap-10"
          v-for="(assetType, i) in createObjectInputs.assetAssetTypes"
          :key="i"
        >
          <Select
            :modelValue="findAssetClassOption(assetType.assetTypeId)"
            :items="assetClasses.options.value"
            @update:modelValue="value => updateSelectedOption(value, i)"
            :placeholder="$t('input.assetClass')"
            :info="$t('input.assetClass-info')"
            :errorMessage="errorMessages.assetAssetTypes?.[i]?.[0]"
          />
          <div class="w-full flex justify-between">
            <Input
              class="w-full"
              type="number"
              v-model="assetType.percentage"
              :placeholder="$t('input.percentage')"
              @focusout ="updateToInteger"
            />

            <Icon
              v-if="disableAddAssetType()"
              class="inline-block stroke-current w-6 lg:w-5 ml-7 lg:ml-5 text-darkGray mb-2"
              name="trash"
            />
            <Icon
              v-else
              class="inline-block stroke-current cursor-pointer w-6 lg:w-5 ml-7 lg:ml-5 text-[#a60a28] mb-2"
              name="trash"
              @click="deleteAssetType(assetType.assetTypeId)"
            />
          </div>
        </div>
        <div class="col-start-2 text-right">
          <Button
            class="w-[200px] px-4 text-sm"
            variant="primary"
            @click="addAssetType"
            >{{ $t('general.more-class') }}
          </Button>
        </div>
        <Select
          v-model="groupSelectModel"
          :items="objectGroups.options.value"
          :placeholder="$t('input.objectGroup')"
          :errorMessage="errorMessages.assetGroupId"
          :disabled="!!group"
          @close="v$.createObjectInputs.assetGroupId.$touch()"
        />
        <Input
          v-model="createObjectInputs.ownerCompany"
          :placeholder="$t('input.organisation')"
          :disabled="true"
        />
        <Input
          v-model="createObjectInputs.city"
          :placeholder="$t('input.city')"
          :errorMessage="errorMessages.city"
          @blur="v$.createObjectInputs.city.$touch()"
        />
        <Input
          v-model="createObjectInputs.street"
          :placeholder="$t('input.street')"
          :errorMessage="errorMessages.street"
          @blur="v$.createObjectInputs.street.$touch()"
        />
        <div class="flex justify-between">
          <Input
            class="w-[45%]"
            v-model="createObjectInputs.streetNumber"
            :placeholder="$t('input.streetNumber')"
            :errorMessage="errorMessages.streetNumber"
            @blur="v$.createObjectInputs.streetNumber.$touch()"
          />
          <Input
            class="w-[45%]"
            v-model="createObjectInputs.zip"
            :placeholder="$t('input.zip')"
            :errorMessage="errorMessages.zip"
            @blur="v$.createObjectInputs.zip.$touch()"
          />
        </div>
        <Select
          v-model="countrySelectModel"
          :items="countries.options.value"
          :placeholder="$t('input.country')"
          :errorMessage="errorMessages.country"
          @blur="v$.createObjectInputs.country.$touch()"
        />
        <Select
          v-model="conditionsAndEnvironmentGoals"
          :items="conditionsAndEnvironmentGoalsOptions"
          :placeholder="$t('input.conditionsAndEnvironmentGoals')"
          :errorMessage="errorMessages.conditionsAndEnvironmentGoals"
          @blur="v$.createObjectInputs.conditionsAndEnvironmentGoals.$touch()"
          :info="$t('input.conditionsAndEnvironmentGoals-info')"
        />
      </div>
    </div>

    <div class="flex flex-col pt-10 pb-5">
      <span class="section-label">{{
        $t('assetManagement.objects-edit-details')
      }}</span>
      <div class="input-grid mt-2">
        <Datepicker
          v-model="constructionYearModel"
          :placeholder="$t('input.constructionYear')"
          :info="$t('input.constructionYear-info')"
          :minimum-view="'year'"
          :maximum-view="'year'"
          :errorMessage="errorMessages.constructionYear"
          @blur="v$.constructionYearModel.$touch()"
        />

        <Datepicker
          v-model.number="renovationYearModel"
          :placeholder="$t('input.renovationYear')"
          :info="$t('input.renovationYear-info')"
          :errorMessage="errorMessages.renovationYear"
          @blur="v$.renovationYearModel.$touch()"
          :minimum-view="'year'"
          :maximum-view="'year'"
        />
        <Select
          v-model="qualitySelectModel"
          :items="qualityOptions"
          :placeholder="$t('input.featureQuality')"
          :info="$t('input.featureQuality-info')"
          :infoImage="'feature-quality-' + $i18n.locale"
        />
        <Input
          v-model.number="createObjectInputs.assetTotalArea"
          :placeholder="$t('input.assetTotalArea')"
          :info="$t('input.assetTotalArea-info')"
          :errorMessage="errorMessages.assetTotalArea"
          @blur="v$.createObjectInputs.assetTotalArea.$touch()"
        />
        <Input
          v-model.number="createObjectInputs.usableArea"
          :placeholder="$t('input.assetUsableArea')"
          :info="$t('input.assetUsableArea-info')"
          :errorMessage="errorMessages.usableArea"
          @blur="v$.createObjectInputs.usableArea.$touch()"
        />
        <Input
          v-model.number="createObjectInputs.rentingPeople"
          :placeholder="$t('input.numTenants')"
          :info="$t('input.numTenants-info')"
          :errorMessage="errorMessages.rentingPeople"
          @blur="v$.createObjectInputs.rentingPeople.$touch()"
        />
        <Select
          v-model="historicProtectionSelectModel"
          :items="historicProtectionOptions"
          :placeholder="$t('input.historicProtection')"
          :info="$t('input.historicProtection-info')"
        />
        <Input
          v-model.number="createObjectInputs.ipms2"
          :placeholder="$t('input.ipms2')"
          :info="$t('input.ipms2-info')"
          :errorMessage="errorMessages.ipms2"
          @blur="v$.createObjectInputs.ipms2.$touch()"
        />
        <Input
          v-model.number="createObjectInputs.conditionedArea"
          :placeholder="$t('input.conditionedArea')"
          :info="$t('input.conditionedArea-info')"
          :errorMessage="errorMessages.conditionedArea"
          @blur="v$.createObjectInputs.conditionedArea.$touch()"
        />
        <Select
          v-model="projectCharacteristicsModel"
          :items="projectCharacteristicsOptions"
          :nullable="true"
          :placeholder="$t('input.projectCharacteristics')"
        />
        <Datepicker
          v-model="determinationStartDateModel"
          :placeholder="$t('input.determinationStartDate')"
          :info="$t('input.determinationStartDate-info')"
          :errorMessage="errorMessages.determinationStartDate"
          @blur="v$.determinationStartDateModel.$touch()"
        />
        <Datepicker
          v-model="acceptanceDateModel"
          :placeholder="$t('input.acceptanceDate')"
          :errorMessage="errorMessages.acceptanceDate"
          @blur="v$.acceptanceDateModel.$touch()"
        />
        <Datepicker
          v-model="handoverDateModel"
          :placeholder="$t('input.handoverDate')"
          :errorMessage="errorMessages.handoverDate"
          @blur="v$.handoverDateModel.$touch()"
          :info="$t('input.handoverDate-info')"
        />
        <Select
          v-model="projectStatusModel"
          :items="projectStatusOptions"
          :nullable="true"
          :placeholder="$t('input.projectStatus')"
        />
      </div>
      <span class="text-[14px] text-darkGray">{{
        `*${$t('input.requiredFields')}`
      }}</span>
    </div>

    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="requestPending" @click="close">{{
          $t('general.cancel')
        }}</Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="requestPending"
          variant="primary"
          @click="confirm"
          >{{ $t('general.create') }}</Button
        >
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { pick, omit } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import {
  useRequest,
  useValidators,
  useAssetClasses,
  useCountries,
  useObjectGroups,
} from '@/composition'
import { object as objectHelpers, user, general } from '@/helpers'
import { Input, Select, Button, Datepicker } from '@/components/form'
import {
  EFeatureQuality,
  EHistoricProtection,
  EUserRole,
  EConditionsAndEnvironmentGoals,
  EprojectCharacteristics,
  EProjectStatus,
} from '@/enums'
import { objects as objectsApi } from '@/services/api'
import { useDialog } from '@/composition'
import Dialog from './Dialog'
import Icon from '@/components/Icon'
import { required, numeric, minValue, maxValue, helpers } from '@vuelidate/validators'

const emptyObject = {
  name: '',
  assetGroupId: null,
  ownerCompany: '',
  country: '',
  city: '',
  zip: '',
  street: '',
  streetNumber: '',
  constructionYear: null,
  renovationYear: null,
  quality: EFeatureQuality.Low,
  assetTotalArea: 0,
  usableArea: 0,
  rentingPeople: 0,
  historicProtection: EHistoricProtection.NoStatement,
  ipms2: 0,
  conditionsAndEnvironmentGoals:
    EConditionsAndEnvironmentGoals.StockAndClimateProtection,
  conditionedArea: 0,
  projectCharacteristics: null,
  determinationStartDate: null,
  acceptanceDate: null,
  handoverDate: null,
  projectStatus: null,
  assetAssetTypes: [],
}

const defaultAssetAssetType = {
  assetTypeId: null,
  percentage: 100,
}

const optionalFields = [
  'constructionYear',
  'renovationYear',
  'quality',
  'assetTotalArea',
  'usableArea',
  'rentingPeople',
  'historicProtection',
  'ipms2',
  'conditionedArea',
  'projectCharacteristics',
  'projectStatus',
  'determinationStartDate',
  'acceptanceDate',
  'handoverDate',
]

export default {
  components: {
    Dialog,
    Input,
    Button,
    Select,
    Datepicker,
    Icon,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'create'],
  setup(props, { emit }) {
    const assetClasses = useAssetClasses()
    const countries = useCountries()
    const objectGroups = useObjectGroups()
    const createRequest = useRequest(objectsApi.create)
    const validators = useValidators()

    return {
      ...useDialog(props, emit, createRequest.pending),
      assetClasses: pick(assetClasses, ['request', 'pending', 'options']),
      countries: pick(countries, ['request', 'pending', 'options']),
      objectGroups: pick(objectGroups, ['request', 'pending', 'options']),
      createRequest,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      createObjectInputs: Object.assign({}, emptyObject),
    }
  },
  validations() {
    const fieldValidations = Object.keys(emptyObject).reduce(
      (result, field) => {
        if (!optionalFields.includes(field)) {
          result[field] = { required: this.validators.required }
        } else {
          result[field] = { required: false }
        }
        return result
      },
      {}
    )
    fieldValidations.assetTotalArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.usableArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.rentingPeople = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.ipms2 = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.conditionedArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    return {
      createObjectInputs: {
        ...fieldValidations,
        assetAssetTypes: {
          $each: helpers.forEach({
            assetTypeId: { required },
            percentage: { required, numeric, minValue: minValue(1), maxValue: maxValue(100) },
          }),
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return this.validators.errorMessages(
        this.v$.createObjectInputs,
        Object.keys(
          omit(emptyObject, [
            'quality',
            'historicProtection',
            'handoverDate',
            'renovationYear',
            'acceptanceDate',
            'constructionYear',
            'determinationStartDate',
            'projectCharacteristics',
            'projectStatus',
          ])
        )
      )
    },
    groupSelectModel: {
      get() {
        return this.objectGroups.options.value.find(
          o => o.key === this.createObjectInputs.assetGroupId
        )
      },
      set({ key, org }) {
        this.createObjectInputs.assetGroupId = key
        this.createObjectInputs.ownerCompany = org
      },
    },
    countrySelectModel: {
      get() {
        return this.countries.options.value.find(
          o => o.key === this.createObjectInputs.country
        )
      },
      set({ key }) {
        this.createObjectInputs.country = key
      },
    },
    qualitySelectModel: {
      get() {
        return this.qualityOptions.find(
          o => o.key === this.createObjectInputs.quality
        )
      },
      set({ key }) {
        this.createObjectInputs.quality = key
      },
    },
    historicProtectionSelectModel: {
      get() {
        return this.historicProtectionOptions.find(
          o => o.key === this.createObjectInputs.historicProtection
        )
      },
      set({ key }) {
        this.createObjectInputs.historicProtection = key
      },
    },
    requestPending() {
      return (
        this.assetClasses.pending.value ||
        this.countries.pending.value ||
        this.objectGroups.pending.value ||
        this.createRequest.pending.value
      )
    },
    qualityOptions() {
      return objectHelpers.qualityKeys.map(this.qualityToOptionsEntry)
    },
    historicProtectionOptions() {
      return objectHelpers.historicProtectionKeys.map(
        this.historicProtectionToOptionsEntry
      )
    },
    assetTypeOptions() {
      let options = this.assetClasses.options.value
      if (user.hasPermission([EUserRole.ProspectiveBuyer]))
        return options.filter(opt => opt.model.assetClassIsAvailableForBuyer)
      return options
    },
    conditionsAndEnvironmentGoalsOptions() {
      const tPrefix = 'assetManagement.objects-CEG'

      const options = Object.keys(EConditionsAndEnvironmentGoals)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EConditionsAndEnvironmentGoals[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    conditionsAndEnvironmentGoals: {
      get() {
        return this.conditionsAndEnvironmentGoalsOptions.find(
          o => o.key === this.createObjectInputs.conditionsAndEnvironmentGoals
        )
      },
      set({ key }) {
        this.createObjectInputs.conditionsAndEnvironmentGoals = key
      },
    },
    projectCharacteristicsOptions() {
      const tPrefix = 'assetManagement.objects-PC'

      let options = Object.keys(EprojectCharacteristics)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EprojectCharacteristics[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    projectCharacteristicsModel: {
      get() {
        return this.projectCharacteristicsOptions.find(
          o => o.key === this.createObjectInputs.projectCharacteristics
        )
      },
      set({ key }) {
        this.createObjectInputs.projectCharacteristics = key
      },
    },
    projectStatusOptions() {
      const tPrefix = 'assetManagement.objects-PS'
      const options = Object.keys(EProjectStatus)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EProjectStatus[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    projectStatusModel: {
      get() {
        return this.projectStatusOptions.find(
          o => o.key === this.createObjectInputs.projectStatus
        )
      },
      set({ key }) {
        this.createObjectInputs.projectStatus = key
      },
    },
    constructionYearModel: {
      get() {
        return this.createObjectInputs.constructionYear
          ? new Date(this.createObjectInputs.constructionYear, 0, 1)
          : null
      },
      set(val) {
        const date = new Date(val)
        const year = date.getFullYear()
        this.createObjectInputs.constructionYear = year
      },
    },
    renovationYearModel: {
      get() {
        return this.createObjectInputs.renovationYear
          ? new Date(this.createObjectInputs.renovationYear, 0, 1)
          : null
      },
      set(val) {
        const date = new Date(val)
        const year = date.getFullYear()
        this.createObjectInputs.renovationYear = year
      },
    },
    determinationStartDateModel: {
      get() {
        return this.createObjectInputs.determinationStartDate
          ? new Date(this.createObjectInputs.determinationStartDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)
        this.createObjectInputs.determinationStartDate = date
      },
    },
    acceptanceDateModel: {
      get() {
        return this.createObjectInputs.acceptanceDate
          ? new Date(this.createObjectInputs.acceptanceDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)
        this.createObjectInputs.acceptanceDate = date
      },
    },
    handoverDateModel: {
      get() {
        return this.createObjectInputs.handoverDate
          ? new Date(this.createObjectInputs.handoverDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)
        this.createObjectInputs.handoverDate = date
      },
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      this.v$.createObjectInputs.$touch()
      if (this.v$.createObjectInputs.$error) {
        return
      }

      if (this.createObjectInputs.assetAssetTypes?.map(x => x.percentage)?.reduce((partialSum, a) => partialSum + a, 0) != 100) {
        this.showMessage({
          type: 'error',
          translationKey: 'objectGroups-create-error-percentages',
        })
        return
      }

      const assetTypesIds = this.createObjectInputs.assetAssetTypes?.map(x => x.assetTypeId);
      if (assetTypesIds.filter((n, i) => assetTypesIds.indexOf(n) === i).length != assetTypesIds.length) {
        this.showMessage({
          type: 'error',
          translationKey: 'objectGroups-create-error-assettype-duplicate',
        })
        return
      }

      try {
        const payload = pick(this.createObjectInputs, Object.keys(emptyObject))
        const object = await this.createRequest.request(payload)
        this.$emit('create', object)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'objects-create-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-create-error',
        })
      }
    },
    qualityToOptionsEntry(quality) {
      return {
        key: quality,
        text: objectHelpers.qualityName(quality),
      }
    },
    historicProtectionToOptionsEntry(historicProtection) {
      return {
        key: historicProtection,
        text: objectHelpers.historicProtectionName(historicProtection),
      }
    },
    addAssetType() {
      this.createObjectInputs.assetAssetTypes.push({
        assetTypeId: null,
        percentage: 0,
      })
    },
    findAssetClassOption(assetTypeId) {
      return this.assetClasses.options.value.find(
        option => option.key === assetTypeId
      )
    },
    updateSelectedOption(selectedOption, index) {
      this.createObjectInputs.assetAssetTypes[index].assetTypeId =
        selectedOption.key
    },
    deleteAssetType(assetTypeId) {
      const index = this.createObjectInputs.assetAssetTypes.findIndex(
        assetType => assetType.assetTypeId === assetTypeId
      )
      if (index !== -1) {
        this.createObjectInputs.assetAssetTypes.splice(index, 1)
      }
    },
    copyFromEmptyObject(target) {
      Object.assign(target, emptyObject)
      const emptyAssetAssetType = {}
      Object.assign(emptyAssetAssetType, defaultAssetAssetType)
      target.assetAssetTypes = [emptyAssetAssetType]
    },
    updateToInteger(e) {
      this.createObjectInputs.assetAssetTypes.forEach((aat) => {
        aat.percentage = Math.round(aat.percentage)
      })
    },
    disableAddAssetType() {
      return this.createObjectInputs.assetAssetTypes.length <= 1
    }
  },
  watch: {
    modelValue(opening) {
      if (!opening) {
        return
      }
      this.v$.createObjectInputs.$reset()
      this.createRequest.error.value = false

      this.copyFromEmptyObject(this.createObjectInputs)

      if (this.group) {
        this.objectGroups.options.value = [
          {
            key: this.group.id,
            text: this.group.name,
          },
        ]
        this.createObjectInputs.assetGroupId = this.group.id
        this.createObjectInputs.ownerCompany = this.group.organisationName
      } else {
        this.objectGroups.request()
      }
      this.assetClasses.request()
      this.countries.request()
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
.input-grid {
  @apply grid grid-cols-2 gap-x-10;
}
.input-grid > * {
  @apply w-[358px] mt-1;
}
</style>
