<template>
  <Dialog :class="groupRequest.pending.value && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{
        $t(
          `assetManagement.objectGroups-${
            isNewGroup ? 'create' : 'edit'
          }-headline`
        )
      }}
    </template>

    <div class="flex flex-col py-10">
      <span class="section-label">
        {{ `${$t('assetManagement.objectGroups-create-group')}*` }}
      </span>
      <div class="flex flex-col mt-2">
        <Input
          v-model="groupInputs.name"
          :placeholder="$t('input.objectGroupName')"
          :errorMessage="errorMessages.name"
          @blur="v$.groupInputs.name.$touch()"
        />
        <Select
          v-model="organisationSelectModel"
          :items="organisationOptions"
          :placeholder="$t('input.organisation')"
          :errorMessage="errorMessages.organisationId"
          @close="v$.groupInputs.organisationId.$touch()"
          :disabled="!canChooseOrganisations()"
        />
      </div>
      <span class="text-[14px] text-darkGray mt-2">
        {{ `*${$t('input.requiredFields')}` }}
      </span>
    </div>

    <template #actions>
      <div>
        <Button
          class="w-[240px]"
          :disabled="groupRequest.pending.value"
          @click="close"
        >
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="w-[240px] ml-[31px] px-[88px]"
          :disabled="groupRequest.pending.value"
          variant="primary"
          @click="confirm"
        >
          {{ $t(`general.${isNewGroup ? 'create' : 'save'}`) }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { clone } from 'lodash-es'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { Input, Select, Button } from '@/components/form'
import { user, general } from '@/helpers'
import {
  objectGroups as objectGroupsApi,
  organisations as organisationsApi,
} from '@/services/api'
import { EUserRole } from '@/enums'
import { useDialog } from '@/composition'
import Dialog from './Dialog'

const emptyGroup = {
  name: '',
  organisationId: null,
  organisationName: '',
}

export default {
  components: {
    Dialog,
    Input,
    Select,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    group: {
      type: Object,
    },
  },
  emits: ['update:modelValue', 'finish'],
  setup(props, { emit }) {
    const store = useStore()

    const groupRequest = useRequest((group, isCreate) =>
      objectGroupsApi[isCreate ? 'create' : 'edit'](group)
    )
    const organisationsRequest = useRequest(organisationsApi.getList)
    const ownOrganisationRequest = useRequest(() =>
      store.dispatch('auth/getOrganisation')
    )
    const validators = useValidators()

    return {
      ...useDialog(props, emit, groupRequest.pending),
      groupRequest,
      organisationsRequest,
      ownOrganisationRequest,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      organisationOptions: [],
      groupInputs: Object.assign({}, emptyGroup),
    }
  },
  validations() {
    const fieldValidations = Object.keys(emptyGroup).reduce((result, field) => {
      result[field] = { required: this.validators.required }
      return result
    }, {})
    return { groupInputs: fieldValidations }
  },
  computed: {
    isNewGroup() {
      return !this.group?.id
    },
    errorMessages() {
      return this.validators.errorMessages(this.v$.groupInputs, [
        'name',
        'organisationId',
      ])
    },
    organisationSelectModel: {
      get() {
        return {
          key: this.groupInputs.organisationId,
          text: this.groupInputs.organisationName,
        }
      },
      set({ key, text }) {
        this.groupInputs.organisationId = key
        this.groupInputs.organisationName = text
      },
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    showOrganisationError() {
      this.showMessage({
        type: 'error',
        translationKey: 'organisations-list-fetch-error',
      })
    },
    async fetchOrganisations() {
      try {
        const list = await this.organisationsRequest.request()
        this.organisationOptions = list.map(this.organisationToOptionsEntry)
      } catch {
        this.showOrganisationError()
      }
    },
    async fetchOwnOrganisation() {
      try {
        const ownOrg = await this.ownOrganisationRequest.request()
        if (ownOrg) {
          this.organisationSelectModel = this.organisationToOptionsEntry(ownOrg)
        }
      } catch {
        this.showOrganisationError()
      }
    },
    organisationToOptionsEntry(org) {
      return { key: org.id, text: org.name }
    },
    canChooseOrganisations() {
      return user.hasPermission([EUserRole.AccountAdministrator])
    },
    async confirm() {
      this.v$.groupInputs.$touch()
      if (this.v$.groupInputs.$error) {
        return
      }
      try {
        const payload = clone(this.groupInputs)
        if (!this.isNewGroup) {
          payload.id = this.group.id
        }
        const group = await this.groupRequest.request(payload, this.isNewGroup)
        this.$emit('finish', group)
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: this.isNewGroup
            ? 'objectGroups-create-success'
            : 'objectGroups-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: this.isNewGroup
            ? 'objectGroups-create-error'
            : 'objectGroups-edit-error',
        })
      }
    },
  },
  watch: {
    async modelValue(opening) {
      if (!opening) {
        return
      }

      this.v$.groupInputs.$reset()
      this.groupRequest.error.value = false

      if (this.isNewGroup) {
        Object.assign(this.groupInputs, emptyGroup)
      } else {
        const group = general.defaultingPick(this.group, emptyGroup)
        Object.assign(this.groupInputs, group)
      }

      if (this.canChooseOrganisations()) {
        this.fetchOrganisations()
      } else {
        this.fetchOwnOrganisation()
      }
    },
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
</style>
