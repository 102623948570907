<template>
  <div class="w-[250px]">
    <Icon class="text-primary cursor-pointer w-[110px] mx-auto" :name="icon" />
    <Button class="w-[240px] my-3" variant="primary">
      {{ label }}
    </Button>
    <p class="py-5 text-[15px] font-normal leading-8">
      {{ text }}
    </p>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { Button } from '@/components/form'

export default {
  components: {
    Icon,
    Button,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
