<template>
  <div class="flex bg-lightBlue py-2 px-1">
    <Icon
      name="information"
      class="flex-shrink-0 text-darkBlue m-[8px] w-6 h-6"
    />
    <div class="text-lightBlack text-[15px] font-medium ml-2 my-1">
      <slot />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
}
</script>
