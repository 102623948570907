<template>
  <Input
    v-model="stringModel"
    @keydown.enter="emitFloat"
    @blur="emitFloat"
    :maxlength="maxLength ?? 524288"
  />
</template>

<script>
import { useFloatInput } from '@/composition'
import { Input } from '@/components/form'

export default {
  components: {
    Input,
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    maxLength: Number,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    return useFloatInput(props, emit)
  },
}
</script>
