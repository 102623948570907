<template>
  <div
    class="text-darkGray focus:outline-none"
    :tabindex="$attrs.disabled ? -1 : 0"
    v-bind="attrs.wrapper"
  >
    <div class="flex flex-row mb-1">
      <label class="inline-block text-xs pl-2" :class="labelClasses">{{ heading || '&nbsp;' }}</label>
      <InfoPopup v-if="info || infoImage" class="ml-auto" :info="info" :image="infoImage" />
    </div>

    <datepicker
      :value="value"
      class="flex-1 text-[15px] font-medium focus:outline-none w-full bg-transparent"
      :class="{ truncate }"
      v-bind="$attrs"
      v-model="value"
      @blur="onBlur"
      :language="locale"
    ></datepicker>
    <Icon v-if="icon && iconVariant === 'end'" class="text-darkGray w-6 h-6 ml-3" :name="icon" />

    <div
      class="text-xs text-error cursor-default overflow-x-hidden whitespace-nowrap overflow-ellipsis pl-2 mt-1"
    >{{ errorMessage || '&nbsp;' }}</div>
  </div>
</template>

<script>
import Datepicker from 'vuejs3-datepicker'
import { isNil } from 'lodash-es'
import Icon from '@/components/Icon'
import InfoPopup from '@/components/form/InfoPopup.vue'

export default {
  name: 'ui-datepicker',
  components: { Icon, InfoPopup, Datepicker },
  inheritAttrs: false,
  props: {
    modelValue: {
      default: '',
    },
    icon: {
      type: String,
    },
    iconVariant: {
      type: String,
      default: 'start',
      validator: val => ['start', 'end'].includes(val),
    },
    label: {
      type: String,
    },
    info: {
      type: String,
    },
    infoImage: {
      type: String,
    },
    visibleHeader: {
      type: Boolean,
      default: true,
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    errorHighlight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {}
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
      }
      const fieldAttrs = {
        title,
      }

      return {
        wrapper: wrapperAttrs,
        field: fieldAttrs,
      }
    },
    labelClasses() {
      return this.errorMessage
        ? 'text-error'
        : this.$attrs.disabled
        ? 'text-lightGray'
        : this.hasFocus
        ? 'text-primary'
        : 'text-lightBlack'
    },
    outlineClasses() {
      return this.hasFocus
        ? 'border-transparent ring-2 ring-primary'
        : this.errorMessage || this.errorHighlight
        ? 'border-error'
        : 'border-lighterGray'
    },
    heading() {
      if (this.visibleHeader) {
        if (this.label) {
          return this.label
        }
        if (!isNil(this.modelValue) && this.modelValue.toString() !== '') {
          return this.$attrs.placeholder
        }
      }

      return ''
    },
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {},
}
</script>

<style>
.vuejs3-datepicker__value {
  min-width: unset;
  width: 100%;
  @apply border-lighterGray;
}
/* .vuejs3-datepicker * {
  @apply text-darkGray;
} */
.vuejs3-datepicker__calendar .cell.selected {
  @apply bg-primaryActive;
}
.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vuejs3-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  @apply bg-primaryHover border-0;
}
.vuejs3-datepicker__calendar-topbar {
  @apply bg-primaryActive text-white;
}
</style>