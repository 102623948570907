<template>
  <div class="absolute min-w-[300px] z-20" v-show="isOpen">
    <div class="relative">
      <input
        v-show="isOpen"
        v-model="searchedItem"
        class="border w-full h-[40px] border-gray px-2"
        :placeholder="$t('general.search-options')"
      />
      <Icon
        name="close"
        class="absolute right-2 top-3 w-4 cursor-pointer"
        v-show="searchedItem.length > 0"
        @click="clearSearchedItem"
      />
    </div>

    <ul
      class="flex flex-col bg-white shadow-popup divide-y divide-lighterGray select-none z-[90] max-h-[300px] overflow-y-scroll"
    >
      <li
        v-for="(item,i) in filteredItems"
        :key="'option'+i"
        class="block text-gray text-[17px] font-medium hover:font-semibold hover:text-primary cursor-pointer py-2 px-3"
        :class="{ 'opacity-50 pointer-events-none': item.disabled }"
        @click.prevent="selectedItem(item)"
      >
        <slot :item="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
export default {
  emits: ['select'],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    nullable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      searchedItem: '',
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.items
        .filter(item => {
          return (
            item.text.toLowerCase().indexOf(this.searchedItem.toLowerCase()) !==
            -1
          )
        })
        .sort((a, b) =>
          a.text.toLowerCase().localeCompare(b.text.toLowerCase())
        )
      if (this.nullable)
        filteredItems.unshift({
          text: this.$t('input.none'),
          key: null,
        })
      return filteredItems
    },
  },
  methods: {
    clearSearchedItem() {
      this.searchedItem = ''
    },
    selectedItem(item) {
      this.clearSearchedItem()
      this.$emit('select', item)
    },
  },
}
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
