<template>
  <Dialog :class="pending && 'cursor-wait'" v-model="isOpen">
    <template #title>
      {{ $t('score.questionnaire-activate-headline') }}
    </template>
    <template #description>
      <p>
        {{ $t('score.questionnaire-activate-description1', { title }) }}
      </p>
      <p class="mt-8">
        {{ $t('score.questionnaire-activate-description2') }}
      </p>
      <p class="mt-8">
        {{ $t('score.questionnaire-activate-description3') }}
      </p>
    </template>
    <template #actions>
      <div>
        <Button class="w-[240px]" :disabled="pending" @click="close">
          {{ $t('general.cancel') }}
        </Button>
        <Button
          class="ml-[31px] w-[240px]"
          variant="primary"
          :disabled="pending"
          @click="confirm"
        >
          {{ $t('score.questionnaire-activate-action') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { useStore, mapActions } from 'vuex'
import { useDialog, useRequest } from '@/composition'
import { Button } from '@/components/form'
import Dialog from './Dialog'

export default {
  components: {
    Dialog,
    Button,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue', 'activate'],
  setup(props, { emit }) {
    const store = useStore()
    const activateRequest = useRequest(() =>
      store.dispatch('questionnaire/activate')
    )
    return {
      ...useDialog(props, emit, activateRequest.pending),
      ...activateRequest,
    }
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    async confirm() {
      try {
        await this.request()
        this.$emit('activate')
        this.close()
        this.showMessage({
          type: 'success',
          translationKey: 'questionnaire-activate-success',
        })
      } catch (error) {
        this.showMessage({
          type: 'error',
          translationKey: `questionnaire-activate-${
            error.response.status === 400 ? 'invalid' : 'error'
          }`,
        })
      }
    },
  },
  watch: {
    modelValue(opening) {
      if (opening) {
        this.error = false
      }
    },
  },
}
</script>
