<template>
  <div
    class="text-darkGray focus:outline-none"
    :tabindex="$attrs.disabled ? -1 : 0"
    v-bind="attrs.wrapper"
    @focus="onFocus"
  >
    <label class="inline-block text-xs pl-2 mb-1" :class="labelClasses">
      {{ heading || '&nbsp;' }}
    </label>
    <textarea
      v-bind="attrs.input"
      v-model="value"
      class="
        border
        rounded-md
        text-[15px]
        p-3
        font-medium
        cursor-text
        focus:outline-none
        w-full
        resize-none
      "
      :class="textareaClasses"
      @focus="hasFocus = true"
      @blur="hasFocus = false"
    />
    <div
      class="
        text-xs text-error
        cursor-default
        overflow-x-hidden
        whitespace-nowrap
        overflow-ellipsis
        pl-2
      "
    >
      {{ errorMessage || '&nbsp;' }}
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash-es'

export default {
  name: 'ui-textarea',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: false,
    },
    visibleHeader: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    errorHighlight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs
      const wrapperAttrs = {
        title,
        class: _class,
      }
      return {
        input: inputAttrs,
        wrapper: wrapperAttrs,
      }
    },
    labelClasses() {
      return this.errorMessage
        ? 'text-error'
        : this.$attrs.disabled
        ? 'text-lightGray'
        : this.hasFocus
        ? 'text-primary'
        : 'text-lightBlack'
    },
    textareaClasses() {
      return this.hasFocus
        ? 'border-transparent ring-2 ring-primary'
        : this.$attrs.disabled
        ? 'text-lighterGray border-lighterGray bg-backgroundDisabled cursor-text'
        : this.errorMessage || this.errorHighlight
        ? 'border-error'
        : 'border-lighterGray'
    },
    heading() {
      if (this.visibleHeader) {
        if (this.label) {
          return this.label
        }
        if (!isNil(this.modelValue) && this.modelValue.toString() !== '') {
          return this.$attrs.placeholder
        }
      }

      return ''
    },
  },
}
</script>
