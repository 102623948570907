import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { i18n, directives } from '@/plugins'

import '@styles/base.css'

const app = createApp(App).use(store).use(router).use(i18n).use(directives)

/*
  injected property "scrollParent" is a ref and will be auto-unwrapped and no longer
  needs `.value` in the next minor release. To opt-in to the new behavior now, set
  `app.config.unwrapInjectedRef = true` (this config is temporary and will not be
  needed in the future.)
*/
app.config.unwrapInjectedRef = true

app.mount('#app')
