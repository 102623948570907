import { EUserRole } from '@/enums'
import store from '@/store'
import { i18n } from '@/plugins'
import { enumKeys, enumString } from './general'

export function hasPermission(permittedRoles: Array<EUserRole>): boolean {
  const userRoles: Array<EUserRole> = store.getters['auth/userRoles']
  return (
    !permittedRoles ||
    userRoles.find(role => permittedRoles.includes(role)) !== undefined
  )
}

export function userIsInFinancePartner(): boolean {
  return store.getters['auth/organisationIsFinancePartner']
}

export const roleKeys = enumKeys(EUserRole)

export function roleName(role: EUserRole): string {
  return i18n.global.t(`roles.${enumString(EUserRole, role)}`)
}

export function isRestricted(restrictedRoles: Array<EUserRole>): boolean {
  const userRoles: Array<EUserRole> = store.getters['auth/userRoles']
  return userRoles?.some(role => restrictedRoles.includes(role))
}

export function userOrganisationId(): string {
  const orgId = store.getters['auth/organisationId']
  return orgId
}