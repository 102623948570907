enum EScoreTypes {
    Ecore = 0,
    Finance = 1,
    StockAndClimateProtection = 2,
    StockAndClimateChangeAdaption = 3,
    RenovationAndClimateProtection = 4,
    RenovationAndClimateChangeAdaption = 5,
    NewConstructionAndClimateProtection = 6,
    NewConstructionAndClimateChangeAdaption = 7,
    ProjectDevelopmentAndClimateProtection = 8,
    ProjectDevelopmentAndClimateChangeAdaption = 9,
    NewConstructionAndCircularEconomy = 10,
    ProjectDevelopmentAndCircularEconomy = 11,
    RenovationAndCircularEconomy = 12
}
export default EScoreTypes