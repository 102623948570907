<template>
  <div class="flex" v-bind="attrs.wrapper" v-click-outside="close">
    <Button
      v-bind="attrs.input"
      ref="trigger"
      class="px-4"
      @click="isOpen = !isOpen"
    >
      <slot name="text" />
    </Button>
    <Popup
      v-bind="{ isOpen, items }"
      ref="popup"
      @select="onSelect"
      #default="{ item }"
    >
      <slot name="items" :item="item">
        {{ item.text }}
      </slot>
    </Popup>
  </div>
</template>

<script>
import Button from './Button'
import Popup from '@/components/form/Popup'
import { usePopper } from '@/composition'

const validateItem = item => item.key !== undefined && item.text !== undefined

export default {
  inheritAttrs: false,
  components: {
    Button,
    Popup,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: list => list.every(validateItem),
    },
    sameWidth: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open', 'close', 'select'],
  setup(props, { emit }) {
    return usePopper(emit, [0, 10])
  },
  computed: {
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs

      const wrapperAttrs = {
        class: _class,
        title,
      }

      return {
        wrapper: wrapperAttrs,
        input: inputAttrs,
      }
    },
  },
  methods: {
    onSelect(item) {
      this.$emit('select', item)
      this.close()
    },
  },
}
</script>
