import { pick } from 'lodash-es'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'
import { useRequest } from '@/composition'
import { objects as objectsApi } from '@/services/api'
import { object as objectHelpers } from '@/helpers'
import type { IAssetClass } from '@/models/object'

export interface IUseAssetClasses {
  request: () => Promise<void>
  options: Ref<Array<IAssetClassOption>>
  pending: Ref<boolean>
  error: Ref<boolean>
  success: Ref<boolean>
  toOption: (assetClass: IAssetClass) => IAssetClassOption
}

export interface IAssetClassOption {
  key: number
  text: string
  model: IAssetClass
}

export default function (): IUseAssetClasses {
  const store = useStore()

  const assetClassOptions = ref<IAssetClassOption[]>([])
  const assetClassesRequest = useRequest(objectsApi.getAssetClasses)

  const toOption = (assetClass: IAssetClass): IAssetClassOption => ({
    key: assetClass.id,
    text: objectHelpers.assetClassName(
      assetClass.assetClassIdentifier,
      assetClass.assetClassIsPilot
    ),
    model: assetClass,
  })

  const request = async () => {
    try {
      const list = await assetClassesRequest.request()
      if (!list) {
        return
      }
      assetClassOptions.value = list.map(toOption)
    } catch {
      store.dispatch('toastMessage/showMessage', {
        type: 'error',
        translationKey: 'objects-assetClasses-fetch-error',
      })
    }
  }

  return {
    request,
    options: assetClassOptions,
    ...pick(assetClassesRequest, ['pending', 'error', 'success']),
    toOption,
  }
}
