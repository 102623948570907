<template>
  <button
    class="
      inline-flex
      flex-center
      border
      rounded-3xl
      font-semibold
      focus:outline-none
      py-[9px]
    "
    :class="classes"
  >
    <Icon
      v-if="icon && iconPosition === 'first'"
      class="w-6 mr-2"
      :class="[iconClasses, $attrs.disabled && 'opacity-60']"
      :name="icon"
    />
    <slot />
    <Icon
      v-if="icon && iconPosition === 'last'"
      class="w-6 ml-2"
      :class="[iconClasses, $attrs.disabled && 'opacity-60']"
      :name="icon"
    />
  </button>
</template>

<script>
import Icon from '@/components/Icon'

const variantClasses = {
  primary: {
    enabled:
      'bg-primary hover:bg-primaryHover focus:bg-primaryHover active:bg-primaryActive ' +
      'border-primary text-white',
    disabled: 'bg-gray border-gray text-lightestGray cursor-default',
  },
  secondary: {
    enabled:
      'bg-white hover:bg-secondaryHover focus:bg-secondaryHover active:bg-secondaryActive ' +
      'border-primary text-primary',
    disabled: 'bg-white border-gray text-gray cursor-default',
  },
  tertiary: {
    enabled:
      'bg-tertiary hover:bg-tertiaryHover focus:bg-tertiaryHover active:bg-tertiaryActive ' +
      'border-transparent text-white',
    disabled:
      'bg-tertiary border-transparent text-white cursor-default opacity-60',
  },
  noBorder: {
    enabled:
      'bg-transparent border-none focus:bg-tertiaryHover active:bg-tertiaryActive active:text-lightGreen ' +
      'text-darkGray',
    disabled: 'bg-transparent border-none text-gray cursor-default',
  },
  noBorderGreen: {
    enabled: 'bg-transparent border-none text-lightGreen',
    disabled: 'bg-transparent border-none text-gray cursor-default',
  },
  taxonomy: {
    enabled: 'bg-cyan border-cyan text-white',
    disabled: 'bg-tertiary text-gray cursor-default',
  },
  error: {
    enabled: 'bg-error border-error text-white',
    disabled: 'bg-tertiary text-gray cursor-default',
  },
  gray: {
    enabled: 'bg-lighterGray border-lighterGray text-darkGray',
    disabled: 'bg-tertiary text-gray cursor-default',
  },
  yellow: {
    enabled: 'bg-yellow border-yellow text-lightestGray',
    disabled: 'bg-tertiary text-gray cursor-default',
  },
}

export default {
  name: 'ui-button',
  components: { Icon },
  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator: val =>
        [
          'primary',
          'secondary',
          'tertiary',
          'noBorder',
          'noBorderGreen',
          'error',
          'gray',
        ].includes(val),
    },
    raised: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconClasses: {
      type: String,
      required: false,
    },
    iconPosition: {
      type: String,
      default: 'first',
      validator: val => ['first', 'last'].includes(val),
    },
  },
  computed: {
    classes() {
      const state = this.$attrs.disabled ? 'disabled' : 'enabled'
      return `${variantClasses[this.variant][state]} ${
        this.raised ? 'shadow-raisedButton' : ''
      }`
    },
  },
}
</script>
