import { onUnmounted } from 'vue'
import parse from 'parse-duration'

interface IIntervalOptions {
  /** @param delayedStart determains, if the timeout should started manually via startIntervall() call */
  delayedStart: boolean
  /** @param immediate determains, if the callback should be executed on interval start */
  immediate: boolean
}

interface IUseInterval {
  cancelInterval: () => void
  /** Only has effect, when delayed started */
  startInterval: () => void
}

/**
 * @param cb Function that is executed periodically
 * @param timeout A human readable string that describes the duration, e.g. '2s'. More info on https://www.npmjs.com/package/parse-duration
 */
export default function useInterval(
  cb: () => void,
  timeout: string,
  { delayedStart = false, immediate = false }: IIntervalOptions
): IUseInterval {
  const timeoutMs = parse(timeout)

  let intervalId: number | undefined

  const startInterval = () => {
    if (immediate) {
      cb()
    }
    intervalId = setInterval(cb, timeoutMs)
  }

  const cancelInterval = () => clearInterval(intervalId)

  if (!delayedStart) {
    startInterval()
  }

  onUnmounted(cancelInterval)

  return { cancelInterval, startInterval }
}
