<template>
  <footer class="bg-oceanGreen flex justify-center py-5 min-h-fit-content">
    <router-link
      v-for="{ name, text } in routes"
      :key="name"
      class="font-medium tracking-wide my-3 mx-14"
      :class="$route.name === name ? 'text-lighterGreen' : 'text-white'"
      :to="{ name }"
    >
      {{ text }}
    </router-link>
  </footer>
</template>

<script>
export default {
  computed: {
    routes() {
      return [
        { name: 'faq', text: this.$t('pages.faq') },
        { name: 'contact', text: this.$t('pages.contact') },
        { name: 'imprint', text: this.$t('pages.imprint') },
        { name: 'dataProtection', text: this.$t('pages.dataProtection') },
      ]
    },
  },
}
</script>

<style scoped>
@media print {
  footer {
    display: none;
  }
}
</style>
