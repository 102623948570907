import type { ActionContext } from 'vuex'
import type { State } from '@/store'
import { authStorage, configStorage } from '@/services/storage'
import { auth, organisations } from '@/services/api'
import type { IOrganisation } from '@/models/organisation'
import type {
  IAuthState,
  ILoginData,
  ILogin2faStep2Data,
  IResetPasswordData,
} from './types'
import { uses2fa } from '@/helpers/general'

type Context = ActionContext<IAuthState, State>

export default {
  async login(
    { commit }: Context,
    { userName, password }: ILoginData
  ): Promise<void> {
    const token = await auth.login(userName, password)
    if (!uses2fa()) {
      commit('setUser', { userName, token })
      authStorage.setUser(userName, token)
    }
  },

  async login2faStep2(
    { commit }: Context,
    { userName, token }: ILogin2faStep2Data
  ): Promise<void> {
    const accessToken = await auth.login2faStep2(userName, token)
    commit('setUser', { userName, token: accessToken })
    authStorage.setUser(userName, accessToken)
  },

  tryLoginFromStorage({ commit }: Context): boolean {
    const { userName, token } = authStorage.getUser()

    if (userName && token) {
      commit('setUser', { userName, token })
      return true
    } else {
      return false
    }
  },

  logout({ commit }: Context): void {
    commit('clearUser')
    authStorage.clearUser()
    configStorage.clearApiConfig()
  },

  getOrganisation({ state }: Context): Promise<IOrganisation> {
    return organisations.getById(state!.tokenDecoded!.orgId)
  },

  resetPassword(context: Context, userName: string): Promise<void> {
    return auth.resetPassword(userName)
  },

  setPassword(
    context: Context,
    { password, token }: IResetPasswordData
  ): Promise<void> {
    return auth.setPassword(password, token)
  },
}
