<template>
  <div class="flex border rounded-md cursor-pointer" :class="outlineClasses" @click="onClick">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    hasFocus: {
      type: Boolean,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:isOpen'],
  data() {
    return {
      focusJustHappened: false,
    }
  },
  computed: {
    outlineClasses() {
      return this.hasFocus
        ? 'border-transparent ring-2 ring-primary'
        : this.hasError
        ? 'border-error'
        : 'border-lighterGray'
    },
  },
  methods: {
    onClick() {
      // ignore click when open already happened through focus event
      if ((!this.focusJustHappened, !this.disabled)) {
        this.$emit('update:isOpen', !this.isOpen)
      }
    },
  },
  watch: {
    hasFocus(focussed) {
      if (focussed) {
        this.focusJustHappened = true
        setTimeout(() => {
          this.focusJustHappened = false
        }, 500)
      }
    },
  },
}
</script>
