import { ref, watch, Ref } from 'vue'
import {
  createPopper,
  Instance,
  Modifier,
  Placement,
  State,
} from '@popperjs/core'

type RefElement = HTMLElement | { $el: HTMLElement }

export interface IUsePopper {
  isOpen: Ref<boolean>
  open: () => void
  close: () => void
  trigger: Ref<RefElement | undefined>
  popup: Ref<RefElement | undefined>
}

export default (
  emit: (event: string) => void,
  offset: Placement,
  sameWidth: false,
  anchor: Placement
): IUsePopper => {
  const isOpen = ref(false)
  const trigger = ref<RefElement | undefined>(undefined)
  const popup = ref<RefElement | undefined>(undefined)
  let popper: Instance | null = null

  const modifiers: Array<Partial<Modifier<string, unknown>>> = [
    {
      name: 'offset',
      options: {
        offset,
      },
    },
  ]
  if (sameWidth) {
    modifiers.push({
      name: 'sameWidth',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      fn({ state }: { state: State }) {
        state.styles.popper.width = `${state.rects.reference.width}px`
      },
      effect({ state }: { state: State }) {
        state.elements.popper.style.width = `${
          (state.elements.reference as HTMLElement).offsetWidth
        }px`
      },
    })
  }

  const openPopper = () => {
    const triggerEl =
      trigger.value instanceof HTMLElement ? trigger.value : trigger.value!.$el
    const popupEl =
      popup.value instanceof HTMLElement ? popup.value : popup.value!.$el

    popper = createPopper(triggerEl, popupEl, {
      placement: anchor ? anchor : 'bottom-start',
      modifiers,
    })
  }

  const closePopper = () => {
    if (popper) {
      popper.destroy()
      popper = null
    }
  }

  const open = () => {
    isOpen.value = true
  }
  const close = () => {
    isOpen.value = false
  }

  watch(isOpen, isOpening => {
    if (isOpening) {
      openPopper()
      emit('open')
    } else {
      closePopper()
      emit('close')
    }
  })

  return { isOpen, open, close, trigger, popup }
}
