<template>
  <div
    class="
      border-[2.5px]
      rounded-3xl
      bg-ultralightGray
      focus:outline-none
      py-[9px]
      px-[7px]
    "
    v-bind="attrs.wrapper"
    :class="checkboxClasses"
  >
    <Checkbox
      class="font-medium cursor-pointer mx-2"
      :class="$attrs.disabled ? 'cursor-default' : 'cursor-pointer'"
      v-model="value"
      v-bind="attrs.input"
      :taxonomy="taxonomy"
    >
      {{ label }}
    </Checkbox>
  </div>
</template>

<script>
import Checkbox from './Checkbox'

export default {
  components: {
    Checkbox,
  },
  inheritAttrs: false,
  props: {
    modelValue: {},
    label: {
      type: String,
      required: true,
    },
    taxonomy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    attrs() {
      const { class: _class, ...inputAttrs } = this.$attrs
      const wrapperAttrs = {
        class: _class,
      }
      return {
        input: inputAttrs,
        wrapper: wrapperAttrs,
      }
    },
    checkboxClasses() {
      return this.isChecked && !this.taxonomy
        ? 'border-primary text-primary'
        : this.isChecked && this.taxonomy
        ? 'border-cyan text-cyan'
        : this.$attrs.disabled
        ? 'border-transparent text-lightGray'
        : 'border-transparent text-darkGray'
    },
    isChecked() {
      const trueValue = this.$attrs['true-value']

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.$attrs.value)
      } else if (trueValue !== undefined) {
        return this.modelValue === trueValue
      } else {
        return this.modelValue
      }
    },

    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
