import { ITranslatedName } from '@/models/questionnaire'
import { EQuestionType, EVirtQuestType,EScoreTypes } from '@/enums'
import { i18n } from '@/plugins'
import { enumKeys, enumString } from './general'

const iconMap = {
  [EQuestionType.SingleChoice]: 'radio',
  [EQuestionType.MultipleChoice]: 'checkbox',
  [EQuestionType.ValueQuestion]: 'value-question',
  [EQuestionType.VirtualQuestion]: 'virtual-question',
}

export const typeKeys = enumKeys(EQuestionType)
export const scoreTypes = enumKeys(EScoreTypes)

export function typeName(qType: EQuestionType): string {
  return i18n.global.t(`score.question-${enumString(EQuestionType, qType)}`)
}
export function scoreName(sType: EScoreTypes): string {
  return enumString(EScoreTypes, sType)
}
export function typeIcon(qType: EQuestionType): string {
  return iconMap[qType]
}

export const virtQuestTypeKeys = enumKeys(EVirtQuestType)

export function virtQuestTypeName(virtQuestType: EVirtQuestType): string {
  const downcased = EVirtQuestType[virtQuestType].toLowerCase()
  return i18n.global.t(`score.question-virtualQuestionType-${downcased}`)
}

export function defaultingLangEntry(
  langs: Array<ITranslatedName>
): ITranslatedName {
  return (
    langs?.find(entry => entry.language === i18n.global.locale) ||
    langs?.find(entry => entry.language === 'de') || {
      // This is only useful on the defaultClustersTable of QuestionnaireScoreInfo, which does not contain texts in order to become smaller.
      name: 'unbekkant',
      language: 'de',
    }
  ) // fallback
}
