<template>
  <label
    class="flex items-center"
    v-bind="attrs.wrapper"
    :class="!$attrs.disabled && 'cursor-pointer'"
  >
    <input
      class="hidden"
      type="checkbox"
      v-bind="attrs.input"
      v-model="value"
    />
    <div
      class="
        flex-shrink-0
        w-[20px]
        h-[20px]
        border-[2.5px] border-solid
        rounded-sm
      "
      :class="[checkboxClasses, $attrs.disabled && 'opacity-50']"
    >
      <div
        class="
          border-solid border-white
          relative
          border-t-0 border-b-[3px] border-r-[3px] border-l-0
          w-[8px]
          h-[12px]
          left-[4px]
          bottom-[1px]
          transforms
          rotate-45
        "
        :class="checkmarkClasses"
      />
    </div>
    <span
      class="text-[15px]"
      :class="{ 'ml-3': this.$slots.default, 'opacity-50': $attrs.disabled }"
    >
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  name: 'ui-check',
  inheritAttrs: false,
  props: {
    // no type restrictions to enable true-value / false-value
    modelValue: {},
    taxonomy: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    attrs() {
      const { title, class: _class, ...inputAttrs } = this.$attrs
      const wrapperAttrs = {
        title,
        class: _class,
      }
      return {
        input: inputAttrs,
        wrapper: wrapperAttrs,
      }
    },
    isChecked() {
      const trueValue = this.$attrs['true-value']

      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.$attrs.value)
      } else if (trueValue !== undefined) {
        return this.modelValue === trueValue
      } else {
        return this.modelValue
      }
    },
    checkboxClasses() {
      return this.isChecked && !this.taxonomy
        ? 'border-lightGreen bg-lightGreen'
        : this.isChecked && this.taxonomy
        ? 'border-cyan bg-cyan'
        : 'border-gray bg-white'
    },
    checkmarkClasses() {
      return this.isChecked ? 'block' : 'hidden'
    },
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
}
</script>
