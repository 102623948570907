<template>
  <LoggedOutView :class="pending && 'cursor-wait'">
    <template #heading>
      {{ $t('login.setPassword-headline') }}
    </template>
    <template #description>
      <div class="w-96">
        {{
          $t('login.setPassword-description', {
            userName: setPassword.userName,
          })
        }}
      </div>
      <div class="w-96 mt-2">
        {{ $t('login.setPassword-description2') }}
      </div>
    </template>
    <form class="flex flex-col mt-4" novalidate @submit.stop.prevent="confirm">
      <Input
        type="password"
        icon="lock"
        :placeholder="$t('input.password')"
        :errorMessage="errorMessages.password"
        v-model="setPassword.password"
        @blur="v$.setPassword.password.$touch()"
      />
      <Input
        type="password"
        icon="lock"
        :placeholder="$t('input.passwordRepeat')"
        :errorMessage="errorMessages.passwordRepeat"
        v-model="setPassword.passwordRepeat"
        @input="v$.setPassword.passwordRepeat.$touch()"
      />
      <div
        class="text-sm font-semibold text-center mt-2"
        :class="notificationClasses"
      >
        {{ notification }}
      </div>
      <div class="flex justify-center pt-6 pb-10">
        <Button
          v-if="success"
          class="self-center w-[200px]"
          type="button"
          variant="secondary"
          @click="this.$router.push({ name: 'login' })"
        >
          {{ $t('login.setPassword-toLogin') }}
        </Button>
        <Button
          v-else
          class="self-center w-[200px]"
          :class="pending && 'cursor-wait'"
          type="submit"
          variant="primary"
          :disabled="pending"
        >
          {{ $t('login.setPassword-finish') }}
        </Button>
      </div>
    </form>
  </LoggedOutView>
</template>

<script>
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useRequest, useValidators } from '@/composition'
import { LoggedOutView } from '@/components/layout'
import { Input, Button } from '@/components/form'

export default {
  components: {
    Input,
    Button,
    LoggedOutView,
  },
  setup() {
    const store = useStore()
    const request = useRequest((password, token) =>
      store.dispatch('auth/setPassword', { password, token })
    )
    const validators = useValidators()

    return {
      ...request,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      setPassword: {
        userName: this.$route.query.userName,
        token: this.$route.query.token,
        password: '',
        passwordRepeat: '',
      },
    }
  },
  validations() {
    return {
      setPassword: {
        userName: { required: this.validators.required },
        token: { required: this.validators.required },
        password: {
          required: this.validators.required,
          minLength: this.validators.minLength(6, 'error.minLengthString'),
          passwordFormat: this.validators.passwordFormat(
            'error.passwordProperFormat'
          ),
        },
        passwordRepeat: {
          required: this.validators.required,
          sameAs: this.validators.sameAs(
            this.setPassword.password,
            'error.identicalPassword'
          ),
        },
      },
    }
  },
  computed: {
    errorMessages() {
      return this.validators.errorMessages(this.v$.setPassword, [
        'password',
        'passwordRepeat',
      ])
    },
    notification() {
      return this.success
        ? this.$t('login.setPassword-success')
        : this.$t('error.request')
    },
    notificationClasses() {
      return !this.error && !this.success
        ? 'invisible'
        : this.error
        ? 'text-error'
        : 'text-lightGreen'
    },
  },
  methods: {
    async confirm() {
      this.v$.setPassword.$touch()
      if (this.v$.setPassword.$error) {
        return
      }
      try {
        await this.request(this.setPassword.password, this.setPassword.token)
        if (this.$store.getters['auth/loggedIn']) {
          this.$store.dispatch('auth/logout')
        }
      } catch {
        // error handled through error flag
      }
    },
  },
}
</script>
