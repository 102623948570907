enum EAssetState {
  Active,
  Review,
  Evaluation,
  Closed,
  Declined,
  Verification,
  DefinitelyDeclined,
  Expired
}

export default EAssetState
